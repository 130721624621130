import {
    CaretLeftFilled,
    CaretRightFilled,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { Icon } from "@iconify/react";
import {
    Avatar,
    Button,
    Divider,
    Input,
    Popconfirm,
    Select,
    Switch,
    Tag,
} from "antd";
import { formatDistanceToNow } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FloatingButton } from "../../components/UI/Buttons";
import { AddAdminModal } from "../../components/UI/Modals";
import api from "../../config/api";
import {
    mobileNavState,
    organizationState,
    permissionsState,
    titleState,
    userState,
} from "../../store";
import {
    hasCreatePermission,
    hasDeletePermission,
    hasUpdatePermission,
} from "../../utils/checkPermission";
import { imageChecker } from "../../utils/imageChecker";
import { getInitals } from "../../utils/initials";
import { successNotification } from "../../utils/notifications";

function AdminUsers() {
    const [addModal, setAddModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageLimit, setPageLimit] = useState(10);
    const [searchbarVisible, setSearchbarVisible] = useState(false);
    const [adminUsers, setAdminUsers] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);

    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const user = useRecoilValue(userState);
    const setTitle = useSetRecoilState(titleState);
    const setMobileNav = useSetRecoilState(mobileNavState);

    const queryClient = useQueryClient();
    const [adminRoles, setAdminRoles] = useState([]);

    useEffect(() => {
        setTitle("Admin User");
    }, [setTitle]);

    useEffect(() => {
        setMobileNav(false);
    }, [setMobileNav]);

    const fetchAdminUsers = async (pageNumber, pageSize) => {
        const { data } = await api.get(
            `/admin/users_paginated?organization_id=${organization}&:organization_user->role&user_type=ADMIN&page=${
                pageNumber + 1
            }&PAGINATE_SIZE=${pageSize}&:last_seen`
        );
        return data;
    };

    const { isLoading, isError } = useQuery(
        ["admin-users", pageNumber, pageLimit, organization],
        () => fetchAdminUsers(pageNumber, pageLimit),
        {
            keepPreviousData: true,
            onSuccess: (response) => {
                const { result } = response;
                const { data, last_page, total } = result;
                setAdminUsers(data);
                setPageCount(last_page);
                setTotalUsers(total);
            },
        }
    );

    const fetchRoles = async () => {
        const { data } = await api.get(
            `/admin/roles?organization_id=${organization}`
        );
        return data;
    };

    useQuery(["roles", organization], fetchRoles, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            setAdminRoles(result);
        },
    });

    const handleDeleteUser = async (data) => {
        await api.delete(
            `/admin/organization_user?user_id=${data.id}&organization_id=${organization}`
        );
        successNotification("Admin deleted successfully!");
        queryClient.invalidateQueries("admin-users");
    };

    const handleUpdateRole = async (roleId, user) => {
        await api.patch(
            `/admin/organization_user?user_id=${user.id}&organization_id=${organization}&role_id=${roleId}`
        );
        successNotification("Admin role updated successfully!");
        queryClient.invalidateQueries("admin-users");
    };

    const toggleUserActiveStatus = async (checked, userId) => {
        if (checked) {
            await api.patch(
                `/admin/organization_user?organization_id=${organization}&user_id=${userId}&is_active=${Number(
                    checked
                )}`
            );
            successNotification("Admin set active successfully!");
            queryClient.invalidateQueries("admin-users");
        } else {
            await api.patch(
                `/admin/organization_user?organization_id=${organization}&user_id=${userId}&is_active=${Number(
                    checked
                )}`
            );
            successNotification("Admin set inactive successfully!");
            queryClient.invalidateQueries("admin-users");
        }
    };

    const data = React.useMemo(() => adminUsers, [adminUsers, adminRoles]);

    const columns = React.useMemo(
        () => [
            {
                Header: "",
                accessor: "image_name",
                Cell: ({ row }) => {
                    if (row.original.image_name) {
                        return (
                            <div className="text-center">
                                <Avatar
                                    size="large"
                                    src={
                                        imageChecker(row.original.image_name)
                                            ? row.original.image_name
                                            : `${process.env.REACT_APP_IMAGE_API}${row.original.image_name}`
                                    }
                                />
                            </div>
                        );
                    } else {
                        return (
                            <div className="text-center">
                                <Avatar size="large" className="bg-brand">
                                    {getInitals(row.original.full_name)}
                                </Avatar>
                            </div>
                        );
                    }
                },
            },
            {
                Header: "Name",
                accessor: "full_name",
                Cell: ({ row }) => {
                    if (
                        Boolean(row.original.organization_user.is_new_request)
                    ) {
                        return (
                            <span>
                                {row.original.full_name}{" "}
                                <Tag color="#045DB8">Requested</Tag>
                            </span>
                        );
                    } else {
                        return <span>{row.original.full_name}</span>;
                    }
                },
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Role",
                accessor: "role",
                Cell: ({ row }) => {
                    return (
                        <Select
                            value={row.original.organization_user.role.name}
                            onChange={(roleId) =>
                                handleUpdateRole(roleId, row.original)
                            }
                            bordered={false}
                            disabled={
                                _.isEqual(row.original.id, user.id) ||
                                !hasUpdatePermission(
                                    permissions,
                                    "ORGANIZATION_USERS"
                                )
                            }
                        >
                            {adminRoles.map((adminRole) => (
                                <Select.Option
                                    key={adminRole.id}
                                    value={adminRole.id}
                                >
                                    {adminRole.name}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                },
            },
            {
                Header: "Last Seen",
                accessor: "last_seen",
                Cell: ({ row }) => {
                    if (!_.isNil(row.original.last_seen)) {
                        return (
                            <span>
                                {formatDistanceToNow(
                                    new Date(row.original.last_seen),
                                    {
                                        addSuffix: true,
                                    }
                                )}
                            </span>
                        );
                    } else {
                        return <span></span>;
                    }
                },
            },
            {
                Header: "Delete",
                accessor: "delete",
                Cell: ({ row }) => {
                    if (_.isEqual(row.original.id, user.id)) {
                        return null;
                    } else {
                        return (
                            <Popconfirm
                                className="cursor-pointer"
                                title="Are you sure you want to delete this user?"
                                okText="Delete"
                                onConfirm={() => handleDeleteUser(row.original)}
                                onCancel={(e) => e.preventDefault()}
                            >
                                <Icon
                                    icon="fluent:delete-off-20-filled"
                                    className="text-red-500"
                                />
                            </Popconfirm>
                        );
                    }
                },
            },
            {
                Header: "Active",
                accessor: "active",
                Cell: ({ row }) => {
                    if (_.isEqual(row.original.id, user.id)) {
                        return null;
                    } else {
                        return (
                            <Switch
                                onChange={(value) =>
                                    toggleUserActiveStatus(
                                        value,
                                        row.original.id
                                    )
                                }
                                checked={Boolean(
                                    Number(
                                        row.original.organization_user.is_active
                                    )
                                )}
                                checkedChildren={<Icon icon="typcn:tick" />}
                                unCheckedChildren={<Icon icon="jam:close" />}
                            />
                        );
                    }
                },
            },
        ],
        [adminRoles]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        setPageSize,
        pageOptions,
        gotoPage,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: pageNumber,
                pageSize: pageLimit,
                hiddenColumns: [
                    !hasUpdatePermission(permissions, "ORGANIZATION_USERS")
                        ? "active"
                        : "",
                    !hasDeletePermission(permissions, "ORGANIZATION_USERS")
                        ? "delete"
                        : "",
                ],
            },
            manualPagination: true,
            pageCount,
        },
        useGlobalFilter,
        usePagination
    );

    useEffect(() => {
        setPageNumber(pageIndex);
    }, [pageIndex]);

    useEffect(() => {
        setPageLimit(pageSize);
    }, [pageSize]);

    const toggleSearchbar = () => {
        setSearchbarVisible(!searchbarVisible);
    };

    const onSearch = (event) => {
        setGlobalFilter(event.target.value);
    };

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isError) {
        return <>Error... Please refresh the page.</>;
    }

    return (
        <>
            <Divider className="mb-8" />
            <div className="flex items-center justify-end gap-2">
                <SearchOutlined
                    className="text-brand"
                    onClick={toggleSearchbar}
                />
                {searchbarVisible ? (
                    <Input
                        className="w-60"
                        defaultValue={globalFilter}
                        onKeyUp={onSearch}
                    />
                ) : null}
                <select
                    className="border border-brand rounded"
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[5, 10, 20, 50, 100, 200].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
                <div className="flex text-brand">
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => gotoPage(0)}
                        icon={<DoubleLeftOutlined />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        icon={<CaretLeftFilled />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        icon={<CaretRightFilled />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => gotoPage(pageCount - 1)}
                        icon={<DoubleRightOutlined />}
                    />
                </div>
            </div>
            <table {...getTableProps()} className="w-full">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            className="text-left text-gray-400"
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    className="font-normal"
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className="odd:bg-gray-100 hover:bg-brand hover:opacity-40 hover:text-white text-left"
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            className="py-4"
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="flex items-center justify-end gap-2">
                <span>
                    Page {pageIndex + 1} / {pageOptions.length}
                </span>
                <select
                    className="border border-brand rounded"
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[5, 10, 20, 50, 100, 200].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
                <div className="flex text-brand">
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => gotoPage(0)}
                        icon={<DoubleLeftOutlined />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        icon={<CaretLeftFilled />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        icon={<CaretRightFilled />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => gotoPage(pageCount - 1)}
                        icon={<DoubleRightOutlined />}
                    />
                </div>
                <span>Total Admin Users: {totalUsers}</span>
            </div>
            {hasCreatePermission(permissions, "USERS") ? (
                <>
                    <FloatingButton
                        text="Add Admin"
                        action={() => setAddModal(true)}
                    />
                    <AddAdminModal open={addModal} closeAction={setAddModal} />
                </>
            ) : null}
        </>
    );
}

export default AdminUsers;
