import { MoreOutlined } from "@ant-design/icons";
import { Alert, Button, Dropdown, Menu, Modal, Popconfirm } from "antd";
import { format } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import api from "../../../../config/api";
import {
    coursePreviewState,
    organizationState,
    permissionsState,
} from "../../../../store";
import {
    hasCreatePermission,
    hasDeletePermission,
    hasUpdatePermission,
} from "../../../../utils/checkPermission";
import { successNotification } from "../../../../utils/notifications";
import { CustomButton } from "../../Buttons";
import "./CoursePreview.less";

function CoursePreview() {
    const [coursePreviewVisibility, setCoursePreviewVisibility] =
        useRecoilState(coursePreviewState.visible);
    const [coursePreviewData, setCoursePreviewData] = useRecoilState(
        coursePreviewState.data
    );
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const [coursePreviewDefaultData, setCoursePreviewDefaultData] =
        useRecoilState(coursePreviewState.defaultData);
    const [courseId, setCourseId] = useState(null);
    const [languagePreference, setLanguagePreference] = useState(0);
    const [chapters, setChapters] = useState([]);
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const closeCoursePreview = () => {
        setCoursePreviewData(null);
        setCoursePreviewDefaultData(null);
        setLanguagePreference(0);
        setCoursePreviewVisibility(false);
    };

    const fetchChapters = async (courseId) => {
        const { data } = await api.get(
            `/admin/chapters?course_id=${courseId}&organization_id=${organization}&:translations`
        );
        return data;
    };

    const { refetch, isLoading } = useQuery(
        ["chapters", courseId],
        () => fetchChapters(courseId),
        {
            enabled: false,
            cacheTime: 0,
            onSuccess: (response) => {
                const { result } = response;
                setChapters(result);
            },
        }
    );

    useEffect(() => {
        if (coursePreviewData) {
            setCourseId(coursePreviewData.id);
        }
    }, [coursePreviewData]);

    useEffect(() => {
        if (courseId) {
            refetch();
        }
    }, [courseId]);

    const handleDeleteChapter = async (chapterId) => {
        await api.delete(
            `/admin/chapter?id=${chapterId}&organization_id=${organization}`
        );
        successNotification("Chapter deleted successfully!");
        refetch();
    };

    const handleTranslateCourse = (languageId) => {
        setLanguagePreference(languageId);

        if (languageId == 0) {
            setCoursePreviewData({
                ...coursePreviewData,
                title: coursePreviewDefaultData.title,
                summary: coursePreviewDefaultData.summary,
                cover_image: coursePreviewDefaultData.cover_image,
            });
        } else {
            const translation = _.find(coursePreviewData.translations, [
                "language_id",
                languageId,
            ]);

            if (!_.isNil(translation)) {
                setCoursePreviewData({
                    ...coursePreviewData,
                    title: translation.title,
                    summary: translation.summary,
                    cover_image:
                        translation.image_name ?? coursePreviewData.cover_image,
                });
            }
        }
    };

    const handleTranslateChapter = (chapter) => {
        if (languagePreference == 0) {
            return chapter.title;
        } else {
            const translation = _.find(chapter.translations, [
                "language_id",
                languagePreference,
            ]);
            if (!_.isNil(translation)) {
                return translation.title;
            } else {
                return chapter.title;
            }
        }
    };

    const menu = (languages) => {
        return (
            <Menu className="text-right">
                {[{ id: 0, name: "Default" }, ...languages]?.map((language) => (
                    <Menu.Item
                        key={language.id}
                        className="text-brand"
                        onClick={() => handleTranslateCourse(language.id)}
                    >
                        {language.name}
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    return (
        <Modal
            centered={true}
            visible={coursePreviewVisibility}
            footer={
                <div className="flex justify-end gap-4">
                    <Button
                        type="primary"
                        className="rounded"
                        onClick={closeCoursePreview}
                        ghost
                    >
                        Back
                    </Button>
                    {hasUpdatePermission(permissions, "COURSES") ? (
                        <Button
                            type="primary"
                            className="rounded"
                            onClick={() =>
                                navigate(
                                    `/courses/${coursePreviewData?.id}/edit`
                                )
                            }
                        >
                            Edit
                        </Button>
                    ) : null}
                </div>
            }
            onCancel={closeCoursePreview}
            closable={false}
        >
            <div className="relative">
                <img
                    src={`${process.env.REACT_APP_IMAGE_API}${coursePreviewData?.cover_image}`}
                    alt=""
                    className="w-full h-40 object-cover object-center"
                />
                <h1 className="absolute bottom-1 left-10 right-10 uppercase font-bold text-white text-3xl break-all">
                    {coursePreviewData?.title}
                </h1>
                {coursePreviewData?.published ? null : (
                    <div className="absolute bottom-6 right-0 bg-red-500 text-white px-3">
                        Draft
                    </div>
                )}
                {_.isEmpty(coursePreviewData?.languages) ? null : (
                    <Dropdown
                        placement="bottomRight"
                        trigger={["click"]}
                        overlay={menu(coursePreviewData?.languages)}
                        className="absolute top-2 right-2 bg-white rounded-full grid place-items-center p-1"
                    >
                        <span onClick={(e) => e.stopPropagation()}>
                            <MoreOutlined className="text-brand font-bold" />
                        </span>
                    </Dropdown>
                )}
            </div>
            <div className="py-5 px-10 flex flex-col">
                <p className="text-gray-500">{coursePreviewData?.summary}</p>
                <div className="flex flex-col gap-y-4 mb-4">
                    <div className="flex gap-x-2">
                        <span>Author:</span>
                        <strong>{coursePreviewData?.author}</strong>
                    </div>
                    <div className="flex gap-x-2">
                        <span>Created by:</span>
                        <strong>{coursePreviewData?.created_by}</strong>
                    </div>
                    <div className="flex gap-x-2">
                        <span>Category:</span>
                        <strong>{coursePreviewData?.category}</strong>
                    </div>
                    <div className="flex gap-x-2">
                        <span>Date:</span>
                        <strong>
                            {coursePreviewData?.date
                                ? format(
                                      new Date(coursePreviewData?.date),
                                      "dd/MM/yyyy"
                                  )
                                : ""}
                        </strong>
                    </div>
                </div>
                <h3 className="text-base text-gray-500 uppercase font-bold">
                    Chapters
                </h3>
                {isLoading ? (
                    <div className="mb-6">Loading...</div>
                ) : chapters.length > 0 ? (
                    chapters.map((chapter, index) => (
                        <div
                            key={chapter.id}
                            className="flex gap-4 items-center mb-6"
                        >
                            <Button
                                type="primary"
                                size="large"
                                className="rounded"
                                onClick={() =>
                                    navigate(
                                        `/courses/${coursePreviewData?.id}/chapters/${chapter.id}`
                                    )
                                }
                            >
                                Chapter {index + 1}
                            </Button>
                            <div>{handleTranslateChapter(chapter)}</div>
                            {hasUpdatePermission(permissions, "CHAPTERS") ? (
                                <Button
                                    className="py-1 px-2"
                                    onClick={() =>
                                        navigate(
                                            `/courses/${coursePreviewData?.id}/chapters/${chapter.id}/edit`
                                        )
                                    }
                                >
                                    Edit
                                </Button>
                            ) : null}
                            {hasDeletePermission(permissions, "CHAPTERS") ? (
                                <Popconfirm
                                    title="Are you sure you want to delete this chapter?"
                                    okText="Delete"
                                    onConfirm={() =>
                                        handleDeleteChapter(chapter.id)
                                    }
                                    onCancel={(e) => e.preventDefault()}
                                >
                                    <Button className="py-1 px-2 bg-red-500 text-white">
                                        Delete
                                    </Button>
                                </Popconfirm>
                            ) : null}
                        </div>
                    ))
                ) : (
                    <Alert
                        className="mb-6"
                        message="No chapters added to this course yet."
                        type="info"
                        showIcon
                    />
                )}

                {hasCreatePermission(permissions, "CHAPTERS") ? (
                    <div>
                        <CustomButton
                            text="Add Chapter"
                            action={() =>
                                navigate(
                                    `/courses/${coursePreviewData?.id}/chapter`
                                )
                            }
                        />
                    </div>
                ) : null}
            </div>
        </Modal>
    );
}

export default CoursePreview;
