import _ from "lodash";

const hasCreatePermission = (permissions, page) => {
    if (
        _.find(permissions, {
            name: `CREATE_${page}`,
        })
    ) {
        return true;
    } else {
        return false;
    }
};

const hasGetPermission = (permissions, page) => {
    if (
        _.find(permissions, {
            name: `GET_${page}`,
        })
    ) {
        return true;
    } else {
        return false;
    }
};

const hasUpdatePermission = (permissions, page) => {
    if (
        _.find(permissions, {
            name: `UPDATE_${page}`,
        })
    ) {
        return true;
    } else {
        return false;
    }
};

const hasDeletePermission = (permissions, page) => {
    if (
        _.find(permissions, {
            name: `DELETE_${page}`,
        })
    ) {
        return true;
    } else {
        return false;
    }
};

export {
    hasCreatePermission,
    hasGetPermission,
    hasUpdatePermission,
    hasDeletePermission,
};
