import { FileTextFilled } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import {
    Alert,
    Breadcrumb,
    Button,
    Col,
    Divider,
    Dropdown,
    Form,
    Input,
    Menu,
    Row,
    Tabs,
    Upload,
} from "antd";
import _ from "lodash";
import ImageResize from "quill-image-resize-module-react";
import React, { useEffect, useState } from "react";
import ReactAudio from "react-audio-player";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ReactQuill, { Quill } from "react-quill";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import api from "../../config/api";
import { organizationState, permissionsState, titleState } from "../../store";
import { hasCreatePermission } from "../../utils/checkPermission";
import {
    errorNotification,
    successNotification,
} from "../../utils/notifications";

Quill.register("modules/imageResize", ImageResize);

const fontSizes = [
    "13px",
    "14px",
    "16px",
    "18px",
    "24px",
    "32px",
    "40px",
    "48px",
    "80px",
    "96px",
];
const Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizes;
Quill.register(Size, true);

const { TextArea } = Input;

function TranslateChapter() {
    const [fileList, setFileList] = useState([]);
    const [isImageFile, setImageFile] = useState(false);
    const [audioFileList, setAudioFileList] = useState([]);
    const [docFileList, setDocFileList] = useState([]);
    const [thumbnailPreview, setThumbnailPreview] = useState("");
    const [audioPreview, setAudioPreview] = useState("");
    const [docPreview, setDocPreview] = useState("");
    const setTitle = useSetRecoilState(titleState);
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const [translationForm] = Form.useForm();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { id, chapterId } = useParams();
    const [languages, setLanguages] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([
        { id: 0, name: "Default", closable: false },
    ]);
    const [activeTabKey, setActiveTabKey] = useState("0");
    const [defaultTranslation, setDefaultTranslation] = useState(null);
    const [courseTranslations, setCourseTranslations] = useState(null);

    useEffect(() => {
        if (!hasCreatePermission(permissions, "CHAPTER_TRANSLATIONS")) {
            navigate("/");
        }
    }, [permissions, navigate]);

    useEffect(() => {
        setTitle("Translate Chapter");
    }, [setTitle]);

    const fetchChapter = async () => {
        const { data } = await api.get(
            `/admin/chapter?id=${chapterId}&organization_id=${organization}&:translations->language&:quizzes->options`
        );
        return data;
    };

    const fetchLanguages = async () => {
        const { data } = await api.get(`/admin/languages`);
        return data;
    };

    const { mutate, isLoading: isSaving } = useMutation(
        (translatedChapter) =>
            api.post(`/admin/chapter_translation`, translatedChapter),
        {
            onSuccess: (response, variables, context) => {
                successNotification("Chapter translated successfully");
                queryClient.invalidateQueries("chapter");
                // navigate(`/courses`);
            },
        }
    );

    const { isLoading, isError } = useQuery("chapter", fetchChapter, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            setDefaultTranslation({
                title: result.title,
                summary: result.summary,
                description: result.description,
                youtube_url: result.youtube_url,
                image_name: result.image_name,
                audio_name: result.audio_name,
                file_name: result.file_name,
            });

            if (!_.isEmpty(result.translations)) {
                let langs = [];
                for (let translation of result.translations) {
                    langs.push({ ...translation["language"], closable: true });
                }

                setSelectedLanguages((prev) => {
                    const displayLangs = [...prev];

                    for (const language of langs) {
                        const alreadyInitialized = _.find(prev, [
                            "id",
                            language.id,
                        ]);

                        if (_.isNil(alreadyInitialized)) {
                            displayLangs.push(language);
                        }
                    }

                    return displayLangs;
                });
            }

            setCourseTranslations(result);

            if (Number(activeTabKey) === 0) {
                translationForm.setFieldsValue({
                    title: result.title,
                    summary: result.summary,
                    description: result.description,
                    youtube_url: result.youtube_url,
                    // image_name: result.image_name,
                    // audio_name: result.audio_name,
                });

                setThumbnailPreview(result.image_name);

                setAudioPreview(result.audio_name);

                setDocPreview(result.file_name);
            }
        },
    });

    useQuery("languages", fetchLanguages, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            setLanguages(result);
        },
    });

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const isPicture = ["image/png", "image/jpeg", "image/jpg"].includes(
                file.type
            );

            if (!isPicture) {
                errorNotification(
                    `${file.name} is not an image. Please select an image.`
                );
                setImageFile(false);
            } else {
                setImageFile(true);
            }

            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    const audioUploadProps = {
        onRemove: (file) => {
            const index = audioFileList.indexOf(file);
            const newFileList = audioFileList.slice();
            newFileList.splice(index, 1);
            setAudioFileList(newFileList);
        },
        beforeUpload: (file) => {
            setAudioFileList([...audioFileList, file]);

            return false;
        },
        audioFileList,
    };

    const docUploadProps = {
        onRemove: (file) => {
            const index = docFileList.indexOf(file);
            const newFileList = docFileList.slice();
            newFileList.splice(index, 1);
            setDocFileList(newFileList);
        },
        beforeUpload: (file) => {
            setDocFileList([...docFileList, file]);

            return false;
        },
        docFileList,
    };

    const handleLanguageChange = (tabKey) => {
        setActiveTabKey(tabKey);

        if (Number(tabKey) === 0 && defaultTranslation) {
            translationForm.setFieldsValue({
                title: defaultTranslation.title,
                summary: defaultTranslation.summary,
                description: defaultTranslation.description,
                youtube_url: defaultTranslation.youtube_url,
                // image_name: defaultTranslation.image_name,
                // audio_name: result.audio_name,
            });

            setThumbnailPreview(defaultTranslation.image_name);

            setAudioPreview(defaultTranslation.audio_name);

            setDocPreview(defaultTranslation.file_name);
        } else {
            if (!_.isEmpty(courseTranslations.translations)) {
                const translation = _.find(courseTranslations.translations, [
                    "language_id",
                    Number(tabKey),
                ]);

                if (_.isNil(translation)) {
                    translationForm.setFieldsValue({
                        title: "",
                        summary: "",
                        description: "",
                        youtube_url: "",
                    });

                    setThumbnailPreview("");

                    setAudioPreview("");

                    setDocPreview("");
                } else {
                    translationForm.setFieldsValue({
                        title: translation.title,
                        summary: translation.summary,
                        description: translation.description,
                        youtube_url: translation.youtube_url,
                    });

                    setThumbnailPreview(translation.image_name);

                    setAudioPreview(translation.audio_name);

                    setDocPreview(translation.file_name);
                }
            } else {
                translationForm.setFieldsValue({
                    title: "",
                    summary: "",
                    description: "",
                    youtube_url: "",
                });

                setThumbnailPreview("");

                setAudioPreview("");

                setDocPreview("");
            }
        }
    };

    const deleteChapterTranslation = async (translationId) => {
        await api.delete(
            `/admin/chapter_translation?id=${translationId}&organization_id=${organization}&chapter_id=${chapterId}`
        );
        successNotification("Translation deleted successfully");
        queryClient.invalidateQueries("chapter");
    };

    const onEdit = (tabKey, action) => {
        const listOfTabKeys = [];
        selectedLanguages.map((selectedLanguage) =>
            listOfTabKeys.push(selectedLanguage.id)
        );

        if (action === "remove") {
            // check if from api, fire delete request if so
            if (
                !_.isEmpty(courseTranslations.translations) &&
                !_.isNil(
                    _.find(courseTranslations.translations, [
                        "language_id",
                        Number(tabKey),
                    ])
                )
            ) {
                const translation = _.find(courseTranslations.translations, [
                    "language_id",
                    Number(tabKey),
                ]);
                deleteChapterTranslation(translation.id);
            }

            remove(tabKey);

            const availableTabs = listOfTabKeys.filter((key) => key != tabKey);

            setActiveTabKey(String(availableTabs[availableTabs.length - 1]));
        }
    };

    const add = (language) => {
        setSelectedLanguages((prev) => {
            const alreadySelected = _.find(prev, ["id", language.id]);
            if (_.isNil(alreadySelected)) {
                return [
                    ...prev,
                    {
                        id: language.id,
                        name: language.name,
                        closable: true,
                    },
                ];
            } else {
                return [...prev];
            }
        });
    };

    const remove = (tabKey) => {
        setSelectedLanguages((prev) => {
            const idx = prev.findIndex((item) => +item.id === +tabKey);
            prev.splice(idx, 1);
            return [...prev];
        });
    };

    const handleTranslateChapter = (values) => {
        const { title, summary, description, image, audio, youtube_url, file } =
            values;

        const formData = new FormData();
        formData.append("title", title);
        formData.append("summary", summary);
        formData.append("description", description);
        if (fileList.length > 0 && isImageFile) {
            formData.append("image", image[0]["originFileObj"]);
        } else if (fileList.length > 0 && !isImageFile) {
            errorNotification(
                `${fileList[0].name} is not an image. Please select an image.`
            );
            return;
        }
        if (audioFileList.length > 0) {
            formData.append("audio", audio[0]["originFileObj"]);
        }
        if (docFileList.length > 0) {
            formData.append("file", file[0]["originFileObj"]);
        }
        formData.append("youtube_url", youtube_url);
        formData.append("course_id", id);
        formData.append("language_id", activeTabKey);
        formData.append("organization_id", organization);
        formData.append("chapter_id", chapterId);

        mutate(formData);
    };

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isError) {
        return <>Error...</>;
    }

    return (
        <>
            <Breadcrumb className="mb-6" separator=">">
                <Breadcrumb.Item>
                    <Link to="/courses">Courses</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Translating a chapter</Breadcrumb.Item>
            </Breadcrumb>
            <Dropdown
                trigger={["click"]}
                placement="bottomLeft"
                overlay={
                    <Menu>
                        {languages.map((language) => (
                            <Menu.Item
                                key={language.id}
                                onClick={() => add(language)}
                            >
                                {language.name}
                            </Menu.Item>
                        ))}
                    </Menu>
                }
            >
                <Button
                    type="text"
                    className="border-none text-brand"
                    icon={<Icon icon="akar-icons:circle-plus-fill" />}
                />
            </Dropdown>
            <Tabs
                hideAdd
                type="editable-card"
                onChange={handleLanguageChange}
                activeKey={activeTabKey}
                onEdit={onEdit}
            >
                {selectedLanguages.map((language) => (
                    <Tabs.TabPane
                        tab={language.name}
                        key={language.id}
                        closable={language.closable}
                    >
                        <Row>
                            {Number(activeTabKey) === 0 ? null : (
                                <Col span={8}>
                                    <div className="flex">
                                        <div className="flex flex-col">
                                            <h2>Defaults</h2>
                                            <Divider className="my-2" />
                                            <h2 className="font-bold">
                                                Chapter Topic:
                                            </h2>
                                            <h3 className="text-gray-500">
                                                {defaultTranslation?.title}
                                            </h3>
                                            <h2 className="font-bold">
                                                Summary:
                                            </h2>
                                            <h3 className="text-gray-500">
                                                {defaultTranslation?.summary}
                                            </h3>
                                            <h2 className="font-bold">
                                                Content:
                                            </h2>
                                            <h3
                                                className="text-gray-500"
                                                dangerouslySetInnerHTML={{
                                                    __html: defaultTranslation?.description,
                                                }}
                                            ></h3>
                                            <h2 className="font-bold">
                                                Thumbnail:
                                            </h2>
                                            {Boolean(
                                                defaultTranslation?.image_name
                                            ) ? (
                                                <img
                                                    src={`${process.env.REACT_APP_IMAGE_API}${defaultTranslation?.image_name}`}
                                                    alt=""
                                                    className="h-[109px] w-[109px] object-cover object-center rounded mb-4"
                                                />
                                            ) : null}
                                            <h2 className="font-bold">
                                                Audio:
                                            </h2>
                                            {Boolean(
                                                defaultTranslation?.audio_name
                                            ) ? (
                                                <ReactAudio
                                                    className="mb-4"
                                                    src={`${process.env.REACT_APP_IMAGE_API}${defaultTranslation?.audio_name}`}
                                                    controls
                                                />
                                            ) : null}
                                            <h2 className="font-bold">File:</h2>
                                            {Boolean(
                                                defaultTranslation?.file_name
                                            ) ? (
                                                <a
                                                    href={`${process.env.REACT_APP_IMAGE_API}${defaultTranslation?.file_name}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="block mb-4"
                                                >
                                                    <FileTextFilled className="text-6xl" />
                                                </a>
                                            ) : null}
                                            <h2 className="font-bold">
                                                Youtube Link:
                                            </h2>
                                            <h3 className="text-gray-500">
                                                <a
                                                    href={
                                                        defaultTranslation?.youtube_url
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="block mb-4"
                                                >
                                                    Video
                                                </a>
                                            </h3>
                                        </div>
                                        <Divider
                                            className="h-48"
                                            type="vertical"
                                        />
                                    </div>
                                </Col>
                            )}
                            <Col span={Number(activeTabKey) === 0 ? 24 : 16}>
                                <Form
                                    colon={false}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 18 }}
                                    onFinish={handleTranslateChapter}
                                    requiredMark={false}
                                    autoComplete="off"
                                    form={translationForm}
                                >
                                    <Form.Item
                                        label={
                                            <span className="text-base text-gray-400 uppercase font-bold">
                                                Chapter Topic
                                            </span>
                                        }
                                        name="title"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please input a chapter topic",
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Simple and short title"
                                            disabled={
                                                Number(activeTabKey) === 0
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <span className="text-base text-gray-400 uppercase font-bold">
                                                Summary
                                            </span>
                                        }
                                        name="summary"
                                    >
                                        <TextArea
                                            showCount
                                            maxLength={100}
                                            placeholder="Any short summary about the course"
                                            disabled={
                                                Number(activeTabKey) === 0
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <span className="text-base text-gray-400 uppercase font-bold">
                                                Content
                                            </span>
                                        }
                                        name="description"
                                    >
                                        <ReactQuill
                                            modules={{
                                                toolbar: [
                                                    [{ header: [1, 2, false] }],
                                                    [{ size: fontSizes }],
                                                    [
                                                        "bold",
                                                        "italic",
                                                        "underline",
                                                    ],
                                                    [
                                                        { list: "ordered" },
                                                        { list: "bullet" },
                                                    ],
                                                    [
                                                        { align: "" },
                                                        { align: "center" },
                                                        { align: "right" },
                                                        { align: "justify" },
                                                    ],
                                                    ["image", "video"],
                                                ],
                                                imageResize: {
                                                    parchment:
                                                        Quill.import(
                                                            "parchment"
                                                        ),
                                                    modules: [
                                                        "Resize",
                                                        "DisplaySize",
                                                    ],
                                                },
                                            }}
                                            readOnly={
                                                Number(activeTabKey) === 0
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <span className="text-base text-gray-400 uppercase font-bold">
                                                Thumbnail
                                            </span>
                                        }
                                    >
                                        {Boolean(thumbnailPreview) && (
                                            <img
                                                src={`${process.env.REACT_APP_IMAGE_API}${thumbnailPreview}`}
                                                alt=""
                                                className="h-[109px] w-[109px] object-cover object-center rounded mb-4"
                                            />
                                        )}
                                        {Number(activeTabKey) === 0 ? null : (
                                            <Form.Item
                                                noStyle
                                                name="image"
                                                valuePropName="fileList"
                                                getValueFromEvent={(e) => {
                                                    if (Array.isArray(e)) {
                                                        return e;
                                                    }
                                                    return e && e.fileList;
                                                }}
                                            >
                                                <Upload
                                                    {...uploadProps}
                                                    accept="image/*"
                                                    maxCount={1}
                                                >
                                                    <Button>
                                                        Click to upload
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <span className="text-base text-gray-400 uppercase font-bold">
                                                Audio
                                            </span>
                                        }
                                    >
                                        {Boolean(audioPreview) && (
                                            <ReactAudio
                                                className="mb-4"
                                                src={`${process.env.REACT_APP_IMAGE_API}${audioPreview}`}
                                                controls
                                            />
                                        )}
                                        {Number(activeTabKey) === 0 ? null : (
                                            <Form.Item
                                                noStyle
                                                name="audio"
                                                valuePropName="file"
                                                getValueFromEvent={(e) => {
                                                    if (Array.isArray(e)) {
                                                        return e;
                                                    }
                                                    return e && e.fileList;
                                                }}
                                            >
                                                <Upload
                                                    {...audioUploadProps}
                                                    accept="audio/*"
                                                    maxCount={1}
                                                >
                                                    <Button>
                                                        Click to upload
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <span className="text-base text-gray-400 uppercase font-bold">
                                                File
                                            </span>
                                        }
                                    >
                                        {Boolean(docPreview) && (
                                            <a
                                                href={`${process.env.REACT_APP_IMAGE_API}${docPreview}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="block mb-4"
                                            >
                                                <FileTextFilled className="text-6xl" />
                                            </a>
                                        )}
                                        <Form.Item
                                            noStyle
                                            name="file"
                                            valuePropName="file"
                                            getValueFromEvent={(e) => {
                                                if (Array.isArray(e)) {
                                                    return e;
                                                }
                                                return e && e.fileList;
                                            }}
                                        >
                                            {Number(activeTabKey) ===
                                            0 ? null : (
                                                <Upload
                                                    {...docUploadProps}
                                                    accept=".doc, .docx, .pdf"
                                                    maxCount={1}
                                                >
                                                    <Button>
                                                        Click to upload
                                                    </Button>
                                                </Upload>
                                            )}
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <span className="text-base text-gray-400 uppercase font-bold">
                                                Video
                                            </span>
                                        }
                                        name="youtube_url"
                                        rules={[
                                            {
                                                type: "url",
                                                message:
                                                    "Please input a valid youtube url",
                                            },
                                            {
                                                pattern: new RegExp(
                                                    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/g
                                                ),
                                                message:
                                                    "Please only write a youtube url",
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Link for the video"
                                            disabled={
                                                Number(activeTabKey) === 0
                                            }
                                        />
                                    </Form.Item>
                                    <Row>
                                        <Col span={4}></Col>
                                        <Col
                                            span={18}
                                            className="flex justify-end gap-4"
                                        >
                                            <Button
                                                onClick={() => navigate(-1)}
                                                type="primary"
                                                className="uppercase"
                                                ghost
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="primary"
                                                className="uppercase"
                                                htmlType="submit"
                                                loading={isSaving}
                                                disabled={
                                                    isSaving ||
                                                    Number(activeTabKey) === 0
                                                }
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                ))}
            </Tabs>
            <div className="mt-8">
                <span className="text-base text-gray-500 uppercase font-bold">
                    Quizzes
                </span>
                {courseTranslations?.quizzes?.length > 0 ? (
                    courseTranslations?.quizzes?.map((quiz, index) => (
                        <div
                            key={quiz.id}
                            className="flex gap-4 items-center mb-6"
                        >
                            <Button
                                type="primary"
                                size="large"
                                className="rounded"
                                onClick={() =>
                                    navigate(
                                        `/courses/${id}/chapters/${chapterId}/`
                                    )
                                }
                            >
                                Question {index + 1}
                            </Button>
                            <div>{quiz.question}</div>
                            {Number(activeTabKey) === 0 ? null : (
                                <Button
                                    className="py-1 px-2"
                                    onClick={() =>
                                        navigate(
                                            `/courses/${id}/chapters/${chapterId}/quiz/${quiz.id}/translate`
                                        )
                                    }
                                >
                                    Translate
                                </Button>
                            )}
                        </div>
                    ))
                ) : (
                    <Alert
                        className="mb-6"
                        message="No quizzes added to this chapter yet."
                        type="info"
                        showIcon
                    />
                )}
            </div>
        </>
    );
}

export default TranslateChapter;
