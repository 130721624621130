import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
    key: "organizations",
});

const availableOrganizationsState = atom({
    key: "organizations",
    default: null,
    effects_UNSTABLE: [persistAtom],
});

export default availableOrganizationsState;
