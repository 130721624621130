import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
    key: "organization",
});

const organizationState = atom({
    key: "organization",
    default: 0,
    effects_UNSTABLE: [persistAtom],
});

export default organizationState;
