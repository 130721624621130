import { ControlOutlined, SearchOutlined } from "@ant-design/icons";
import { Checkbox, Divider, Input, Pagination, Popover, Select } from "antd";
import Fuse from "fuse.js";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FloatingButton } from "../../components/UI/Buttons";
import { DiscussionCard } from "../../components/UI/Cards";
import { CustomModal, EditDiscussion } from "../../components/UI/Modals";
import api from "../../config/api";
import {
    mobileNavState,
    organizationState,
    permissionsState,
    titleState,
    userState,
} from "../../store";
import { hasCreatePermission } from "../../utils/checkPermission";

function Discussion() {
    const [modalOpen, setModalOpen] = useState(false);
    const [searchbarVisible, setSearchbarVisible] = useState(false);
    const [totalDiscussions, setTotalDiscussions] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [discussions, setDiscussions] = useState([]);
    const [filteredDiscussions, setFilteredDiscussions] = useState([]);
    const [filterOptions, setFilterOptions] = useState(false);
    const [sort, setSort] = useState("desc");

    const setTitle = useSetRecoilState(titleState);
    const organization = useRecoilValue(organizationState);
    const user = useRecoilValue(userState);
    const permissions = useRecoilValue(permissionsState);
    const setMobileNav = useSetRecoilState(mobileNavState);

    useEffect(() => {
        setTitle("Discussion");
    }, [setTitle]);

    useEffect(() => {
        setMobileNav(false);
    }, [setMobileNav]);

    const fetchDiscussions = async () => {
        const { data } = await api.get(
            `/admin/discussions_paginated?organization_id=${organization}&:created_by=&:comments&^comments&|id=desc&page=${currentPage}&PAGINATE_SIZE=${10}&^replies`
        );
        return data;
    };

    const { isLoading, isError } = useQuery(
        ["discussions", organization, currentPage],
        () => fetchDiscussions(),
        {
            keepPreviousData: true,
            onSuccess: (response) => {
                const { result } = response;
                setDiscussions(result.data);
                // setFilteredDiscussions(result.data);
                setTotalDiscussions(result.total);

                applySort(sort, result.data);
            },
        }
    );

    const handleFilter = (event) => {
        setFilterOptions(event.target.checked);
        if (event.target.checked) {
            setFilteredDiscussions(
                _.filter(discussions, ["created_by.id", user.id])
            );
        } else {
            setFilteredDiscussions(discussions);
        }
    };

    const toggleSearchbar = () => {
        setSearchbarVisible(!searchbarVisible);
    };

    const onSearch = (event) => {
        const discussionsIndex = Fuse.createIndex(["title"], discussions);
        const fuse = new Fuse(
            discussions,
            { keys: ["title"] },
            discussionsIndex
        );
        let foundDiscussions = [];
        if (!_.isEmpty(event.target.value)) {
            const searchResult = fuse.search(event.target.value);
            for (let result of searchResult) {
                foundDiscussions.push(result.item);
            }
            setFilteredDiscussions(foundDiscussions);
        } else {
            setFilteredDiscussions(discussions);
        }
    };

    const handleSort = (value) => {
        setSort(value);
        applySort(value, filteredDiscussions);
    };

    const applySort = (value, discussionsToSort) => {
        if (_.isEqual(value, "title")) {
            setFilteredDiscussions(
                _.orderBy(
                    discussionsToSort,
                    [(discussion) => discussion.title.toLowerCase()],
                    ["asc"]
                )
            );
        } else if (_.isEqual(value, "comments_count")) {
            setFilteredDiscussions(
                _.orderBy(discussionsToSort, ["comments_count"], ["desc"])
            );
        } else if (_.isEqual(value, "desc")) {
            setFilteredDiscussions(
                _.orderBy(discussionsToSort, ["id"], ["desc"])
            );
        }
    };

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isError) {
        return <>Error... Please refresh the page.</>;
    }

    return (
        <>
            <Divider />
            <div className="flex justify-end items-center gap-2 text-lg text-brand mb-4">
                <SearchOutlined onClick={toggleSearchbar} />
                {searchbarVisible ? (
                    <Input className="w-60" onKeyUp={onSearch} />
                ) : null}
                <Popover
                    trigger={["click"]}
                    placement="bottomRight"
                    content={
                        <div>
                            <Checkbox onChange={handleFilter}>
                                My Discussions
                            </Checkbox>
                        </div>
                    }
                >
                    <ControlOutlined />
                </Popover>
                <span className="text-sm">Sort By:</span>
                <Select
                    defaultValue={sort}
                    className="w-36"
                    onChange={handleSort}
                >
                    <Select.Option value="desc">Recent</Select.Option>
                    <Select.Option value="title">Title</Select.Option>
                    <Select.Option value="comments_count">
                        Most commented
                    </Select.Option>
                </Select>
            </div>
            <CustomModal open={modalOpen} closeAction={setModalOpen} />
            <EditDiscussion />
            <div className="flex flex-col gap-12">
                {filteredDiscussions.map((singleResult) => (
                    <DiscussionCard
                        key={singleResult.id}
                        id={singleResult.id}
                        title={singleResult.title}
                        description={singleResult.description}
                        profile_image={singleResult.created_by.image_name}
                        comments_count={
                            singleResult.comments_count +
                            singleResult.replies_count
                        }
                        comments={singleResult.comments}
                        published={Boolean(singleResult.is_published)}
                        author={singleResult.created_by.full_name}
                        time={singleResult.created_at}
                        createdBy={singleResult.created_by.id}
                    />
                ))}
                <Pagination
                    className="self-center"
                    current={currentPage}
                    total={totalDiscussions}
                    hideOnSinglePage={true}
                    onChange={(pageNumber) => setCurrentPage(pageNumber)}
                />
            </div>
            {hasCreatePermission(permissions, "DISCUSSIONS") ? (
                <FloatingButton
                    text="Add Discussion"
                    action={() => setModalOpen(true)}
                />
            ) : null}
        </>
    );
}

export default Discussion;
