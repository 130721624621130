import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Switch,
} from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CustomButton } from "../../components/UI/Buttons";
import api from "../../config/api";
import { organizationState, titleState } from "../../store";
import { successNotification } from "../../utils/notifications";

function EditQuiz() {
    const setTitle = useSetRecoilState(titleState);
    const organization = useRecoilValue(organizationState);
    const { chapterId, quizId } = useParams();
    const navigate = useNavigate();
    const [quizForm] = Form.useForm();

    useEffect(() => {
        setTitle("Edit Question");
    }, [setTitle]);

    const fetchQuestion = async () => {
        const { data } = await api.get(
            `/admin/chapter_quiz?id=${quizId}&chapter_id=${chapterId}&organization_id=${organization}&:options`
        );
        return data;
    };

    const {
        data,
        isLoading: fetchLoading,
        isError: fetchError,
    } = useQuery("single-question", fetchQuestion, {
        cacheTime: 0,
    });

    const { mutate, isLoading, isError, error } = useMutation(
        (editedQuestion) => api.patch("/admin/chapter_quiz", editedQuestion),
        {
            onSuccess: (response, variables, context) => {
                successNotification("Question edited successfully!");
                navigate(-1);
            },
        }
    );

    if (fetchLoading) {
        return <>Loading...</>;
    }

    if (fetchError) {
        return <>Error...</>;
    }

    const handleEditQuiz = async (values) => {
        let editedQuestion = {
            ...values,
        };

        let option = _.find(values["quiz_options"], {
            answer: true,
        });

        if (option.id) {
            editedQuestion["answer_id"] = option.id;
        } else {
            editedQuestion["answer_index"] = _.findIndex(
                values["quiz_options"],
                {
                    answer: true,
                }
            );
        }

        for (
            let quizIndex = 0;
            quizIndex < editedQuestion["quiz_options"].length;
            quizIndex++
        ) {
            if (!_.has(editedQuestion["quiz_options"][quizIndex], "name")) {
                // editedQuestion["quiz_options"][quizIndex]["name"] = (
                //     Number(quizIndex) + 10
                // )
                //     .toString(36)
                //     .toUpperCase();
                editedQuestion["quiz_options"][quizIndex]["order"] = 0;
            }
        }

        editedQuestion["organization_id"] = organization;
        editedQuestion["id"] = quizId;

        mutate(editedQuestion);
    };

    const handleSetAsDefaultChange = (index) => {
        let quizOptions = quizForm.getFieldValue("quiz_options");
        quizOptions.map((option, optionIndex) => {
            if (optionIndex === index) {
                option.answer = true;
            } else {
                option.answer = false;
            }
            return option;
        });
        quizForm.setFieldsValue({ quiz_options: quizOptions });
    };

    return (
        <>
            <Divider />
            <Breadcrumb className="mb-6" separator=">">
                <Breadcrumb.Item>
                    <Link to="/courses">Courses</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Editing a question</Breadcrumb.Item>
            </Breadcrumb>
            <Form
                name="dynamic_form_item"
                labelCol={{ span: 4 }}
                colon={false}
                onFinish={handleEditQuiz}
                requiredMark={false}
                autoComplete="off"
                form={quizForm}
            >
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Question
                        </span>
                    }
                    name="question"
                    rules={[
                        {
                            required: true,
                            message: "Please write a question",
                        },
                    ]}
                    initialValue={data.result?.question}
                >
                    <Input placeholder="Add Question" />
                </Form.Item>
                <Form.List
                    name="quiz_options"
                    initialValue={_.map(data.result?.options, (option) =>
                        option.id == data.result?.answer_id
                            ? _.extend(option, { answer: true })
                            : _.extend(option, { answer: false })
                    )}
                >
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey }, index) => (
                                <Form.Item
                                    key={key}
                                    labelCol={{
                                        span: 8,
                                    }}
                                    label={
                                        <span className="text-base text-gray-400 uppercase font-bold">
                                            Choice {index + 1}
                                        </span>
                                    }
                                >
                                    <Row gutter={16}>
                                        <Col span={2}>
                                            <Form.Item
                                                name={[name, "name"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Please write a choice letter",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                name={[name, "option"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Please write a choice",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Add choice for the question" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item
                                                fieldKey={[fieldKey, "answer"]}
                                                name={[name, "answer"]}
                                                valuePropName="checked"
                                            >
                                                <Switch
                                                    onChange={() =>
                                                        handleSetAsDefaultChange(
                                                            index
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        {/* <Col span={4}>
                                            <MinusCircleOutlined
                                                onClick={() => remove(name)}
                                            />
                                        </Col> */}
                                    </Row>
                                </Form.Item>
                            ))}
                            <div className="flex justify-center mb-6">
                                <CustomButton
                                    text="Add Choice"
                                    action={() => add()}
                                />
                            </div>
                        </>
                    )}
                </Form.List>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Answer Description
                        </span>
                    }
                    name="answer_description"
                    initialValue={data.result?.answer_description}
                >
                    <Input.TextArea />
                </Form.Item>
                <div className="flex justify-end gap-4">
                    <Button
                        type="primary"
                        className="uppercase"
                        ghost
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        className="uppercase"
                        htmlType="submit"
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        Submit
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default EditQuiz;
