import {
    Alert,
    Breadcrumb,
    Button,
    Col,
    Form,
    Input,
    Popconfirm,
    Popover,
    Row,
    Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CustomButton } from "../../components/UI/Buttons";
import api from "../../config/api";
import { organizationState, permissionsState, titleState } from "../../store";
import { hasUpdatePermission } from "../../utils/checkPermission";
import {
    errorNotification,
    successNotification,
} from "../../utils/notifications";

const { TextArea } = Input;

function EditCourse() {
    const [fileList, setFileList] = useState([]);
    const [isImageFile, setImageFile] = useState(false);
    const setTitle = useSetRecoilState(titleState);
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const [categoryForm] = Form.useForm();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [courseData, setCourseData] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        if (!hasUpdatePermission(permissions, "COURSES")) {
            navigate("/");
        }
    }, [permissions, navigate]);

    useEffect(() => {
        setTitle("Edit Course");
    }, [setTitle]);

    const fetchCourse = async () => {
        const { data } = await api.get(
            `/admin/course?id=${id}&organization_id=${organization}`
        );
        return data;
    };

    const fetchCategories = async () => {
        const { data } = await api.get(
            `/admin/course_categories?organization_id=${organization}`
        );
        return data;
    };

    const fetchChapters = async () => {
        const { data } = await api.get(
            `/admin/chapters?course_id=${id}&organization_id=${organization}`
        );
        return data;
    };

    const { mutate, isLoading: isSaving } = useMutation(
        (editedCourse) => api.post(`/admin/course_update`, editedCourse),
        {
            onSuccess: (response, variables, context) => {
                successNotification("Course edited successfully");
                navigate(`/courses`);
            },
        }
    );

    const handleEditCourse = async (values) => {
        const { title, summary, image, course_category_id, author } = values;
        const formData = new FormData();
        formData.append("title", title);
        formData.append("summary", summary);
        formData.append("author", author);
        if (fileList.length > 0 && isImageFile) {
            formData.append("image", image[0]["originFileObj"]);
        } else if (fileList.length > 0 && !isImageFile) {
            errorNotification(
                `${fileList[0].name} is not an image. Please select an image.`
            );
            return;
        }
        formData.append("course_category_id", course_category_id);
        formData.append("id", id);
        formData.append("organization_id", organization);
        mutate(formData);
    };

    useQuery("categories", fetchCategories, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            setCategories(result);
        },
    });

    useQuery("chapters", fetchChapters, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            setChapters(result);
        },
    });

    const { isLoading, isError } = useQuery("course", fetchCourse, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            setCourseData(result);
        },
    });

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isError) {
        return <>Error... Please refresh the page.</>;
    }

    const handleAddCategory = async (values) => {
        const newCategory = {
            ...values,
            organization_id: organization,
        };
        await api.post(`/admin/course_category`, newCategory);
        categoryForm.resetFields();
        successNotification("Category added successfully!");
        queryClient.invalidateQueries("categories");
    };

    const popoverForm = (
        <Form
            autoComplete="off"
            onFinish={handleAddCategory}
            form={categoryForm}
        >
            <Form.Item
                name="name"
                label="Category Name"
                rules={[
                    {
                        required: true,
                        message: "Please write a category name",
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <div className="text-right">
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    );

    const handleDeleteChapter = async (chapterId) => {
        await api.delete(
            `/admin/chapter?organization_id=${organization}&id=${chapterId}`
        );
        queryClient.invalidateQueries("chapters");
    };

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const isPicture = ["image/png", "image/jpeg", "image/jpg"].includes(
                file.type
            );

            if (!isPicture) {
                errorNotification(
                    `${file.name} is not an image. Please select an image.`
                );
                setImageFile(false);
            } else {
                setImageFile(true);
            }

            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    return (
        <>
            <Breadcrumb className="mb-6" separator=">">
                <Breadcrumb.Item>
                    <Link to="/courses">Courses</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Editing a course</Breadcrumb.Item>
            </Breadcrumb>
            <Form
                colon={false}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 18 }}
                onFinish={handleEditCourse}
                encType="multipart/form-data"
                requiredMark={false}
                autoComplete="off"
            >
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Topic
                        </span>
                    }
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Please write a course title",
                        },
                    ]}
                    initialValue={courseData.title}
                >
                    <Input
                        showCount
                        maxLength={40}
                        placeholder="Simple and short title"
                    />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Description
                        </span>
                    }
                    name="summary"
                    rules={[
                        {
                            required: true,
                            message: "Please write a course description",
                        },
                    ]}
                    initialValue={courseData.summary}
                >
                    <TextArea
                        rows={4}
                        showCount
                        maxLength={100}
                        placeholder="Any short discription about the course"
                    />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Author
                        </span>
                    }
                    name="author"
                    initialValue={courseData.author}
                >
                    <Input placeholder="Name of the author" />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Thumbnail
                        </span>
                    }
                >
                    <img
                        src={`${process.env.REACT_APP_IMAGE_API}${courseData.image_name}`}
                        alt=""
                        className="h-[109px] w-[109px] object-cover object-center rounded mb-4"
                    />
                    <Form.Item
                        name="image"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e && e.fileList;
                        }}
                    >
                        <Upload {...uploadProps} accept="image/*" maxCount={1}>
                            <Button>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Category
                        </span>
                    }
                >
                    <Form.Item
                        noStyle
                        name="course_category_id"
                        rules={[
                            {
                                required: true,
                                message: "Please select a course category",
                            },
                        ]}
                        initialValue={courseData.course_category_id}
                    >
                        <select className="bg-brand text-white rounded py-2 px-4">
                            <option value="" hidden>
                                Select a category
                            </option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </Form.Item>
                    <Popover
                        trigger={["click"]}
                        placement="right"
                        content={popoverForm}
                    >
                        <Button className="ml-4">Add Category</Button>
                    </Popover>
                </Form.Item>
                <Row>
                    <Col span={4}></Col>
                    <Col span={18} className="flex justify-end gap-4">
                        <Button
                            onClick={() => navigate(-1)}
                            type="primary"
                            className="uppercase"
                            ghost
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            className="uppercase"
                            htmlType="submit"
                            loading={isSaving}
                            disabled={isSaving}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
                <div className="mt-8">
                    <h3 className="text-base text-gray-500 uppercase font-bold">
                        Chapters
                    </h3>
                    {chapters.length > 0 ? (
                        chapters.map((chapter, index) => (
                            <div
                                key={chapter.id}
                                className="flex gap-4 items-center mb-6"
                            >
                                <Button
                                    type="primary"
                                    size="large"
                                    className="rounded"
                                    onClick={() =>
                                        navigate(
                                            `/courses/${id}/chapters/${chapter.id}`
                                        )
                                    }
                                >
                                    Chapter {index + 1}
                                </Button>
                                <div>{chapter.title}</div>
                                <Button
                                    className="py-1 px-2"
                                    onClick={() =>
                                        navigate(
                                            `/courses/${id}/chapters/${chapter.id}/edit`
                                        )
                                    }
                                >
                                    Edit
                                </Button>
                                <Popconfirm
                                    title="Are you sure you want to delete this chapter?"
                                    okText="Delete"
                                    onConfirm={() =>
                                        handleDeleteChapter(chapter.id)
                                    }
                                    onCancel={(e) => e.preventDefault()}
                                >
                                    <Button className="py-1 px-2 bg-red-500 text-white">
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </div>
                        ))
                    ) : (
                        <Alert
                            className="mb-6"
                            message="No chapters added to this course yet."
                            type="info"
                            showIcon
                        />
                    )}
                    <CustomButton
                        text="Add Chapter"
                        action={() => navigate(`/courses/${id}/chapter`)}
                    />
                </div>
            </Form>
        </>
    );
}

export default EditCourse;
