import {
    CaretLeftFilled,
    CaretRightFilled,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { Avatar, Button, Card, Divider, Input, Switch } from "antd";
import { formatDistanceToNow } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { UserPreview } from "../../components/UI/Modals";
import api from "../../config/api";
import {
    mobileNavState,
    organizationState,
    permissionsState,
    titleState,
    userPreviewState,
} from "../../store";
import { hasUpdatePermission } from "../../utils/checkPermission";
import { imageChecker } from "../../utils/imageChecker";
import { getInitals } from "../../utils/initials";
import { successNotification } from "../../utils/notifications";

function MobileUsers() {
    const [pageNumber, setPageNumber] = useState(0);
    const [pageLimit, setPageLimit] = useState(10);
    const [totalUsers, setTotalUsers] = useState(0);
    const [activeUsers, setActiveUsers] = useState(0);
    const [males, setMales] = useState(0);
    const [females, setFemales] = useState(0);
    const [searchbarVisible, setSearchbarVisible] = useState(false);
    const [mobileUsers, setMobileUsers] = useState([]);
    const [pageCount, setPageCount] = useState(0);

    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const setTitle = useSetRecoilState(titleState);
    const setMobileNav = useSetRecoilState(mobileNavState);
    const setUserPreviewVisibility = useSetRecoilState(
        userPreviewState.visible
    );
    const setUserPreviewData = useSetRecoilState(userPreviewState.data);

    const queryClient = useQueryClient();

    const openUserPreview = (data) => {
        setUserPreviewVisibility(true);
        setUserPreviewData(data);
    };

    const closeUserPreview = () => {
        setUserPreviewData(null);
        setUserPreviewVisibility(false);
    };

    useEffect(() => {
        setTitle("Mobile Users");
    }, [setTitle]);

    useEffect(() => {
        setMobileNav(false);
    }, [setMobileNav]);

    const fetchMobileUsers = async (pageNumber, pageSize) => {
        const { data } = await api.get(
            `/admin/users_paginated?organization_id=${organization}&:organization_user&user_type=APP&page=${
                pageNumber + 1
            }&PAGINATE_SIZE=${pageSize}&:courses&:last_seen`
        );
        return data;
    };

    const { isLoading, isError } = useQuery(
        ["mobile-users", pageNumber, pageLimit, organization],
        () => fetchMobileUsers(pageNumber, pageLimit),
        {
            keepPreviousData: true,
            onSuccess: (response) => {
                const { result, males, females } = response;
                const { data, last_page, total } = result;
                setMobileUsers(data);
                setPageCount(last_page);
                setTotalUsers(total);
                setMales(males);
                setFemales(females);
            },
        }
    );

    const toggleUserActiveStatus = async (checked, userId) => {
        if (checked) {
            await api.patch(
                `/admin/organization_user?organization_id=${organization}&user_id=${userId}&is_active=${Number(
                    checked
                )}`
            );
            successNotification("Mobile user set active successfully!");
            queryClient.invalidateQueries("mobile-users");
        } else {
            await api.patch(
                `/admin/organization_user?organization_id=${organization}&user_id=${userId}&is_active=${Number(
                    checked
                )}`
            );
            successNotification("Mobile user set inactive successfully!");
            queryClient.invalidateQueries("mobile-users");
        }
    };

    const data = React.useMemo(() => mobileUsers, [mobileUsers]);

    const columns = React.useMemo(
        () => [
            {
                Header: "",
                accessor: "image_name",
                Cell: ({ row }) => {
                    if (row.original.image_name) {
                        return (
                            <div className="text-center">
                                <Avatar
                                    size="large"
                                    src={
                                        imageChecker(row.original.image_name)
                                            ? row.original.image_name
                                            : `${process.env.REACT_APP_IMAGE_API}${row.original.image_name}`
                                    }
                                />
                            </div>
                        );
                    } else {
                        return (
                            <div className="text-center">
                                <Avatar size="large" className="bg-brand">
                                    {getInitals(row.original.full_name)}
                                </Avatar>
                            </div>
                        );
                    }
                },
            },
            {
                Header: "Name",
                accessor: "full_name",
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Last Seen",
                accessor: "last_seen",
                Cell: ({ row }) => {
                    if (!_.isNil(row.original.last_seen)) {
                        return (
                            <span>
                                {formatDistanceToNow(
                                    new Date(row.original.last_seen),
                                    {
                                        addSuffix: true,
                                    }
                                )}
                            </span>
                        );
                    } else {
                        return <span></span>;
                    }
                },
            },
            {
                Header: "Active",
                accessor: "active",
                Cell: ({ row }) => {
                    return (
                        <Switch
                            onChange={(value, event) => {
                                event.stopPropagation();
                                toggleUserActiveStatus(value, row.original.id);
                            }}
                            checked={Boolean(
                                Number(row.original.organization_user.is_active)
                            )}
                            checkedChildren={<Icon icon="typcn:tick" />}
                            unCheckedChildren={<Icon icon="jam:close" />}
                        />
                    );
                },
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        nextPage,
        previousPage,
        gotoPage,
        canPreviousPage,
        canNextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: pageNumber,
                pageSize: pageLimit,
                hiddenColumns: [
                    !hasUpdatePermission(permissions, "ORGANIZATION_USERS")
                        ? "active"
                        : "",
                ],
            },
            manualPagination: true,
            pageCount,
        },
        useGlobalFilter,
        usePagination
    );

    useEffect(() => {
        setPageNumber(pageIndex);
    }, [pageIndex]);

    useEffect(() => {
        setPageLimit(pageSize);
    }, [pageSize]);

    const toggleSearchbar = () => {
        setSearchbarVisible(!searchbarVisible);
    };

    const onSearch = (event) => {
        setGlobalFilter(event.target.value);
    };

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isError) {
        return <>Error... Please refresh the page.</>;
    }

    return (
        <>
            <Divider className="mb-8" />
            <div className="grid grid-cols-2 lg:grid-cols-4 mb-16 gap-4 lg:gap-16">
                {[
                    {
                        count: totalUsers,
                        title: "Total Users",
                    },
                    {
                        count: activeUsers,
                        title: "Active Users",
                    },
                    {
                        count: males,
                        title: "Males",
                    },
                    {
                        count: females,
                        title: "Females",
                    },
                ].map((stat) => (
                    <Card
                        key={stat.title}
                        className="shadow-lg font-bold text-center"
                    >
                        <span className="text-brand mr-1">{stat.count}</span>
                        <span className="text-gray-400">{stat.title}</span>
                    </Card>
                ))}
            </div>
            {/* <pre>
                <code>
                    {JSON.stringify(
                        {
                            pageSize,
                            pageIndex,
                            canPreviousPage,
                            canNextPage,
                            pageCount,
                        },
                        null,
                        2
                    )}
                </code>
            </pre> */}
            <div className="flex items-center justify-end gap-2">
                <SearchOutlined
                    className="text-brand"
                    onClick={toggleSearchbar}
                />
                {searchbarVisible ? (
                    <Input
                        className="w-60"
                        defaultValue={globalFilter}
                        onKeyUp={onSearch}
                    />
                ) : null}
                <select
                    className="border border-brand rounded"
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[5, 10, 20, 50, 100, 200].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
                <div className="flex text-brand">
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => gotoPage(0)}
                        icon={<DoubleLeftOutlined />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        icon={<CaretLeftFilled />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        icon={<CaretRightFilled />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => gotoPage(pageCount - 1)}
                        icon={<DoubleRightOutlined />}
                    />
                </div>
            </div>
            <table {...getTableProps()} className="w-full">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            className="text-left text-gray-400"
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    className="font-normal"
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className="odd:bg-gray-100 hover:bg-brand hover:opacity-40 hover:text-white text-left"
                                onClick={() => openUserPreview(row.original)}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            className="py-4"
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="flex items-center justify-end gap-2">
                <span>
                    Page {pageIndex + 1} / {pageOptions.length}
                </span>
                <select
                    className="border border-brand rounded"
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[5, 10, 20, 50, 100, 200].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
                <div className="flex text-brand">
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => gotoPage(0)}
                        icon={<DoubleLeftOutlined />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        icon={<CaretLeftFilled />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        icon={<CaretRightFilled />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => gotoPage(pageCount - 1)}
                        icon={<DoubleRightOutlined />}
                    />
                </div>
                <span>Total Mobile Users: {totalUsers}</span>
            </div>
            <UserPreview />
        </>
    );
}

export default MobileUsers;
