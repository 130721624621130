import { Button, Col, Divider, Form, Input, Row } from "antd";
import _ from "lodash";
import React from "react";
import { useMutation } from "react-query";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import api from "../../config/api";
import {
    availableOrganizationsState,
    tokenState,
    userState,
} from "../../store";
import { errorNotification } from "../../utils/notifications";

import Background from "../../assets/images/kids.webp";
import CompassionLogo from "../../assets/logos/compassion.svg";
import GreatCommissionLogo from "../../assets/logos/Great-commission.svg";
import HulentenawiLogo from "../../assets/logos/Hulentenawi-horizontal.svg";

function Login() {
    const navigate = useNavigate();
    const [token, setToken] = useRecoilState(tokenState);
    const [user, setUser] = useRecoilState(userState);
    const [availableOrganizations, setAvailableOrganizations] = useRecoilState(
        availableOrganizationsState
    );

    const { mutate, isLoading, isError, error } = useMutation(
        (credentials) => api.post("/auth/authenticate", credentials),
        {
            onSuccess: async (response, variables, context) => {
                const { data } = response;
                if (_.isEqual(data.result?.user?.user_type, "ADMIN")) {
                    setToken(data.result.token);
                    setUser(data.result.user);
                    setAvailableOrganizations(data.result.organizations);
                    navigate("/pick-organization");
                } else {
                    errorNotification("Unauthorized user!");
                }
            },
        }
    );

    const handleLogin = (values) => {
        mutate(values);
    };

    if (token && user && !_.isEmpty(availableOrganizations)) {
        return <Navigate to="/pick-organization" />;
    }

    return (
        <div
            className="grid place-items-center h-screen login py-4"
            style={{
                backgroundImage: `linear-gradient(
                                rgba(4, 93, 184, 0.8), 
                                rgba(4, 93, 184, 0.8)
                            ), url(${Background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <div>
                <div className="flex justify-center mb-8">
                    <img
                        src={HulentenawiLogo}
                        alt="Hulentenawi Logo"
                        className="w-80"
                    />
                </div>
                <div
                    className="px-8 lg:px-32 flex flex-col items-center rounded-2xl"
                    style={{ backgroundColor: "rgba(4, 93, 184, 0.6)" }}
                >
                    <div className="flex flex-col h-full mb-[18px]">
                        <h1 className="mt-[22px] text-white uppercase font-bold mb-8">
                            Login
                        </h1>
                        <Form
                            className="w-[330px] my-auto"
                            layout="vertical"
                            requiredMark={false}
                            onFinish={handleLogin}
                            autoComplete="off"
                        >
                            <Form.Item
                                label={
                                    <span className="text-white uppercase font-bold">
                                        Email Address
                                    </span>
                                }
                                name="email"
                                validateStatus={
                                    isError && error.response.data.errors.email
                                        ? "error"
                                        : ""
                                }
                                help={
                                    isError && error.response.data.errors.email
                                        ? error.response.data.errors.email
                                        : ""
                                }
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <span className="text-white uppercase font-bold">
                                        Password
                                    </span>
                                }
                                name="password"
                                validateStatus={
                                    isError &&
                                    error.response.data.errors.password
                                        ? "error"
                                        : ""
                                }
                                help={
                                    isError &&
                                    error.response.data.errors.password
                                        ? error.response.data.errors.password
                                        : ""
                                }
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                            <Row className="text-center mt-2">
                                <Col span={24}>
                                    <Button
                                        className="text-brand rounded-sm w-[180px]"
                                        htmlType="submit"
                                        loading={isLoading}
                                        disabled={isLoading}
                                    >
                                        Login
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <Link to="/forgot-password">
                        <h1 className="mt-auto text-white">Forgot Password?</h1>
                    </Link>
                </div>
                <div className="text-center">
                    <Divider className="text-white">Or</Divider>
                    <Button
                        className="text-white rounded-sm w-[180px]"
                        ghost
                        onClick={() => navigate("/signup")}
                    >
                        Register
                    </Button>
                </div>
            </div>
            <div className="flex flex-col items-center">
                <div className="text-white">Powered By</div>
                <div className="flex flex-col lg:flex-row items-baseline gap-[57px]">
                    <img
                        src={CompassionLogo}
                        alt="Compassion Logo"
                        className="w-56"
                    />
                    <img
                        src={GreatCommissionLogo}
                        alt="Great Commission Logo"
                        className="w-56"
                    />
                </div>
            </div>
        </div>
    );
}

export default Login;
