import { promiseGetRecoil } from "recoil-outside";
import { tokenState } from "../store";

// export const setTest = async () => {
//     await promiseSetRecoil(store, "Success");
// };

export const getToken = async () => {
    const value = await promiseGetRecoil(tokenState);
    return value;
};
