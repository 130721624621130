import { Route, Routes } from "react-router-dom";
import "./App.less";
import ProtectedRoute from "./hoc/ProtectedRoute";
import MainLayout from "./layouts/MainLayout";
import AdminUsers from "./pages/AdminUsers/AdminUsers";
import EditChapter from "./pages/Chapters/EditChapter";
import NewChapter from "./pages/Chapters/NewChapter";
import SingleChapter from "./pages/Chapters/SingleChapter";
import CourseCategories from "./pages/CourseCategories/CourseCategories";
import Courses from "./pages/Courses/Courses";
import EditCourse from "./pages/Courses/EditCourse";
import NewCourse from "./pages/Courses/NewCourse";
import Dashboard from "./pages/Dashboard/Dashboard";
import Discussion from "./pages/Discussion/Discussion";
import SingleDiscussion from "./pages/Discussion/SingleDiscussion";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Login from "./pages/Login/Login";
import MobileUsers from "./pages/MobileUsers/MobileUsers";
import NewPassword from "./pages/NewPassword/NewPassword";
import NotFound from "./pages/NotFound/NotFound";
import PickOrganization from "./pages/PickOrganization/PickOrganization";
import EditQuiz from "./pages/Quizzes/EditQuiz";
import NewQuiz from "./pages/Quizzes/NewQuiz";
import SetPassword from "./pages/SetPassword/SetPassword";
import Signup from "./pages/Signup/Signup";
import TranslateChapter from "./pages/Translate/TranslateChapter";
import TranslateCourse from "./pages/Translate/TranslateCourse";
import TranslateQuestion from "./pages/Translate/TranslateQuestion";
import WaitingConfirmation from "./pages/WaitingConfirmation/WaitingConfirmation";

function App() {
    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <MainLayout />
                        </ProtectedRoute>
                    }
                >
                    <Route index element={<Dashboard />} />
                    <Route path="courses" element={<Courses />} />
                    <Route path="courses/new" element={<NewCourse />} />
                    <Route path="courses/:id/edit" element={<EditCourse />} />
                    <Route
                        path="courses/:id/translate"
                        element={<TranslateCourse />}
                    />
                    <Route
                        path="courses/:id/chapter"
                        element={<NewChapter />}
                    />
                    <Route
                        path="courses/:id/chapters/:chapterId"
                        element={<SingleChapter />}
                    />
                    <Route
                        path="courses/:id/chapters/:chapterId/edit"
                        element={<EditChapter />}
                    />
                    <Route
                        path="courses/:id/chapters/:chapterId/translate"
                        element={<TranslateChapter />}
                    />
                    <Route
                        path="courses/:id/chapters/:chapterId/quiz"
                        element={<NewQuiz />}
                    />
                    <Route
                        path="courses/:id/chapters/:chapterId/quiz/:quizId/edit"
                        element={<EditQuiz />}
                    />
                    <Route
                        path="courses/:id/chapters/:chapterId/quiz/:quizId/translate"
                        element={<TranslateQuestion />}
                    />
                    <Route path="discussion" element={<Discussion />} />
                    <Route
                        path="discussion/:id"
                        element={<SingleDiscussion />}
                    />
                    <Route path="mobile-users" element={<MobileUsers />} />
                    <Route path="admin-users" element={<AdminUsers />} />
                    <Route
                        path="course-categories"
                        element={<CourseCategories />}
                    />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<NewPassword />} />
                <Route path="/set-password" element={<SetPassword />} />
                <Route
                    path="/waiting-confirmation"
                    element={<WaitingConfirmation />}
                />
                <Route
                    path="/pick-organization"
                    element={<PickOrganization />}
                />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
}

export default App;
