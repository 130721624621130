import React from "react";
import { Avatar, Popconfirm, Switch } from "antd";
import { DeleteFilled, MessageOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useQueryClient } from "react-query";
import { formatDistanceToNow } from "date-fns";
import { Icon } from "@iconify/react";
import _ from "lodash";

import api from "../../../../config/api";
import {
    editDiscussionState,
    organizationState,
    permissionsState,
    userState,
} from "../../../../store";
import { successNotification } from "../../../../utils/notifications";
import { getInitals } from "../../../../utils/initials";
import { imageChecker } from "../../../../utils/imageChecker";
import {
    hasDeletePermission,
    hasUpdatePermission,
} from "../../../../utils/checkPermission";

function DiscussionCard({
    id,
    profile_image,
    title,
    description,
    comments_count,
    comments,
    published,
    author,
    time,
    createdBy,
}) {
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const setEditDiscussionOpen = useSetRecoilState(
        editDiscussionState.visible
    );
    const setEditDiscussionData = useSetRecoilState(editDiscussionState.data);
    const user = useRecoilValue(userState);

    const queryClient = useQueryClient();

    const handlePublishDiscussion = async (id, value, event) => {
        event.preventDefault();
        await api.patch(`/admin/discussion`, {
            id,
            organization_id: organization,
            is_published: Number(value),
        });
        queryClient.invalidateQueries("discussions");
        if (Number(value)) {
            successNotification("Discussion published successfully!");
        } else {
            successNotification("Discussion unpublished successfully!");
        }
    };

    const handleDeleteDiscussion = async (id, event) => {
        event.preventDefault();
        await api.delete(
            `/admin/discussion?id=${id}&organization_id=${organization}`
        );
        queryClient.invalidateQueries("discussions");
        successNotification("Discussion deleted successfully!");
    };

    return (
        <Link to={`/discussion/${id}`}>
            <div className="flex flex-col gap-y-8 lg:gap-y-0 lg:flex-row bg-white items-center p-6 shadow-lg">
                {profile_image ? (
                    <Avatar
                        size={84}
                        src={
                            imageChecker(profile_image)
                                ? profile_image
                                : `${process.env.REACT_APP_IMAGE_API}${profile_image}`
                        }
                    />
                ) : (
                    <Avatar size={84} className="bg-brand">
                        {getInitals(author)}
                    </Avatar>
                )}
                <div className="lg:ml-10 mr-auto text-gray-500 flex flex-col gap-y-2">
                    <div className="uppercase font-bold text-base">{title}</div>
                    <div className="max-w-[443px] text-xs lg:truncate">
                        {description}
                    </div>
                    <div className="flex gap-2 text-xs">
                        <span>By: {author}</span>
                        <span className="text-gray-400">
                            {formatDistanceToNow(new Date(time), {
                                addSuffix: true,
                            })}
                        </span>
                    </div>
                </div>
                <div className="flex items-end gap-8">
                    <div className="flex items-center gap-4">
                        <div className="text-gray-400 flex items-center gap-1">
                            <span>{comments_count}</span>
                            <Icon icon="bx:comment" />
                        </div>
                        <Avatar.Group>
                            {comments.slice(0, 4).map((comment) => (
                                <React.Fragment key={comment.id}>
                                    {comment.user?.image_name ? (
                                        <Avatar
                                            key={comment.id}
                                            src={comment.user?.image_name}
                                        />
                                    ) : (
                                        <Avatar
                                            key={comment.id}
                                            className="bg-brand"
                                        >
                                            {getInitals(
                                                comment.user?.full_name
                                            )}
                                        </Avatar>
                                    )}
                                </React.Fragment>
                            ))}
                        </Avatar.Group>
                    </div>
                    {hasUpdatePermission(permissions, "DISCUSSIONS") ? (
                        <Switch
                            defaultChecked={published}
                            onChange={(value, event) =>
                                handlePublishDiscussion(id, value, event)
                            }
                            checkedChildren={<Icon icon="typcn:tick" />}
                            unCheckedChildren={<Icon icon="jam:close" />}
                        />
                    ) : null}
                    {hasDeletePermission(permissions, "DISCUSSIONS") ? (
                        <Popconfirm
                            title="Are you sure you want to delete this discussion?"
                            okText="Delete"
                            onConfirm={(event) =>
                                handleDeleteDiscussion(id, event)
                            }
                            onCancel={(e) => e.preventDefault()}
                        >
                            <Icon
                                icon="fluent:delete-off-20-filled"
                                className="text-red-500 text-2xl cursor-pointer"
                                onClick={(event) => event.preventDefault()}
                            />
                        </Popconfirm>
                    ) : null}
                    {_.isEqual(user.id, createdBy) &&
                    hasUpdatePermission(permissions, "DISCUSSIONS") ? (
                        <Icon
                            icon="eva:edit-fill"
                            className="text-2xl cursor-pointer"
                            onClick={(event) => {
                                event.preventDefault();
                                setEditDiscussionOpen(true);
                                setEditDiscussionData({
                                    id,
                                    title,
                                    description,
                                });
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </Link>
    );
}

export default DiscussionCard;
