import { Button, Col, Form, Input, Row } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Background from "../../assets/images/kids.webp";
import CompassionLogo from "../../assets/logos/compassion.svg";
import GreatCommissionLogo from "../../assets/logos/Great-commission.svg";
import HulentenawiLogo from "../../assets/logos/Hulentenawi-horizontal.svg";
import api from "../../config/api";
import { tokenState } from "../../store";

function SetPassword() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = useRecoilValue(tokenState);

    useEffect(() => {
        if (token) {
            navigate("/");
        }
    }, [token, navigate]);

    const { mutate, isLoading, isError, error } = useMutation(
        (resetInformation) =>
            api.post("/auth/reset_password", resetInformation),
        {
            onSuccess: (response, variables, context) => {
                navigate("/login");
            },
        }
    );

    const handleSetPassword = (values) => {
        let reset_token = searchParams.get("reset_token");
        if (!_.isNil(reset_token)) {
            let resetInfo = _.omit(values, ["confirmPassword"]);
            resetInfo = {
                ...resetInfo,
                reset_token,
            };

            mutate(resetInfo);
        }
    };

    return (
        <div
            className="grid place-items-center h-screen py-4"
            style={{
                backgroundImage: `linear-gradient(
                                rgba(4, 93, 184, 0.8), 
                                rgba(4, 93, 184, 0.8)
                            ), url(${Background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <div>
                <div className="flex justify-center mb-8">
                    <img
                        src={HulentenawiLogo}
                        alt="Hulentenawi Logo"
                        className="w-80"
                    />
                </div>
                <div
                    className="px-8 lg:px-32 py-6 flex flex-col items-center rounded-2xl"
                    style={{ backgroundColor: "rgba(4, 93, 184, 0.6)" }}
                >
                    <div className="flex flex-col h-full">
                        <Form
                            className="w-[330px] my-auto"
                            layout="vertical"
                            requiredMark={false}
                            autoComplete="off"
                            onFinish={handleSetPassword}
                        >
                            <Form.Item
                                label={
                                    <span className="text-white uppercase font-bold">
                                        Full Name
                                    </span>
                                }
                                name="full_name"
                                rules={[
                                    {
                                        required: true,
                                        message: `Full name is required`,
                                    },
                                ]}
                            >
                                <Input placeholder="Full Name" />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <span className="text-white uppercase font-bold">
                                        Phone Number
                                    </span>
                                }
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: `Phone number is required`,
                                    },
                                    {
                                        pattern: new RegExp(
                                            /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
                                        ),
                                        message:
                                            "Please input a valid phone number",
                                    },
                                ]}
                            >
                                <Input placeholder="Phone number" type="tel" />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <span className="text-white uppercase font-bold">
                                        New Password
                                    </span>
                                }
                                name="password"
                                validateStatus={
                                    isError &&
                                    error.response.data.errors.password
                                        ? "error"
                                        : ""
                                }
                                help={
                                    isError &&
                                    error.response.data.errors.password
                                        ? error.response.data.errors.password
                                        : ""
                                }
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                            <Form.Item
                                label={
                                    <span className="text-white uppercase font-bold">
                                        Confirm Password
                                    </span>
                                }
                                name="confirmPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please confirm your password",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue("password") ===
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    "The two passwords you entered do not match"
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Confirm Password" />
                            </Form.Item>
                            <Row className="text-center">
                                <Col span={24}>
                                    <Button
                                        type="secondary text-brand rounded-sm w-[180px]"
                                        htmlType="submit"
                                        loading={isLoading}
                                        disabled={isLoading}
                                    >
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row items-baseline gap-[57px]">
                <img
                    src={CompassionLogo}
                    alt="Compassion Logo"
                    className="w-56"
                />
                <img
                    src={GreatCommissionLogo}
                    alt="Great Commission Logo"
                    className="w-56"
                />
            </div>
        </div>
    );
}

export default SetPassword;
