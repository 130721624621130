import { Icon } from "@iconify/react";
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Dropdown,
    Form,
    Input,
    Menu,
    Row,
    Switch,
    Tabs,
} from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import api from "../../config/api";
import { organizationState, permissionsState, titleState } from "../../store";
import { hasCreatePermission } from "../../utils/checkPermission";
import { successNotification } from "../../utils/notifications";

function TranslateQuestion() {
    const [languages, setLanguages] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([
        { id: 0, name: "Default", closable: false },
    ]);
    const [activeTabKey, setActiveTabKey] = useState("0");
    const [defaultTranslation, setDefaultTranslation] = useState(null);
    const [courseTranslations, setCourseTranslations] = useState(null);
    const setTitle = useSetRecoilState(titleState);
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const { chapterId, quizId } = useParams();
    const navigate = useNavigate();
    const [quizForm] = Form.useForm();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!hasCreatePermission(permissions, "CHAPTER_QUIZ_TRANSLATIONS")) {
            navigate("/");
        }
    }, [permissions, navigate]);

    useEffect(() => {
        setTitle("Translate Question");
    }, [setTitle]);

    const fetchQuestion = async () => {
        const { data } = await api.get(
            `/admin/chapter_quiz?id=${quizId}&chapter_id=${chapterId}&organization_id=${organization}&:translations->option_translations&:translations->language&:options->translations`
        );
        return data;
    };

    const fetchLanguages = async () => {
        const { data } = await api.get(`/admin/languages`);
        return data;
    };

    const { mutate, isLoading: isSaving } = useMutation(
        (translatedQuestion) =>
            api.post(`/admin/chapter_quiz_translation`, translatedQuestion),
        {
            onSuccess: (response, variables, context) => {
                successNotification("Question translated successfully");
                queryClient.invalidateQueries("single-question");
                navigate(`/courses`);
            },
        }
    );

    useQuery("languages", fetchLanguages, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            setLanguages(result);
        },
    });

    const { isLoading, isError } = useQuery("single-question", fetchQuestion, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;

            const extendedOptions = _.map(result.options, (option) =>
                option.id == result.answer_id
                    ? _.extend(option, { answer: true })
                    : _.extend(option, { answer: false })
            );

            setDefaultTranslation({
                question: result.question,
                answer_description: result.answer_description,
                quiz_options: extendedOptions,
            });

            if (!_.isEmpty(result.translations)) {
                let langs = [];
                for (let translation of result.translations) {
                    langs.push({
                        ...translation["language"],
                        closable: true,
                    });
                }

                setSelectedLanguages((prev) => {
                    const displayLangs = [...prev];

                    for (const language of langs) {
                        const alreadyInitialized = _.find(prev, [
                            "id",
                            language.id,
                        ]);

                        if (_.isNil(alreadyInitialized)) {
                            displayLangs.push(language);
                        }
                    }

                    return displayLangs;
                });
            }

            setCourseTranslations(result);

            if (Number(activeTabKey) === 0) {
                quizForm.setFieldsValue({
                    question: result.question,
                    answer_description: result.answer_description,
                    quiz_options: extendedOptions,
                });
            }
        },
    });

    const remove = (tabKey) => {
        setSelectedLanguages((prev) => {
            const idx = prev.findIndex((item) => +item.id === +tabKey);
            prev.splice(idx, 1);
            return [...prev];
        });
    };

    const deleteQuestionTranslation = async (translationId) => {
        await api.delete(
            `/admin/chapter_quiz_translation?id=${translationId}&organization_id=${organization}`
        );
        successNotification("Translation deleted successfully");
        queryClient.invalidateQueries("single-question");
    };

    const onEdit = (tabKey, action) => {
        const listOfTabKeys = [];
        selectedLanguages.map((selectedLanguage) =>
            listOfTabKeys.push(selectedLanguage.id)
        );

        if (action === "remove") {
            // check if from api, fire delete request if so
            if (
                !_.isEmpty(courseTranslations.translations) &&
                !_.isNil(
                    _.find(courseTranslations.translations, [
                        "language_id",
                        Number(tabKey),
                    ])
                )
            ) {
                const translation = _.find(courseTranslations.translations, [
                    "language_id",
                    Number(tabKey),
                ]);
                deleteQuestionTranslation(translation.id);
            }

            remove(tabKey);

            const availableTabs = listOfTabKeys.filter((key) => key != tabKey);

            setActiveTabKey(String(availableTabs[availableTabs.length - 1]));
        }
    };

    const add = (language) => {
        setSelectedLanguages((prev) => {
            const alreadySelected = _.find(prev, ["id", language.id]);
            if (_.isNil(alreadySelected)) {
                return [
                    ...prev,
                    {
                        id: language.id,
                        name: language.name,
                        closable: true,
                    },
                ];
            } else {
                return [...prev];
            }
        });
    };

    const handleLanguageChange = (tabKey) => {
        setActiveTabKey(tabKey);

        if (Number(tabKey) === 0 && defaultTranslation) {
            quizForm.setFieldsValue({
                question: defaultTranslation.question,
                answer_description: defaultTranslation.answer_description,
                quiz_options: defaultTranslation.quiz_options,
            });
        } else {
            if (!_.isEmpty(courseTranslations.translations)) {
                const translation = _.find(courseTranslations.translations, [
                    "language_id",
                    Number(tabKey),
                ]);
                if (_.isNil(translation)) {
                    const answerIndex = _.findIndex(
                        defaultTranslation.quiz_options,
                        {
                            answer: true,
                        }
                    );

                    const emptyOption = {
                        option: "",
                        name: "",
                        answer: false,
                    };

                    const emptyQuizOptions = new Array(
                        defaultTranslation.quiz_options.length
                    ).fill(emptyOption);

                    emptyQuizOptions[answerIndex] = {
                        option: "",
                        name: "",
                        answer: true,
                    };

                    quizForm.setFieldsValue({
                        question: "",
                        answer_description: "",
                        quiz_options: emptyQuizOptions,
                    });
                } else {
                    const extendedOptionTranslations = _.map(
                        translation.option_translations,
                        (option) =>
                            option.chapter_quiz_option_id ==
                            courseTranslations.answer_id
                                ? _.extend(option, { answer: true })
                                : _.extend(option, { answer: false })
                    );

                    quizForm.setFieldsValue({
                        question: translation.question,
                        answer_description: translation.answer_description,
                        quiz_options: _.filter(extendedOptionTranslations, {
                            language_id: Number(tabKey),
                        }),
                    });
                }
            } else {
                const answerIndex = _.findIndex(
                    defaultTranslation.quiz_options,
                    {
                        answer: true,
                    }
                );

                const emptyOption = {
                    option: "",
                    name: "",
                    answer: false,
                };

                const emptyQuizOptions = new Array(
                    defaultTranslation.quiz_options.length
                ).fill(emptyOption);

                emptyQuizOptions[answerIndex] = {
                    option: "",
                    name: "",
                    answer: true,
                };

                quizForm.setFieldsValue({
                    question: "",
                    answer_description: "",
                    quiz_options: emptyQuizOptions,
                });
            }
        }
    };

    const handleTranslateQuiz = async (values) => {
        const questionObject = {
            organization_id: organization,
            chapter_quiz_id: quizId,
            language_id: activeTabKey,
            question: values.question,
            answer_description: values.answer_description,
            quiz_option_translations: [],
        };

        for (let [quizIndex, quizOption] of values.quiz_options.entries()) {
            let singleOption = {
                language_id: activeTabKey,
                chapter_quiz_option_id:
                    defaultTranslation.quiz_options[quizIndex]["id"],
                name: quizOption.name,
                option: quizOption.option,
            };

            questionObject.quiz_option_translations.push(singleOption);
        }

        mutate(questionObject);
    };

    // const handleSetAsDefaultChange = (index) => {
    //     let quizOptions = quizForm.getFieldValue("quiz_options");
    //     quizOptions.map((option, optionIndex) => {
    //         if (optionIndex === index) {
    //             option.answer = true;
    //         } else {
    //             option.answer = false;
    //         }
    //         return option;
    //     });
    //     quizForm.setFieldsValue({ quiz_options: quizOptions });
    // };

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isError) {
        return <>Error...</>;
    }

    return (
        <>
            <Divider />
            <Breadcrumb className="mb-6" separator=">">
                <Breadcrumb.Item>
                    <Link to="/courses">Courses</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Translating a question</Breadcrumb.Item>
            </Breadcrumb>
            <Dropdown
                trigger={["click"]}
                placement="bottomLeft"
                overlay={
                    <Menu>
                        {languages.map((language) => (
                            <Menu.Item
                                key={language.id}
                                onClick={() => add(language)}
                            >
                                {language.name}
                            </Menu.Item>
                        ))}
                    </Menu>
                }
            >
                <Button
                    type="text"
                    className="border-none text-brand"
                    icon={<Icon icon="akar-icons:circle-plus-fill" />}
                />
            </Dropdown>
            <Tabs
                hideAdd
                type="editable-card"
                onChange={handleLanguageChange}
                activeKey={activeTabKey}
                onEdit={onEdit}
            >
                {selectedLanguages.map((language) => (
                    <Tabs.TabPane
                        tab={language.name}
                        key={language.id}
                        closable={language.closable}
                    >
                        <Row>
                            {Number(activeTabKey) === 0 ? null : (
                                <Col span={6}>
                                    <div className="flex">
                                        <div className="flex flex-col">
                                            <h2>Defaults</h2>
                                            <Divider className="my-2" />
                                            <h2 className="font-bold">
                                                Question:
                                            </h2>
                                            <h3 className="text-gray-500">
                                                {defaultTranslation?.question}
                                            </h3>
                                            {defaultTranslation?.quiz_options?.map(
                                                (option) => (
                                                    <h3 className="text-gray-500">
                                                        {`${option.name}. ${option.option}`}
                                                    </h3>
                                                )
                                            )}
                                            <h2 className="font-bold">
                                                Answer Description:
                                            </h2>
                                            <h3 className="text-gray-500">
                                                {
                                                    defaultTranslation?.answer_description
                                                }
                                            </h3>
                                        </div>
                                        <Divider
                                            className="h-48"
                                            type="vertical"
                                        />
                                    </div>
                                </Col>
                            )}
                            <Col span={Number(activeTabKey) === 0 ? 24 : 18}>
                                <Form
                                    name="dynamic_form_item"
                                    labelCol={{ span: 6 }}
                                    colon={false}
                                    onFinish={handleTranslateQuiz}
                                    requiredMark={false}
                                    autoComplete="off"
                                    form={quizForm}
                                >
                                    <Form.Item
                                        label={
                                            <span className="text-base text-gray-400 uppercase font-bold">
                                                Question
                                            </span>
                                        }
                                        name="question"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please write a question",
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Add Question"
                                            disabled={
                                                Number(activeTabKey) === 0
                                            }
                                        />
                                    </Form.Item>
                                    <Form.List name="quiz_options">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(
                                                    (
                                                        { key, name, fieldKey },
                                                        index
                                                    ) => (
                                                        <Form.Item
                                                            key={key}
                                                            labelCol={{
                                                                span: 8,
                                                            }}
                                                            label={
                                                                <span className="text-base text-gray-400 uppercase font-bold">
                                                                    Choice{" "}
                                                                    {index + 1}
                                                                </span>
                                                            }
                                                        >
                                                            <Row gutter={16}>
                                                                <Col span={2}>
                                                                    <Form.Item
                                                                        name={[
                                                                            name,
                                                                            "name",
                                                                        ]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "Please write a choice letter",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            disabled={
                                                                                Number(
                                                                                    activeTabKey
                                                                                ) ===
                                                                                0
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={16}>
                                                                    <Form.Item
                                                                        noStyle
                                                                        name={[
                                                                            name,
                                                                            "option",
                                                                        ]}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "Please write a choice",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            placeholder="Add choice for the question"
                                                                            disabled={
                                                                                Number(
                                                                                    activeTabKey
                                                                                ) ===
                                                                                0
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={2}>
                                                                    <Form.Item
                                                                        fieldKey={[
                                                                            fieldKey,
                                                                            "answer",
                                                                        ]}
                                                                        name={[
                                                                            name,
                                                                            "answer",
                                                                        ]}
                                                                        valuePropName="checked"
                                                                    >
                                                                        <Switch
                                                                            // onChange={() =>
                                                                            //     handleSetAsDefaultChange(
                                                                            //         index
                                                                            //     )
                                                                            // }
                                                                            disabled={
                                                                                true
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col
                                                                    span={4}
                                                                ></Col>
                                                            </Row>
                                                        </Form.Item>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </Form.List>
                                    <Form.Item
                                        label={
                                            <span className="text-base text-gray-400 uppercase font-bold">
                                                Answer Description
                                            </span>
                                        }
                                        name="answer_description"
                                    >
                                        <Input.TextArea
                                            disabled={
                                                Number(activeTabKey) === 0
                                            }
                                        />
                                    </Form.Item>
                                    <div className="flex justify-end gap-4">
                                        <Button
                                            type="primary"
                                            className="uppercase"
                                            ghost
                                            onClick={() => navigate(-1)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="primary"
                                            className="uppercase"
                                            htmlType="submit"
                                            loading={isSaving}
                                            disabled={
                                                isSaving ||
                                                Number(activeTabKey) === 0
                                            }
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </>
    );
}

export default TranslateQuestion;
