import { Button } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import Background from "../../assets/images/kids.webp";
import CompassionLogo from "../../assets/logos/compassion.svg";
import GreatCommissionLogo from "../../assets/logos/Great-commission.svg";
import HulentenawiLogo from "../../assets/logos/Hulentenawi-horizontal.svg";
import {
    availableOrganizationsState,
    organizationState,
    permissionsState,
    tokenState,
    userState,
} from "../../store/index";

function PickOrganization() {
    const navigate = useNavigate();
    const [organizations, setAvailableOrganizations] = useRecoilState(
        availableOrganizationsState
    );
    const setCurrentOrganization = useSetRecoilState(organizationState);
    const setPermissions = useSetRecoilState(permissionsState);
    const setToken = useSetRecoilState(tokenState);
    const setUser = useSetRecoilState(userState);

    const handleOrganizationSelect = (selectedOrganization) => {
        setCurrentOrganization(selectedOrganization.organization_id);
        setPermissions(selectedOrganization.role.permissions);
        navigate("/");
    };

    const logout = () => {
        setToken("");
        setUser(null);
        setCurrentOrganization(0);
        setAvailableOrganizations(null);
        setPermissions(null);
        navigate("/login");
    };

    useEffect(() => {
        if (_.isEqual(organizations.length, 1)) {
            handleOrganizationSelect(organizations[0]);
        }
    }, []);

    return (
        <div
            className="grid place-items-center h-screen py-4"
            style={{
                backgroundImage: `linear-gradient(
                                rgba(4, 93, 184, 0.8), 
                                rgba(4, 93, 184, 0.8)
                            ), url(${Background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <div>
                <div className="flex justify-center mb-8">
                    <img
                        src={HulentenawiLogo}
                        alt="Hulentenawi Logo"
                        className="w-80"
                    />
                </div>
                <div className="flex flex-col rounded-2xl">
                    <div className="mb-4 text-white text-center">
                        {_.isEmpty(organizations) ? (
                            <span>
                                You don't have access to the system. <br />{" "}
                                Please contact the system administrator for
                                support.
                            </span>
                        ) : (
                            <span>Please select an organization</span>
                        )}
                    </div>
                    <div className="flex flex-col gap-y-4">
                        {organizations.map((organization) => (
                            <Button
                                key={organization.id}
                                onClick={() =>
                                    handleOrganizationSelect(organization)
                                }
                            >
                                {organization?.organization?.name}
                            </Button>
                        ))}
                    </div>
                </div>
                {_.isEmpty(organizations) ? (
                    <div className="text-center">
                        <h1 className="text-white text-center mb-4">
                            Go back to
                        </h1>
                        <Button
                            className="text-white rounded-sm w-[180px]"
                            ghost
                            onClick={logout}
                        >
                            Login
                        </Button>
                    </div>
                ) : null}
            </div>
            <div className="flex flex-col lg:flex-row items-baseline gap-[57px]">
                <img
                    src={CompassionLogo}
                    alt="Compassion Logo"
                    className="w-56"
                />
                <img
                    src={GreatCommissionLogo}
                    alt="Great Commission Logo"
                    className="w-56"
                />
            </div>
        </div>
    );
}

export default PickOrganization;
