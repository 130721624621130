import { atom } from "recoil";

const changePassword = {
    visible: atom({
        key: "changePasswordVisible",
        default: false,
    }),
};

export default changePassword;
