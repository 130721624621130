import { atom } from "recoil";

const editProfile = {
    visible: atom({
        key: "editProfileVisible",
        default: false,
    }),
    data: atom({
        key: "editProfileData",
        default: null,
    }),
};

export default editProfile;
