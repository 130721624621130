import { atom } from "recoil";

const coursePreview = {
    visible: atom({
        key: "coursePreviewVisible",
        default: false,
    }),
    data: atom({
        key: "coursePreviewData",
        default: null,
    }),
    defaultData: atom({
        key: "coursePreviewDefaultData",
        default: null,
    }),
};

export default coursePreview;
