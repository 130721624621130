import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import api from "../../../../config/api";
import { organizationState } from "../../../../store";
import { successNotification } from "../../../../utils/notifications";
import "./AddAdminModal.less";

function AddAdminModal({ open, closeAction, discussionCommentId }) {
    const [roles, setRoles] = useState([]);

    const organization = useRecoilValue(organizationState);

    const [form] = Form.useForm();
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation(
        (adminInfo) => api.post(`/admin/user`, adminInfo),
        {
            onSuccess: (response, variables, context) => {
                successNotification("Admin added successfully!");
                closeAction();
                queryClient.invalidateQueries("admin-users");
                form.resetFields();
            },
        }
    );

    const handleAddAdmin = async (values) => {
        const { email, role_id, gender } = values;
        const formData = new FormData();
        formData.append("email", email);
        formData.append("gender", gender);
        formData.append("role_id", role_id);
        formData.append("organization_id", organization);
        mutate(formData);
    };

    const fetchRoles = async () => {
        const { data } = await api.get(
            `/admin/roles?organization_id=${organization}`
        );
        return data;
    };

    const { refetch } = useQuery("roles", fetchRoles, {
        enabled: false,
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            setRoles(result);
        },
    });

    useEffect(() => {
        refetch();
    }, []);

    return (
        <Modal
            centered={true}
            title={
                <span className="text-brand font-bold uppercase">
                    Add Admin
                </span>
            }
            visible={open}
            footer={
                <div className="text-right">
                    <Button
                        type="primary"
                        className="rounded"
                        onClick={() => form.submit()}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        Create
                    </Button>
                </div>
            }
            onCancel={() => closeAction(false)}
            maskClosable={false}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={handleAddAdmin}
                requiredMark={false}
                className="px-6"
                autoComplete="off"
            >
                <Form.Item
                    label={
                        <span className="text-base text-gray-500 uppercase font-bold">
                            Email Address
                        </span>
                    }
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Please input an email address",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-500 uppercase font-bold">
                            Gender
                        </span>
                    }
                    name="gender"
                    rules={[
                        {
                            required: true,
                            message: "Please select a gender",
                        },
                    ]}
                >
                    <Select placeholder="Select a gender">
                        <Select.Option value="FEMALE">Female</Select.Option>
                        <Select.Option value="MALE">Male</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-500 uppercase font-bold">
                            Role
                        </span>
                    }
                    name="role_id"
                    rules={[
                        {
                            required: true,
                            message: "Please select a role",
                        },
                    ]}
                >
                    <Select placeholder="Select a role">
                        {roles.map((role) => (
                            <Select.Option key={role.id} value={role.id}>
                                {role.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default AddAdminModal;
