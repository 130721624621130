import { LikeOutlined, UserOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { Avatar, Button, Popconfirm } from "antd";
import { formatDistanceToNow } from "date-fns";
import _ from "lodash";
import React from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import api from "../../../../config/api";
import {
    organizationState,
    permissionsState,
    userState,
} from "../../../../store";
import { hasCreatePermission } from "../../../../utils/checkPermission";
import { imageChecker } from "../../../../utils/imageChecker";
import { getInitals } from "../../../../utils/initials";
import { successNotification } from "../../../../utils/notifications";

function ReplyCard({
    id,
    profile_picture,
    author_name,
    description,
    comments_count,
    likes_count,
    time,
    replyButton,
    openReplyModal,
    type,
    likes,
}) {
    const queryClient = useQueryClient();
    const user = useRecoilValue(userState);
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);

    const handleDelete = async () => {
        if (type === "comment") {
            await api.delete(
                `/admin/discussion_comment?id=${id}&organization_id=${organization}`
            );
            queryClient.invalidateQueries("discussion");
            successNotification("Comment deleted successfully!");
        } else if (type === "reply") {
            await api.delete(
                `/admin/discussion_comment_reply?id=${id}&organization_id=${organization}`
            );
            queryClient.invalidateQueries("discussion");
            successNotification("Reply deleted successfully!");
        }
    };

    const handleLike = async () => {
        if (type === "comment") {
            const ownLike = _.find(likes, ["created_by", user.id]);
            if (ownLike) {
                await api.delete(
                    `/admin/discussion_comment_like?discussion_comment_id=${id}&organization_id=${organization}`
                );
                successNotification("Comment unliked successfully!");
            } else {
                await api.post(`/admin/discussion_comment_like`, {
                    discussion_comment_id: id,
                    organization_id: organization,
                });
                successNotification("Comment liked successfully!");
            }
            queryClient.invalidateQueries("discussion");
        } else if (type === "reply") {
            const ownLike = _.find(likes, ["created_by", user.id]);
            if (ownLike) {
                await api.delete(
                    `/admin/discussion_comment_reply_like?discussion_comment_reply_id=${id}&organization_id=${organization}`
                );
                successNotification("Reply unliked successfully!");
            } else {
                await api.post(
                    `/admin/discussion_comment_reply_like?organization_id=${organization}&discussion_comment_reply_id=${id}`
                );
                successNotification("Reply liked successfully!");
            }
            queryClient.invalidateQueries("discussion");
        }
    };

    return (
        <div className="flex flex-col lg:flex-row gap-y-4 lg:gap-y-0 bg-gray-100 items-center p-6 shadow-lg">
            {profile_picture ? (
                <Avatar
                    size={84}
                    src={
                        imageChecker(profile_picture)
                            ? profile_picture
                            : `${process.env.REACT_APP_IMAGE_API}${profile_picture}`
                    }
                />
            ) : (
                <Avatar size={84} className="bg-brand">
                    {getInitals(author_name)}
                </Avatar>
            )}
            <div className="lg:ml-10 mr-auto text-gray-500 flex-1">
                <div className="flex gap-6 mb-1">
                    <span className="uppercase font-bold text-base">
                        {author_name}
                    </span>
                    <span className="ml-auto">
                        {formatDistanceToNow(new Date(time), {
                            addSuffix: true,
                        })}
                    </span>
                </div>
                <div className="text-xs text-justify">{description}</div>
                <div className="flex items-end justify-end gap-6 mt-3">
                    {type === "reply" ? null : (
                        <div className="flex items-center gap-1">
                            <span>{comments_count}</span>
                            <UserOutlined />
                        </div>
                    )}
                    <div className="flex items-center gap-1">
                        <span>{likes_count}</span>
                        <LikeOutlined
                            className={
                                _.find(likes, ["created_by", user.id])
                                    ? "text-brand"
                                    : ""
                            }
                            onClick={handleLike}
                        />
                    </div>
                    {replyButton &&
                    hasCreatePermission(permissions, "DISCUSSIONS") ? (
                        <Button
                            size="small"
                            onClick={() => openReplyModal(true, id)}
                        >
                            Reply
                        </Button>
                    ) : null}
                    {hasCreatePermission(permissions, "DISCUSSIONS") ? (
                        <Popconfirm
                            title={`Are you sure you want to delete this ${type}?`}
                            okText="Delete"
                            onConfirm={handleDelete}
                            onCancel={(e) => e.preventDefault()}
                        >
                            <Icon
                                icon="fluent:delete-off-20-filled"
                                className="text-red-500 text-2xl cursor-pointer"
                            />
                        </Popconfirm>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default ReplyCard;
