import React from 'react';

export default function BellCardIcon(props) {
  return (
    <svg width={54} height={97} viewBox="0 0 54 97" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g style={{mixBlendMode: 'overlay'}} filter="url(#filter0_d_162_3063)">
        <path fillRule="evenodd" clipRule="evenodd" d="M50 0.275574C46.2218 0.275637 43.1719 3.3154 43.1719 7.08106V10.1662C30.1529 13.2514 22.6874 24.8661 22.6874 38.84V61.5249L13.5831 70.5989V75.1359H50V66.0619H31.7916V38.84C31.7916 27.5883 38.6653 18.4236 50 18.4235V0.275574ZM50 79.6729H40.8959C40.8959 84.6635 44.9927 88.7468 50 88.7468V79.6729ZM23.3702 0.956122L29.8797 7.44402C25.1618 10.7753 21.2585 15.1244 18.4606 20.1675C15.6627 25.2106 14.0422 30.8177 13.7197 36.5715H4.61548C5.25278 22.0531 12.4451 9.25881 23.3702 0.956122Z" fill="#D3D3D3" />
      </g>
      <defs>
        <filter id="filter0_d_162_3063" x="0.615479" y="0.275574" width="53.3845" height="96.4713" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_162_3063" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_162_3063" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
