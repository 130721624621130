import { atom } from "recoil";

const userPreview = {
    visible: atom({
        key: "userPreviewVisible",
        default: false,
    }),
    data: atom({
        key: "userPreviewData",
        default: null,
    }),
};

export default userPreview;
