import { Icon } from "@iconify/react";
import {
    Alert,
    Breadcrumb,
    Button,
    Col,
    Divider,
    Dropdown,
    Form,
    Input,
    Menu,
    Row,
    Tabs,
    Upload,
} from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import api from "../../config/api";
import { organizationState, permissionsState, titleState } from "../../store";
import { hasCreatePermission } from "../../utils/checkPermission";
import {
    errorNotification,
    successNotification,
} from "../../utils/notifications";

const { TextArea } = Input;

function TranslateCourse() {
    const setTitle = useSetRecoilState(titleState);
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const [translationForm] = Form.useForm();
    const navigate = useNavigate();
    const { id } = useParams();
    const [languages, setLanguages] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([
        { id: 0, name: "Default", closable: false },
    ]);
    const [activeTabKey, setActiveTabKey] = useState("0");
    const [defaultTranslation, setDefaultTranslation] = useState(null);
    const [courseTranslations, setCourseTranslations] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [isImageFile, setImageFile] = useState(false);
    const [thumbnailPreview, setThumbnailPreview] = useState("");
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!hasCreatePermission(permissions, "COURSE_TRANSLATIONS")) {
            navigate("/");
        }
    }, [permissions, navigate]);

    useEffect(() => {
        setTitle("Translate Course");
    }, [setTitle]);

    const fetchCourse = async () => {
        const { data } = await api.get(
            `/admin/course?id=${id}&organization_id=${organization}&:translations->language&:chapters`
        );
        return data;
    };

    const fetchLanguages = async () => {
        const { data } = await api.get(`/admin/languages`);
        return data;
    };

    const { mutate, isLoading: isSaving } = useMutation(
        (translatedCourse) =>
            api.post(`/admin/course_translation`, translatedCourse),
        {
            onSuccess: (response, variables, context) => {
                successNotification("Course translated successfully");
                queryClient.invalidateQueries("course");
                // navigate(`/courses`);
            },
        }
    );

    const { isLoading, isError } = useQuery("course", fetchCourse, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;

            setDefaultTranslation({
                title: result.title,
                summary: result.summary,
                image_name: result.image_name,
            });

            // setThumbnailPreview(result.image_name);

            if (!_.isEmpty(result.translations)) {
                let langs = [];
                for (let translation of result.translations) {
                    langs.push({ ...translation["language"], closable: true });
                }

                setSelectedLanguages((prev) => {
                    const displayLangs = [...prev];

                    for (const language of langs) {
                        const alreadyInitialized = _.find(prev, [
                            "id",
                            language.id,
                        ]);

                        if (_.isNil(alreadyInitialized)) {
                            displayLangs.push(language);
                        }
                    }

                    return displayLangs;
                });
            }

            setCourseTranslations(result);

            if (Number(activeTabKey) === 0) {
                translationForm.setFieldsValue({
                    title: result.title,
                    summary: result.summary,
                });

                setThumbnailPreview(result.image_name);
            }
        },
    });

    useQuery("languages", fetchLanguages, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            setLanguages(result);
        },
    });

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const isPicture = ["image/png", "image/jpeg", "image/jpg"].includes(
                file.type
            );

            if (!isPicture) {
                errorNotification(
                    `${file.name} is not an image. Please select an image.`
                );
                setImageFile(false);
            } else {
                setImageFile(true);
            }

            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    const handleLanguageChange = (tabKey) => {
        setActiveTabKey(tabKey);

        if (Number(tabKey) === 0 && defaultTranslation) {
            translationForm.setFieldsValue({
                title: defaultTranslation.title,
                summary: defaultTranslation.summary,
            });

            setThumbnailPreview(defaultTranslation.image_name);
        } else {
            if (!_.isEmpty(courseTranslations.translations)) {
                const translation = _.find(courseTranslations.translations, [
                    "language_id",
                    Number(tabKey),
                ]);
                if (_.isNil(translation)) {
                    translationForm.setFieldsValue({
                        title: "",
                        summary: "",
                    });
                    setThumbnailPreview("");
                } else {
                    translationForm.setFieldsValue({
                        title: translation.title,
                        summary: translation.summary,
                    });
                    setThumbnailPreview(translation.image_name);
                }
            } else {
                translationForm.setFieldsValue({
                    title: "",
                    summary: "",
                });
                setThumbnailPreview("");
            }
        }
    };

    const deleteCourseTranslation = async (translationId) => {
        await api.delete(
            `/admin/course_translation?id=${translationId}&organization_id=${organization}&course_id=${id}`
        );
        successNotification("Translation deleted successfully");
        queryClient.invalidateQueries("course");
    };

    const onEdit = (tabKey, action) => {
        const listOfTabKeys = [];
        selectedLanguages.map((selectedLanguage) =>
            listOfTabKeys.push(selectedLanguage.id)
        );

        if (action === "remove") {
            // check if from api, fire delete request if so
            if (
                !_.isEmpty(courseTranslations.translations) &&
                !_.isNil(
                    _.find(courseTranslations.translations, [
                        "language_id",
                        Number(tabKey),
                    ])
                )
            ) {
                const translation = _.find(courseTranslations.translations, [
                    "language_id",
                    Number(tabKey),
                ]);
                deleteCourseTranslation(translation.id);
            }

            remove(tabKey);

            const availableTabs = listOfTabKeys.filter((key) => key != tabKey);

            setActiveTabKey(String(availableTabs[availableTabs.length - 1]));
        }
    };

    const add = (language) => {
        setSelectedLanguages((prev) => {
            const alreadySelected = _.find(prev, ["id", language.id]);
            if (_.isNil(alreadySelected)) {
                return [
                    ...prev,
                    {
                        id: language.id,
                        name: language.name,
                        closable: true,
                    },
                ];
            } else {
                return [...prev];
            }
        });
    };

    const remove = (tabKey) => {
        setSelectedLanguages((prev) => {
            const idx = prev.findIndex((item) => +item.id === +tabKey);
            prev.splice(idx, 1);
            return [...prev];
        });
    };

    const handleTranslateCourse = (values) => {
        const { image, title, summary } = values;

        const formData = new FormData();
        formData.append("course_id", id);
        formData.append("language_id", activeTabKey);
        formData.append("organization_id", organization);
        formData.append("title", title);
        formData.append("summary", summary);
        if (fileList.length > 0 && isImageFile) {
            formData.append("image", image[0]["originFileObj"]);
        } else if (fileList.length > 0 && !isImageFile) {
            errorNotification(
                `${fileList[0].name} is not an image. Please select an image.`
            );
            return;
        }

        mutate(formData);
    };

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isError) {
        return <>Error...</>;
    }

    return (
        <>
            <Breadcrumb className="mb-6" separator=">">
                <Breadcrumb.Item>
                    <Link to="/courses">Courses</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Translating a course</Breadcrumb.Item>
            </Breadcrumb>
            <Dropdown
                trigger={["click"]}
                placement="bottomLeft"
                overlay={
                    <Menu>
                        {languages.map((language) => (
                            <Menu.Item
                                key={language.id}
                                onClick={() => add(language)}
                            >
                                {language.name}
                            </Menu.Item>
                        ))}
                    </Menu>
                }
            >
                <Button
                    type="text"
                    className="border-none text-brand"
                    icon={<Icon icon="akar-icons:circle-plus-fill" />}
                />
            </Dropdown>
            <Tabs
                hideAdd
                type="editable-card"
                onChange={handleLanguageChange}
                activeKey={activeTabKey}
                onEdit={onEdit}
            >
                {selectedLanguages.map((language) => (
                    <Tabs.TabPane
                        tab={language.name}
                        key={language.id}
                        closable={language.closable}
                    >
                        <Row>
                            {Number(activeTabKey) === 0 ? null : (
                                <Col span={6}>
                                    <div className="flex">
                                        <div className="flex flex-col">
                                            <h2>Defaults</h2>
                                            <Divider className="my-2" />
                                            <h2 className="font-bold">
                                                Topic:
                                            </h2>
                                            <h3 className="text-gray-500">
                                                {defaultTranslation?.title}
                                            </h3>
                                            <h2 className="font-bold">
                                                Description:
                                            </h2>
                                            <h3 className="text-gray-500">
                                                {defaultTranslation?.summary}
                                            </h3>
                                            <h2 className="font-bold">
                                                Thumbnail:
                                            </h2>
                                            {Boolean(
                                                defaultTranslation?.image_name
                                            ) ? (
                                                <img
                                                    src={`${process.env.REACT_APP_IMAGE_API}${defaultTranslation?.image_name}`}
                                                    alt=""
                                                    className="h-[109px] w-[109px] object-cover object-center rounded mb-4"
                                                />
                                            ) : null}
                                        </div>
                                        <Divider
                                            className="h-48"
                                            type="vertical"
                                        />
                                    </div>
                                </Col>
                            )}
                            <Col span={Number(activeTabKey) === 0 ? 24 : 18}>
                                <Form
                                    colon={false}
                                    labelCol={{ span: 4 }}
                                    // wrapperCol={{ span: 18 }}
                                    onFinish={handleTranslateCourse}
                                    requiredMark={false}
                                    autoComplete="off"
                                    form={translationForm}
                                >
                                    <Form.Item
                                        label={
                                            <span className="text-base text-gray-400 uppercase font-bold">
                                                Topic
                                            </span>
                                        }
                                        name="title"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please write a course title",
                                            },
                                        ]}
                                    >
                                        <Input
                                            showCount
                                            maxLength={40}
                                            placeholder="Simple and short title"
                                            disabled={
                                                Number(activeTabKey) === 0
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <span className="text-base text-gray-400 uppercase font-bold">
                                                Description
                                            </span>
                                        }
                                        name="summary"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please write a course description",
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            rows={4}
                                            showCount
                                            maxLength={100}
                                            placeholder="Any short discription about the course"
                                            disabled={
                                                Number(activeTabKey) === 0
                                            }
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={
                                            <span className="text-base text-gray-400 uppercase font-bold">
                                                Thumbnail
                                            </span>
                                        }
                                    >
                                        {Boolean(thumbnailPreview) && (
                                            <img
                                                src={`${process.env.REACT_APP_IMAGE_API}${thumbnailPreview}`}
                                                alt=""
                                                className="h-[109px] w-[109px] object-cover object-center rounded mb-4"
                                            />
                                        )}
                                        {Number(activeTabKey) === 0 ? null : (
                                            <Form.Item
                                                noStyle
                                                name="image"
                                                valuePropName="fileList"
                                                getValueFromEvent={(e) => {
                                                    if (Array.isArray(e)) {
                                                        return e;
                                                    }
                                                    return e && e.fileList;
                                                }}
                                            >
                                                <Upload
                                                    {...uploadProps}
                                                    accept="image/*"
                                                    maxCount={1}
                                                >
                                                    <Button>
                                                        Click to upload
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                        )}
                                    </Form.Item>
                                    <Row>
                                        <Col span={6}></Col>
                                        <Col
                                            span={18}
                                            className="flex justify-end gap-4"
                                        >
                                            <Button
                                                onClick={() => navigate(-1)}
                                                type="primary"
                                                className="uppercase"
                                                ghost
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="primary"
                                                className="uppercase"
                                                htmlType="submit"
                                                loading={isSaving}
                                                disabled={
                                                    isSaving ||
                                                    Number(activeTabKey) === 0
                                                }
                                            >
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                ))}
            </Tabs>
            <div className="mt-8">
                <span className="text-base text-gray-500 uppercase font-bold">
                    Chapters
                </span>
                {courseTranslations?.chapters?.length > 0 ? (
                    courseTranslations?.chapters?.map((chapter, index) => (
                        <div
                            key={chapter.id}
                            className="flex gap-4 items-center mb-6"
                        >
                            <Button
                                type="primary"
                                size="large"
                                className="rounded"
                                onClick={() =>
                                    navigate(
                                        `/courses/${id}/chapters/${chapter.id}`
                                    )
                                }
                            >
                                Chapter {index + 1}
                            </Button>
                            <div>{chapter.title}</div>
                            {Number(activeTabKey) === 0 ? null : (
                                <Button
                                    className="py-1 px-2"
                                    onClick={() =>
                                        navigate(
                                            `/courses/${id}/chapters/${chapter.id}/translate`
                                        )
                                    }
                                >
                                    Translate
                                </Button>
                            )}
                        </div>
                    ))
                ) : (
                    <Alert
                        className="mb-6"
                        message="No chapters added to this course yet."
                        type="info"
                        showIcon
                    />
                )}
            </div>
        </>
    );
}

export default TranslateCourse;
