import { MoreOutlined } from "@ant-design/icons";
import { Card, Dropdown, Menu, Popconfirm } from "antd";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import api from "../../../../config/api";
import {
    coursePreviewState,
    organizationState,
    permissionsState,
} from "../../../../store";
import {
    hasCreatePermission,
    hasDeletePermission,
    hasUpdatePermission,
} from "../../../../utils/checkPermission";
import { successNotification } from "../../../../utils/notifications";

function CourseCard({
    id,
    title,
    summary,
    cover_image,
    published,
    author,
    date,
    created_by,
    category,
    translations,
    languages,
}) {
    const navigate = useNavigate();
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const setCoursePreviewVisibility = useSetRecoilState(
        coursePreviewState.visible
    );
    const setCoursePreviewData = useSetRecoilState(coursePreviewState.data);
    const setCoursePreviewDefaultData = useSetRecoilState(
        coursePreviewState.defaultData
    );
    const queryClient = useQueryClient();

    const { mutate } = useMutation(
        (courseId) =>
            api.delete(
                `/admin/course?id=${courseId}&organization_id=${organization}`
            ),
        {
            onSuccess: (data, variables, context) => {
                successNotification("Course deleted successfully");
                queryClient.invalidateQueries("courses");
            },
        }
    );

    const openCoursePreview = () => {
        setCoursePreviewVisibility(true);
        setCoursePreviewData({
            id,
            title,
            summary,
            cover_image,
            published,
            author,
            date,
            created_by,
            category,
            translations,
            languages,
        });

        setCoursePreviewDefaultData({
            id,
            title,
            summary,
            cover_image,
            published,
            author,
            date,
            created_by,
            category,
            translations,
            languages,
        });
    };

    const publishCourse = async () => {
        await api.patch("/admin/course", {
            id,
            organization_id: organization,
            is_published: 1,
        });
        queryClient.invalidateQueries("courses");
        successNotification("Course published successfully!");
    };

    const unpublishCourse = async () => {
        await api.patch("/admin/course", {
            id,
            organization_id: organization,
            is_published: 0,
        });
        queryClient.invalidateQueries("courses");
        successNotification("Course unpublished successfully!");
    };

    const menu = (
        <Menu
            className="text-right"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            {hasUpdatePermission(permissions, "COURSES") ? (
                <Menu.Item
                    key="0"
                    className="text-brand uppercase"
                    onClick={() => navigate(`/courses/${id}/edit`)}
                >
                    Edit
                </Menu.Item>
            ) : null}
            {published ? (
                <Menu.Item
                    key="1"
                    className="text-brand uppercase"
                    onClick={unpublishCourse}
                >
                    Unpublish
                </Menu.Item>
            ) : (
                <Menu.Item
                    key="1"
                    className="text-brand uppercase"
                    onClick={publishCourse}
                >
                    Publish
                </Menu.Item>
            )}
            {hasCreatePermission(permissions, "COURSE_TRANSLATIONS") ? (
                <Menu.Item
                    key="2"
                    className="text-brand uppercase"
                    onClick={() => navigate(`/courses/${id}/translate`)}
                >
                    Translate
                </Menu.Item>
            ) : null}
            {hasDeletePermission(permissions, "COURSES") ? (
                <Menu.Item key="3" className="text-brand uppercase">
                    <Popconfirm
                        title="Are you sure you want to delete this course?"
                        okText="Delete"
                        onConfirm={() => mutate(id)}
                        onCancel={(e) => e.preventDefault()}
                    >
                        Delete
                    </Popconfirm>
                </Menu.Item>
            ) : null}
        </Menu>
    );

    return (
        <Card
            className="shadow-lg relative cursor-pointer"
            cover={
                <LazyLoadImage
                    className="h-40 object-cover object-center"
                    alt="Course cover"
                    src={`${process.env.REACT_APP_IMAGE_API}${cover_image}`}
                />
            }
            onClick={openCoursePreview}
        >
            {hasUpdatePermission(permissions, "COURSES") ||
            hasCreatePermission(permissions, "COURSE_TRANSLATIONS") ||
            hasDeletePermission(permissions, "COURSES") ? (
                <Dropdown
                    placement="bottomRight"
                    trigger={["click"]}
                    overlay={menu}
                    className="absolute top-2 right-2 bg-white rounded-full grid place-items-center p-1"
                >
                    <span onClick={(e) => e.stopPropagation()}>
                        <MoreOutlined className="text-brand font-bold" />
                    </span>
                </Dropdown>
            ) : null}
            <h1 className="text-base text-brand font-bold truncate">{title}</h1>
            <p className="text-xs text-brand truncate">{summary}</p>
            <div className="flex justify-end gap-2 mr-10">
                {languages?.map((language) => (
                    <span
                        key={language.id}
                        className="border border-gray-300 text-gray-400 px-2 rounded"
                    >
                        {language?.name}
                    </span>
                ))}
            </div>
            {published ? null : (
                <div className="absolute bottom-6 right-0 bg-red-500 text-white px-3">
                    Draft
                </div>
            )}
        </Card>
    );
}

export default CourseCard;
