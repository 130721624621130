import { ControlOutlined, SearchOutlined } from "@ant-design/icons";
import { Checkbox, Divider, Input, Popover, Select } from "antd";
import Fuse from "fuse.js";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FloatingButton } from "../../components/UI/Buttons";
import { CourseCard } from "../../components/UI/Cards";
import { CoursePreview } from "../../components/UI/Modals";
import api from "../../config/api";
import {
    coursePreviewState,
    mobileNavState,
    organizationState,
    permissionsState,
    titleState,
    userState,
} from "../../store";
import { hasCreatePermission } from "../../utils/checkPermission";
import "./Courses.less";

function Courses() {
    const [searchbarVisible, setSearchbarVisible] = useState(false);
    const [courses, setCourses] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [categories, setCategories] = useState([
        { label: "Published", value: -1, type: "visibility" },
        { label: "Draft", value: -2, type: "visibility" },
    ]);
    const [sort, setSort] = useState("desc");
    const setTitle = useSetRecoilState(titleState);
    const setMobileNav = useSetRecoilState(mobileNavState);
    const setCoursePreview = useSetRecoilState(coursePreviewState.visible);
    const organization = useRecoilValue(organizationState);
    const user = useRecoilValue(userState);
    const permissions = useRecoilValue(permissionsState);
    const navigate = useNavigate();

    useEffect(() => {
        setTitle("Courses");
    }, [setTitle]);

    useEffect(() => {
        setMobileNav(false);
    }, [setMobileNav]);

    useEffect(() => {
        setCoursePreview(false);
    }, [setCoursePreview]);

    const fetchCourses = async () => {
        const { data } = await api.get(
            `/admin/courses?organization_id=${organization}&|id=desc&:created_by&:course_category&:translations->language&:languages`
        );
        return data;
    };

    const { isLoading, isError } = useQuery(
        ["courses", organization],
        () => fetchCourses(),
        {
            cacheTime: 0,
            onSuccess: (response) => {
                const { result } = response;
                setCourses(result);
                // setFilteredCourses(result);

                applySort(sort, result);
            },
        }
    );

    const fetchCategories = async () => {
        const { data } = await api.get(
            `/admin/course_categories?organization_id=${organization}`
        );
        return data;
    };

    useQuery(["categories", organization], fetchCategories, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            let categoryFilters = [];
            for (let categoryResult of result) {
                categoryFilters.push({
                    label: categoryResult.name,
                    value: categoryResult.id,
                    type: "category",
                });
            }
            setCategories((prev) => {
                const mixedCategories = [...prev];

                for (const categoryFilter of categoryFilters) {
                    if (
                        _.isNil(
                            _.find(mixedCategories, [
                                "label",
                                categoryFilter.label,
                            ])
                        )
                    ) {
                        mixedCategories.push(categoryFilter);
                    }
                }

                return mixedCategories;
            });
        },
    });

    const handleCategoryFilter = (selectedCategories) => {
        let filterAppliedCourses = [];
        const appliedFilterObj = {
            is_published: [],
            course_category_id: [],
        };

        if (!_.isEmpty(selectedCategories)) {
            for (const applyFilter of selectedCategories) {
                if (applyFilter === -1 || applyFilter === -2) {
                    const isPublished = applyFilter === -1 ? 1 : 0;

                    appliedFilterObj["is_published"].push(isPublished);
                } else {
                    appliedFilterObj["course_category_id"].push(applyFilter);
                }
            }

            if (
                !_.isEmpty(appliedFilterObj["is_published"]) &&
                !_.isEmpty(appliedFilterObj["course_category_id"])
            ) {
                filterAppliedCourses = courses.filter(
                    (course) =>
                        appliedFilterObj["course_category_id"].includes(
                            course["course_category_id"]
                        ) &&
                        appliedFilterObj["is_published"].includes(
                            course["is_published"]
                        )
                );
            } else if (
                _.isEmpty(appliedFilterObj["is_published"]) &&
                !_.isEmpty(appliedFilterObj["course_category_id"])
            ) {
                filterAppliedCourses = courses.filter((course) =>
                    appliedFilterObj["course_category_id"].includes(
                        course["course_category_id"]
                    )
                );
            } else if (
                !_.isEmpty(appliedFilterObj["is_published"]) &&
                _.isEmpty(appliedFilterObj["course_category_id"])
            ) {
                filterAppliedCourses = courses.filter((course) =>
                    appliedFilterObj["is_published"].includes(
                        course["is_published"]
                    )
                );
            }

            setFilteredCourses(filterAppliedCourses);
        } else {
            setFilteredCourses(courses);
        }
    };

    const handleStatusFilter = (selectedCategories) => {
        if (!_.isEmpty(selectedCategories)) {
            setFilteredCourses(
                _.filter(courses, (course) =>
                    _.some(selectedCategories, (selectedCategory) =>
                        _.isEqual(course["is_published"], selectedCategory)
                    )
                )
            );
        } else {
            setFilteredCourses(courses);
        }
    };

    const toggleSearchbar = () => {
        setSearchbarVisible(!searchbarVisible);
    };

    const onSearch = (event) => {
        const courseIndex = Fuse.createIndex(["title"], courses);
        const fuse = new Fuse(courses, { keys: ["title"] }, courseIndex);
        let foundCourses = [];
        if (!_.isEmpty(event.target.value)) {
            const searchResult = fuse.search(event.target.value);
            for (let result of searchResult) {
                foundCourses.push(result.item);
            }
            setFilteredCourses(foundCourses);
        } else {
            setFilteredCourses(courses);
        }
    };

    const handleSort = (value) => {
        setSort(value);

        applySort(value, filteredCourses);
    };

    const applySort = (value, coursesToSort) => {
        if (_.isEqual(value, "desc")) {
            setFilteredCourses(_.orderBy(coursesToSort, ["id"], ["desc"]));
        } else if (_.isEqual(value, "title")) {
            setFilteredCourses(
                _.orderBy(
                    coursesToSort,
                    [(course) => course.title.toLowerCase()],
                    ["asc"]
                )
            );
        }
    };

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isError) {
        return <>Error... Please refresh the page.</>;
    }

    return (
        <>
            <div className="flex justify-end items-center gap-2 text-lg text-brand mb-4">
                <SearchOutlined onClick={toggleSearchbar} />
                {searchbarVisible ? (
                    <Input className="w-60" onKeyUp={onSearch} />
                ) : null}
                <Popover
                    trigger={["click"]}
                    placement="bottomRight"
                    content={
                        <div>
                            {/* <Checkbox.Group
                                options={[
                                    { label: "Published", value: 1 },
                                    { label: "Draft", value: 0 },
                                ]}
                                onChange={handleStatusFilter}
                            />
                            <Divider className="my-2" /> */}
                            <Checkbox.Group
                                options={categories}
                                onChange={handleCategoryFilter}
                            />
                        </div>
                    }
                >
                    <ControlOutlined />
                </Popover>
                <span className="text-sm">Sort By:</span>
                <Select
                    defaultValue={sort}
                    className="w-36"
                    onChange={handleSort}
                >
                    <Select.Option value="desc">Recent</Select.Option>
                    <Select.Option value="title">Title</Select.Option>
                </Select>
            </div>
            <Divider orientation="left" orientationMargin="0" className="mt-0">
                My Courses
            </Divider>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 lg:gap-14">
                {_.filter(filteredCourses, ["created_by.id", user.id]).map(
                    (course) => (
                        <CourseCard
                            key={course.id}
                            id={course.id}
                            title={course.title}
                            summary={course.summary}
                            cover_image={course.image_name}
                            published={Boolean(course.is_published)}
                            author={course.author}
                            created_by={course.created_by.full_name}
                            date={course.created_at}
                            category={course?.course_category?.name}
                            translations={course?.translations}
                            languages={course?.languages}
                        />
                    )
                )}
            </div>
            <Divider orientation="left" orientationMargin="0">
                Others Course
            </Divider>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 lg:gap-14">
                {_.filter(
                    filteredCourses,
                    (singleCourse) =>
                        !_.isEqual(singleCourse.created_by.id, user.id)
                ).map((course) => (
                    <CourseCard
                        key={course.id}
                        id={course.id}
                        title={course.title}
                        summary={course.summary}
                        cover_image={course.image_name}
                        published={Boolean(course.is_published)}
                        author={course.author}
                        created_by={course.created_by.full_name}
                        date={course.created_at}
                        category={course?.course_category?.name}
                        translations={course?.translations}
                        languages={course?.languages}
                    />
                ))}
            </div>
            {hasCreatePermission(permissions, "COURSES") ? (
                <FloatingButton
                    text="Add Course"
                    action={() => navigate("/courses/new")}
                />
            ) : null}
            <CoursePreview />
        </>
    );
}

export default Courses;
