import React, { useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useRecoilValue } from "recoil";
import { useQueryClient } from "react-query";

import api from "../../../../config/api";
import { organizationState } from "../../../../store";
import { successNotification } from "../../../../utils/notifications";

import "./CustomModal.less";

const { TextArea } = Input;

function CustomModal({ open, closeAction }) {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const organization = useRecoilValue(organizationState);
    const queryClient = useQueryClient();

    const handleSubmit = async (values) => {
        setLoading(true);
        const { data } = await api.post(`/admin/discussion`, {
            ...values,
            organization_id: organization,
        });
        form.resetFields();
        closeAction(false);
        queryClient.invalidateQueries("discussions");
        successNotification("New discussion created successfully!");
        setLoading(false);
    };

    return (
        <Modal
            centered={true}
            title={
                <span className="text-brand font-bold uppercase">
                    Add Discussion
                </span>
            }
            visible={open}
            footer={
                <div className="text-center">
                    <Button
                        type="primary"
                        className="rounded"
                        onClick={() => form.submit()}
                        loading={loading}
                    >
                        Submit
                    </Button>
                </div>
            }
            onCancel={() => closeAction(false)}
            maskClosable={false}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={handleSubmit}
                requiredMark={false}
                className="px-6"
            >
                <Form.Item
                    label={
                        <span className="text-base text-gray-500 uppercase font-bold">
                            Topic
                        </span>
                    }
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Please input the topic of discussion",
                        },
                    ]}
                >
                    <Input placeholder="Simple and short title" />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-500 uppercase font-bold">
                            Description
                        </span>
                    }
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: "Please input the description",
                        },
                    ]}
                >
                    <TextArea
                        rows={6}
                        placeholder="Any description to discuss"
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CustomModal;
