import React from 'react';

export default function UserCardIcon(props) {
  return (
    <svg width={44} height={98} viewBox="0 0 44 98" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g style={{mixBlendMode: 'overlay'}} filter="url(#filter0_d_162_3035)">
        <path fillRule="evenodd" clipRule="evenodd" d="M40.0001 0.734257C38.531 0.431404 37.0248 0.275574 35.5025 0.275574C31.0842 0.275574 26.7652 1.58961 23.0915 4.05151C19.4178 6.51341 16.5545 10.0126 14.8637 14.1066C13.1729 18.2006 12.7305 22.7055 13.5925 27.0516C14.4544 31.3978 16.5821 35.39 19.7063 38.5234C22.8305 41.6568 26.8109 43.7907 31.1443 44.6552C34.0825 45.2413 37.0934 45.2261 40.0001 44.6269V38.0354C39.5451 38.1694 39.0832 38.2834 38.6155 38.3767C35.5202 38.9942 32.3119 38.6773 29.3962 37.466C26.4805 36.2548 23.9884 34.2035 22.2351 31.5718C20.4817 28.94 19.5459 25.8458 19.5459 22.6806C19.5459 18.4362 21.227 14.3656 24.2195 11.3644C27.2119 8.36311 31.2706 6.67702 35.5025 6.67702C37.0332 6.67702 38.5472 6.89782 40.0001 7.32588V0.734257ZM40.0001 51.8495H26.0703C20.2334 51.8495 14.6355 54.1877 10.5081 58.3498C6.38074 62.512 4.06201 68.157 4.06201 74.0432V89.8958H10.3501V74.0432C10.3501 69.8388 12.0063 65.8066 14.9545 62.8336C17.9026 59.8607 21.9011 58.1905 26.0703 58.1905H40.0001V51.8495Z" fill="#B9B9B9" />
      </g>
      <defs>
        <filter id="filter0_d_162_3035" x="0.0620117" y="0.275574" width="43.938" height="97.6202" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_162_3035" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_162_3035" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
