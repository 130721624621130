import { Avatar, Button, Form, Input, Modal, Upload } from "antd";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import api from "../../../../config/api";
import {
    editProfileState,
    organizationState,
    userState,
} from "../../../../store";
import { imageChecker } from "../../../../utils/imageChecker";
import { getInitals } from "../../../../utils/initials";
import {
    errorNotification,
    successNotification,
} from "../../../../utils/notifications";
import "./EditProfile.less";

const { TextArea } = Input;

function EditProfile() {
    const [fileList, setFileList] = useState([]);
    const [isImageFile, setImageFile] = useState(false);
    const [form] = Form.useForm();
    const organization = useRecoilValue(organizationState);
    const [user, setUser] = useRecoilState(userState);
    const [open, setOpen] = useRecoilState(editProfileState.visible);
    const [profile, setProfile] = useRecoilState(editProfileState.data);
    const queryClient = useQueryClient();

    const { mutate, isLoading, isError, error } = useMutation(
        (editedProfile) => api.post("/admin/user_update", editedProfile),
        {
            onSuccess: (response, variables, context) => {
                const { data } = response;
                const { result } = data;
                setUser(result);
                successNotification("Profile updated successfully");
                setOpen(false);
            },
        }
    );

    const handleSubmit = async (values) => {
        const { full_name, image } = values;
        const formData = new FormData();
        formData.append("id", user.id);
        formData.append("organization_id", organization);
        formData.append("full_name", full_name);
        if (fileList.length > 0 && isImageFile) {
            formData.append("image", image[0]["originFileObj"]);
        }
        mutate(formData);
        queryClient.invalidateQueries("admin-users");
    };

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const isPicture = ["image/png", "image/jpeg", "image/jpg"].includes(
                file.type
            );

            if (!isPicture) {
                errorNotification(
                    `${file.name} is not an image. Please select an image.`
                );
                setImageFile(false);
            } else {
                setImageFile(true);
            }

            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    return (
        <Modal
            centered={true}
            title={
                <span className="text-brand font-bold uppercase">
                    Edit Profile
                </span>
            }
            visible={open}
            footer={
                <div className="text-center">
                    <Button
                        type="primary"
                        className="rounded"
                        onClick={() => form.submit()}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        Submit
                    </Button>
                </div>
            }
            onCancel={() => setOpen(false)}
            maskClosable={false}
        >
            <Form
                className="px-6"
                layout="vertical"
                form={form}
                onFinish={handleSubmit}
                requiredMark={false}
                autoComplete="off"
            >
                <Form.Item>
                    {user?.image_name ? (
                        <img
                            src={
                                imageChecker(user?.image_name)
                                    ? user?.image_name
                                    : `${process.env.REACT_APP_IMAGE_API}${user?.image_name}`
                            }
                            alt=""
                            className="h-[109px] w-[109px] object-cover object-center rounded-full mb-4"
                        />
                    ) : (
                        <Avatar
                            size="large"
                            className="bg-brand block mb-4"
                            size={109}
                        >
                            {getInitals(profile?.full_name)}
                        </Avatar>
                    )}
                    <Form.Item
                        noStyle
                        name="image"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e && e.fileList;
                        }}
                    >
                        <Upload {...uploadProps} accept="image/*" maxCount={1}>
                            <Button>Change profile picture</Button>
                        </Upload>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-500 uppercase font-bold">
                            Name
                        </span>
                    }
                    name="full_name"
                    rules={[
                        {
                            required: true,
                            message: "Please input the topic of discussion",
                        },
                    ]}
                    initialValue={profile?.full_name}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default EditProfile;
