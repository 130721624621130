import { Button } from "antd";
import React from "react";
import { PlusOutlined } from "@ant-design/icons";

function CustomButton({ text, action }) {
    return (
        <Button
            type="primary"
            className="flex items-center uppercase font-bold"
            shape="round"
            icon={<PlusOutlined className="text-white font-bold" />}
            onClick={action}
        >
            {text}
        </Button>
    );
}

export default CustomButton;
