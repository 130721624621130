import { Button, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

import notFound from "../../assets/svg/not-found.svg";

function NotFound() {
    const navigate = useNavigate();

    return (
        <div className="min-h-screen grid place-items-center">
            <Result
                icon={<img src={notFound} className="h-40" alt="404" />}
                title="404"
                subTitle="Page not found."
                extra={
                    <Button type="primary" onClick={() => navigate("/")}>
                        Go Back Home
                    </Button>
                }
            />
        </div>
    );
}

export default NotFound;
