import React from 'react';

export default function BubbleCardIcon(props) {
  return (
    <svg width={55} height={98} viewBox="0 0 55 98" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g style={{mixBlendMode: 'overlay'}} filter="url(#filter0_d_162_3050)">
        <path fillRule="evenodd" clipRule="evenodd" d="M50.3557 0.275574H16.1401C13.1779 0.275574 10.337 1.6526 8.24248 4.10372C6.14791 6.55483 4.97119 9.87926 4.97119 13.3457V54.4231C4.97119 61.6378 9.97484 67.4932 16.1401 67.4932H17.7356V79.0173C17.7363 81.169 18.282 83.2721 19.3038 85.0609C20.3256 86.8497 21.7777 88.2439 23.4764 89.0672C25.1752 89.8905 27.0443 90.106 28.8477 89.6864C30.6511 89.2668 32.3077 88.2311 33.6082 86.71L50.036 67.4932H50.3557V56.2903H48.0511C46.782 56.2916 45.5654 56.8826 44.6685 57.9334L27.3089 78.248V61.8917C27.3089 60.4061 26.8046 58.9814 25.9069 57.9309C25.0093 56.8804 23.7918 56.2903 22.5223 56.2903H16.1401C15.7169 56.2903 15.3111 56.0936 15.0118 55.7434C14.7126 55.3932 14.5445 54.9183 14.5445 54.4231V13.3457C14.5445 12.8505 14.7126 12.3756 15.0118 12.0254C15.3111 11.6752 15.7169 11.4785 16.1401 11.4785H50.3557V0.275574Z" fill="#EFEDED" />
      </g>
      <defs>
        <filter id="filter0_d_162_3050" x="0.971191" y="0.275574" width="53.3845" height="97.6197" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_162_3050" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_162_3050" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
