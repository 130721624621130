import { DeleteFilled, FileTextFilled } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { Alert, Breadcrumb, Button, Popconfirm, Radio, Select } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactAudio from "react-audio-player";
import ReactPlayer from "react-player/youtube";
import { useQuery, useQueryClient } from "react-query";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CustomButton } from "../../components/UI/Buttons";
import api from "../../config/api";
import {
    coursePreviewState,
    organizationState,
    permissionsState,
    titleState,
} from "../../store";
import {
    hasCreatePermission,
    hasDeletePermission,
    hasUpdatePermission,
} from "../../utils/checkPermission";
import { successNotification } from "../../utils/notifications";

function SingleChapter() {
    const setTitle = useSetRecoilState(titleState);
    const setCoursePreview = useSetRecoilState(coursePreviewState.visible);
    const { id, chapterId } = useParams();
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [languagePreference, setLanguagePreference] = useState(0);

    const fetchChapter = async () => {
        const { data } = await api.get(
            `/admin/chapter?id=${chapterId}&:paragraphs&:quizzes->options->translations&:quizzes->translations&organization_id=${organization}&:translations->language`
        );
        return data;
    };

    const {
        data: chapterData,
        isLoading,
        isError,
    } = useQuery("single-chapter", fetchChapter, {
        cacheTime: 0,
    });

    useEffect(() => {
        setTitle("");
    }, [setTitle]);

    useEffect(() => {
        setCoursePreview(false);
    }, [setCoursePreview]);

    const handleDeleteQuestion = async (quizId) => {
        await api.delete(
            `/admin/chapter_quiz?organization_id=${organization}&id=${quizId}`
        );
        successNotification("Question deleted successfully!");
        queryClient.invalidateQueries("single-chapter");
    };

    const handleDeleteChoice = async (choiceId) => {
        await api.delete(
            `/admin/chapter_quiz_option?organization_id=${organization}&id=${choiceId}`
        );
        successNotification("Choice deleted successfully!");
        queryClient.invalidateQueries("single-chapter");
    };

    const handleShowTranslation = (chapter, key) => {
        if (languagePreference == 0) {
            return chapter[key];
        } else {
            const translation = _.find(chapter.translations, [
                "language_id",
                languagePreference,
            ]);
            if (!_.isNil(translation)) {
                return translation[key];
            } else {
                return chapter[key];
            }
        }
    };

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isError) {
        return <>Error... Please refresh the page.</>;
    }

    return (
        <>
            {!chapterData.result ? (
                <Navigate to={`/courses`} />
            ) : (
                <>
                    <Breadcrumb className="mb-6" separator=">">
                        <Breadcrumb.Item>
                            <Link to="/courses">Courses</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {chapterData.result?.title}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    {_.isEmpty(chapterData.result?.translations) ? null : (
                        <div className="mb-6 flex justify-end">
                            <Select
                                defaultValue={0}
                                className="w-36"
                                onChange={(selectedLanguage) =>
                                    setLanguagePreference(selectedLanguage)
                                }
                            >
                                {[
                                    { language: { id: 0, name: "Default" } },
                                    ...chapterData?.result?.translations,
                                ].map((translation) => (
                                    <Select.Option
                                        value={translation?.language?.id}
                                    >
                                        <Icon
                                            icon="clarity:language-line"
                                            className="inline-block mr-2 h-4 w-4"
                                        />
                                        <span>
                                            {translation?.language?.name}
                                        </span>
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    )}
                    <div
                        className="h-36 relative"
                        style={{
                            background: `linear-gradient(to right,
                                rgba(4, 93, 184, 0.8), 
                                rgba(4, 93, 184, 0)
                            ), url(
                                ${
                                    process.env.REACT_APP_IMAGE_API
                                }${handleShowTranslation(
                                chapterData.result,
                                "image_name"
                            )}
                            ) no-repeat center center / cover`,
                        }}
                    >
                        <h1 className="absolute left-10 bottom-2 text-3xl uppercase text-white font-bold">
                            {handleShowTranslation(chapterData.result, "title")}
                        </h1>
                    </div>
                    {handleShowTranslation(
                        chapterData.result,
                        "youtube_url"
                    ) ? (
                        <ReactPlayer
                            className="w-full h-[500px] my-10"
                            url={handleShowTranslation(
                                chapterData.result,
                                "youtube_url"
                            )}
                        />
                    ) : null}

                    <div
                        dangerouslySetInnerHTML={{
                            __html: handleShowTranslation(
                                chapterData.result,
                                "description"
                            ),
                        }}
                        style={{ overflowWrap: "break-word" }}
                    />
                    {handleShowTranslation(chapterData.result, "audio_name") ? (
                        <div className="flex justify-center mt-8">
                            <ReactAudio
                                src={`${
                                    process.env.REACT_APP_IMAGE_API
                                }${handleShowTranslation(
                                    chapterData.result,
                                    "audio_name"
                                )}`}
                                controls
                            />
                        </div>
                    ) : null}

                    {handleShowTranslation(chapterData.result, "file_name") ? (
                        <div className="flex justify-center mt-8">
                            <a
                                href={`${
                                    process.env.REACT_APP_IMAGE_API
                                }${handleShowTranslation(
                                    chapterData.result,
                                    "description"
                                )}`}
                                target="_blank"
                            >
                                <FileTextFilled className="text-6xl" />
                            </a>
                        </div>
                    ) : null}
                    <div className="mt-8">
                        <h3 className="text-base text-gray-500 font-bold">
                            Quiz
                        </h3>

                        {chapterData.result?.quizzes.length > 0 ? (
                            chapterData.result?.quizzes.map((quiz, index) => (
                                <div
                                    key={quiz.id}
                                    className="flex gap-4 items-center mb-6 text-gray-400"
                                >
                                    <div className="flex flex-col gap-y-2">
                                        <div className="flex items-center gap-4">
                                            <span>
                                                {index + 1}.{" "}
                                                {handleShowTranslation(
                                                    quiz,
                                                    "question"
                                                )}
                                            </span>
                                            {hasUpdatePermission(
                                                permissions,
                                                "CHAPTER_QUIZZES"
                                            ) ? (
                                                <Button
                                                    className="py-1 px-2"
                                                    onClick={() =>
                                                        navigate(
                                                            `/courses/${id}/chapters/${chapterId}/quiz/${quiz.id}/edit`
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                            ) : null}
                                            {hasDeletePermission(
                                                permissions,
                                                "CHAPTER_QUIZZES"
                                            ) ? (
                                                <Popconfirm
                                                    title="Are you sure you want to delete this question?"
                                                    okText="Delete"
                                                    onConfirm={() =>
                                                        handleDeleteQuestion(
                                                            quiz.id
                                                        )
                                                    }
                                                    onCancel={(e) =>
                                                        e.preventDefault()
                                                    }
                                                >
                                                    <Button className="py-1 px-2 bg-red-500 text-white">
                                                        Delete
                                                    </Button>
                                                </Popconfirm>
                                            ) : null}
                                        </div>
                                        <div className="flex flex-col gap-y-2">
                                            {quiz.options.map((choice) => (
                                                <div
                                                    key={choice.id}
                                                    className="flex items-center"
                                                >
                                                    <Radio
                                                        disabled
                                                        defaultChecked={
                                                            choice.id ===
                                                            quiz.answer_id
                                                        }
                                                    >
                                                        {handleShowTranslation(
                                                            choice,
                                                            "option"
                                                        )}
                                                    </Radio>
                                                    {hasDeletePermission(
                                                        permissions,
                                                        "CHAPTER_QUIZ_ANSWER"
                                                    ) ? (
                                                        <Popconfirm
                                                            title="Are you sure you want to delete this choice?"
                                                            okText="Delete"
                                                            onConfirm={() =>
                                                                handleDeleteChoice(
                                                                    choice.id
                                                                )
                                                            }
                                                            onCancel={(e) =>
                                                                e.preventDefault()
                                                            }
                                                        >
                                                            <Button
                                                                type="text"
                                                                className="border-none text-red-500"
                                                                icon={
                                                                    <DeleteFilled />
                                                                }
                                                            />
                                                        </Popconfirm>
                                                    ) : null}
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            {handleShowTranslation(
                                                quiz,
                                                "answer_description"
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <Alert
                                className="mb-6"
                                message="No quizzes added to this chapter yet."
                                type="info"
                                showIcon
                            />
                        )}
                        {hasCreatePermission(permissions, "CHAPTER_QUIZZES") ? (
                            <CustomButton
                                text="Add Question"
                                action={() =>
                                    navigate(
                                        `/courses/${id}/chapters/${chapterId}/quiz`
                                    )
                                }
                            />
                        ) : null}
                    </div>
                    <div className="flex justify-end mt-8 gap-4">
                        <Button
                            type="primary"
                            className="uppercase"
                            onClick={() => navigate(-1)}
                            ghost
                        >
                            Back
                        </Button>
                        {hasUpdatePermission(permissions, "CHAPTERS") ? (
                            <Button
                                type="primary"
                                className="uppercase"
                                onClick={() =>
                                    navigate(
                                        `/courses/${id}/chapters/${chapterId}/edit`
                                    )
                                }
                            >
                                Edit
                            </Button>
                        ) : null}
                    </div>
                </>
            )}
        </>
    );
}

export default SingleChapter;
