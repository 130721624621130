import {
    Breadcrumb,
    Button,
    Col,
    Form,
    Input,
    Popover,
    Row,
    Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import api from "../../config/api";
import { organizationState, permissionsState, titleState } from "../../store";
import { hasCreatePermission } from "../../utils/checkPermission";
import {
    errorNotification,
    successNotification,
} from "../../utils/notifications";

const { TextArea } = Input;

function NewCourse() {
    const [fileList, setFileList] = useState([]);
    const [isImageFile, setImageFile] = useState(false);
    const [categoryFormVisible, setCategoryFormVisible] = useState(false);
    const setTitle = useSetRecoilState(titleState);
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [categories, setCategories] = useState([]);
    const [categoryForm] = Form.useForm();
    const [newCourseForm] = Form.useForm();

    useEffect(() => {
        if (!hasCreatePermission(permissions, "COURSES")) {
            navigate("/");
        }
    }, [permissions, navigate]);

    useEffect(() => {
        setTitle("Add New Course");
    }, [setTitle]);

    const fetchCategories = async () => {
        const { data } = await api.get(
            `/admin/course_categories?organization_id=${organization}`
        );
        return data;
    };

    const { mutate, isLoading, isError, error } = useMutation(
        (newCourse) => api.post("/admin/course", newCourse),
        {
            onSuccess: (response, variables, context) => {
                const { data } = response;
                const { result } = data;
                successNotification("Course added successfully");
                navigate(`/courses/${result.id}/chapter?workflow=true`);
            },
        }
    );

    const handleAddCourse = async (values) => {
        if (fileList.length > 0 && isImageFile) {
            const { title, summary, image, course_category_id, author } =
                values;
            const formData = new FormData();
            formData.append("title", title);
            formData.append("summary", summary);
            formData.append("author", author);
            formData.append("image", image[0]["originFileObj"]);
            formData.append("course_category_id", course_category_id);
            formData.append("organization_id", organization);
            mutate(formData);
        } else {
            errorNotification(
                `${fileList[0].name} is not an image. Please select an image.`
            );
        }
    };

    useQuery("categories", fetchCategories, {
        onSuccess: (response) => {
            const { result } = response;
            setCategories(result);
        },
    });

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const isPicture = ["image/png", "image/jpeg", "image/jpg"].includes(
                file.type
            );

            if (!isPicture) {
                errorNotification(
                    `${file.name} is not an image. Please select an image.`
                );
                setImageFile(false);
            } else {
                setImageFile(true);
            }

            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    const handleAddCategory = async (values) => {
        const newCategory = {
            ...values,
            organization_id: organization,
        };
        await api.post(`/admin/course_category`, newCategory);
        categoryForm.resetFields();
        setCategoryFormVisible(false);
        successNotification("Category added successfully!");
        queryClient.invalidateQueries("categories");
    };

    const popoverForm = (
        <Form
            autoComplete="off"
            onFinish={handleAddCategory}
            form={categoryForm}
        >
            <Form.Item
                name="name"
                label="Category Name"
                rules={[
                    {
                        required: true,
                        message: "Please write a category name",
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <div className="text-right">
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </div>
        </Form>
    );

    return (
        <>
            <Breadcrumb className="mb-6" separator=">">
                <Breadcrumb.Item>
                    <Link to="/courses">Courses</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Adding a new course</Breadcrumb.Item>
            </Breadcrumb>
            <Form
                form={newCourseForm}
                colon={false}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 18 }}
                onFinish={handleAddCourse}
                requiredMark={false}
                autoComplete="off"
            >
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Course Title
                        </span>
                    }
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Please write a course title",
                        },
                    ]}
                    initialValue=""
                >
                    <Input
                        showCount
                        maxLength={40}
                        placeholder="Simple and short title"
                    />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Description
                        </span>
                    }
                    name="summary"
                    validateStatus={
                        isError && error.response.data.errors.summary
                            ? "error"
                            : ""
                    }
                    help={
                        isError && error.response.data.errors.summary
                            ? error.response.data.errors.summary
                            : ""
                    }
                    initialValue=""
                >
                    <TextArea
                        rows={4}
                        showCount
                        maxLength={100}
                        placeholder="Any short discription about the course"
                    />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Author
                        </span>
                    }
                    name="author"
                    initialValue=""
                >
                    <Input placeholder="Name of the author" />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Thumbnail
                        </span>
                    }
                    name="image"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => {
                        if (Array.isArray(e)) {
                            return e;
                        }
                        return e && e.fileList;
                    }}
                    rules={[
                        {
                            required: true,
                            message: "Please select an image",
                        },
                    ]}
                >
                    <Upload {...uploadProps} accept="image/*" maxCount={1}>
                        <Button>Click to upload</Button>
                    </Upload>
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Category
                        </span>
                    }
                >
                    <Form.Item
                        noStyle
                        name="course_category_id"
                        initialValue=""
                        rules={[
                            {
                                required: true,
                                message: "Please select a course category",
                            },
                        ]}
                    >
                        <select className="bg-brand text-white rounded py-2 px-4">
                            <option value="" hidden>
                                Select a category
                            </option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </Form.Item>
                    <Popover
                        trigger={["click"]}
                        placement="right"
                        content={popoverForm}
                        visible={categoryFormVisible}
                        onVisibleChange={(visible) =>
                            setCategoryFormVisible(visible)
                        }
                    >
                        <Button className="ml-4">Add Category</Button>
                    </Popover>
                </Form.Item>
                {/* <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Language
                        </span>
                    }
                >
                    <Radio.Group
                        defaultValue="a"
                        optionType="button"
                        buttonStyle="solid"
                        className="flex gap-4"
                    >
                        <Radio.Button value="a" className="rounded">
                            English
                        </Radio.Button>
                        <Radio.Button value="b" className="rounded">
                            Amharic
                        </Radio.Button>
                        <Radio.Button value="c" className="rounded">
                            Oromiffa
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item> */}
                <Row>
                    <Col span={4}></Col>
                    <Col span={18} className="flex justify-end gap-4">
                        <Button
                            onClick={() => navigate(-1)}
                            type="primary"
                            className="uppercase"
                            ghost
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            className="uppercase"
                            htmlType="submit"
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default NewCourse;
