import { atom } from "recoil";

const editDiscussion = {
    visible: atom({
        key: "editDiscussionVisible",
        default: false,
    }),
    data: atom({
        key: "editDiscussionData",
        default: null,
    }),
};

export default editDiscussion;
