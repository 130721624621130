export const getInitals = (fullName) => {
    if (fullName) {
        const names = fullName.split(" ");
        let initials;
        if (names.length < 2) {
            initials = names[0].charAt(0);
        } else {
            initials = names[0].charAt(0) + names[names.length - 1].charAt(0);
        }
        return initials.toUpperCase();
    }
};
