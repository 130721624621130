import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "user",
});

const userState = atom({
  key: "user",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export default userState;
