import React, { useEffect, useState } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { usePagination, useTable, useGlobalFilter } from "react-table";
import {
    CaretLeftFilled,
    CaretRightFilled,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { Divider, Button, Popconfirm, Input, Popover, Form } from "antd";
import { useQuery, useQueryClient, useMutation } from "react-query";

import {
    titleState,
    organizationState,
    permissionsState,
    mobileNavState,
} from "../../store";
import api from "../../config/api";
import { successNotification } from "../../utils/notifications";
import {
    hasDeletePermission,
    hasUpdatePermission,
} from "../../utils/checkPermission";

function CourseCategories() {
    const [pageNumber, setPageNumber] = useState(0);
    const [pageLimit, setPageLimit] = useState(10);
    const [searchbarVisible, setSearchbarVisible] = useState(false);
    const [courseCategories, setCourseCategories] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalCategories, setTotalCategories] = useState(0);

    const setTitle = useSetRecoilState(titleState);
    const setMobileNav = useSetRecoilState(mobileNavState);
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);

    const queryClient = useQueryClient();
    const [categoryForm] = Form.useForm();
    let courseCategoryId;

    useEffect(() => {
        setTitle("Course Categories");
    }, [setTitle]);

    useEffect(() => {
        setMobileNav(false);
    }, [setMobileNav]);

    const fetchCategories = async (pageNumber, pageSize) => {
        const { data } = await api.get(
            `/admin/course_categories_paginated?organization_id=${organization}&page=${
                pageNumber + 1
            }&PAGINATE_SIZE=${pageSize}`
        );
        return data;
    };

    const { isLoading, isError } = useQuery(
        ["course-categories", pageNumber, pageLimit, organization],
        () => fetchCategories(pageNumber, pageLimit),
        {
            keepPreviousData: true,
            onSuccess: (response) => {
                const { result } = response;
                const { data, last_page, total } = result;
                setCourseCategories(data);
                setPageCount(last_page);
                setTotalCategories(total);
            },
        }
    );

    const handleDeleteCourseCategory = async (data) => {
        await api.delete(
            `/admin/course_category?id=${data.id}&organization_id=${organization}`
        );
        successNotification("Course category deleted successfully!");
        queryClient.invalidateQueries("course-categories");
    };

    const { mutate, isLoading: isSaving } = useMutation(
        (editedCategory) => api.patch(`/admin/course_category`, editedCategory),
        {
            onSuccess: () => {
                successNotification("Course category edited successfully!");
                queryClient.invalidateQueries("course-categories");
            },
        }
    );

    const handleEditCategory = async (values) => {
        const editedCategory = {
            ...values,
            organization_id: organization,
            id: courseCategoryId,
        };
        mutate(editedCategory);
    };

    const handleSetCategoryRecord = (rowRecord) => {
        categoryForm.setFieldsValue({
            name: rowRecord.name,
        });
        courseCategoryId = rowRecord.id;
    };

    const popoverForm = () => (
        <Form
            autoComplete="off"
            onFinish={handleEditCategory}
            form={categoryForm}
        >
            <Form.Item
                name="name"
                label="Category Name"
                rules={[
                    {
                        required: true,
                        message: "Name is required",
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <div className="text-right">
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSaving}
                    disabled={isSaving}
                >
                    Save
                </Button>
            </div>
        </Form>
    );

    const data = React.useMemo(() => courseCategories, [courseCategories]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Course Category",
                accessor: "name",
            },
            {
                Header: "Actions",
                accessor: "actions",
                Cell: ({ row }) => {
                    return (
                        <div className="flex gap-2">
                            {hasUpdatePermission(
                                permissions,
                                "COURSE_CATEGORIES"
                            ) ? (
                                <Popover
                                    trigger={["click"]}
                                    placement="left"
                                    content={popoverForm}
                                    className="cursor-pointer"
                                >
                                    <Icon
                                        icon="eva:edit-fill"
                                        className="text-brand"
                                        onClick={() =>
                                            handleSetCategoryRecord(
                                                row.original
                                            )
                                        }
                                    />
                                </Popover>
                            ) : null}
                            {hasDeletePermission(
                                permissions,
                                "COURSE_CATEGORIES"
                            ) ? (
                                <Popconfirm
                                    className="cursor-pointer"
                                    title="Are you sure you want to delete this category?"
                                    okText="Delete"
                                    onConfirm={() =>
                                        handleDeleteCourseCategory(row.original)
                                    }
                                    onCancel={(e) => e.preventDefault()}
                                >
                                    <Icon
                                        icon="fluent:delete-off-20-filled"
                                        className="text-red-500"
                                    />
                                </Popconfirm>
                            ) : null}
                        </div>
                    );
                },
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        setPageSize,
        pageOptions,
        gotoPage,
        state: { pageIndex, pageSize, globalFilter },
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: pageNumber,
                pageSize: pageLimit,
                hiddenColumns: [
                    !hasUpdatePermission(permissions, "COURSE_CATEGORIES") &&
                    !hasDeletePermission(permissions, "COURSE_CATEGORIES")
                        ? "actions"
                        : "",
                ],
            },
            manualPagination: true,
            pageCount,
        },
        useGlobalFilter,
        usePagination
    );

    useEffect(() => {
        setPageNumber(pageIndex);
    }, [pageIndex]);

    useEffect(() => {
        setPageLimit(pageSize);
    }, [pageSize]);

    const toggleSearchbar = () => {
        setSearchbarVisible(!searchbarVisible);
    };

    const onSearch = (event) => {
        setGlobalFilter(event.target.value);
    };

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isError) {
        return <>Error... Please refresh the page.</>;
    }

    return (
        <>
            <Divider className="mb-8" />
            <div className="flex items-center justify-end gap-2">
                <SearchOutlined
                    className="text-brand"
                    onClick={toggleSearchbar}
                />
                {searchbarVisible ? (
                    <Input
                        className="w-60"
                        defaultValue={globalFilter}
                        onKeyUp={onSearch}
                    />
                ) : null}
                <select
                    className="border border-brand rounded"
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[5, 10, 20, 50, 100, 200].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
                <div className="flex text-brand">
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => gotoPage(0)}
                        icon={<DoubleLeftOutlined />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        icon={<CaretLeftFilled />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        icon={<CaretRightFilled />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => gotoPage(pageCount - 1)}
                        icon={<DoubleRightOutlined />}
                    />
                </div>
            </div>
            <table {...getTableProps()} className="w-full">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            className="text-left text-gray-400"
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    className="font-normal px-4"
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className="odd:bg-gray-100 hover:bg-brand hover:opacity-40 hover:text-white text-left"
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            className="p-4"
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="flex items-center justify-end gap-2">
                <span>
                    Page {pageIndex + 1} / {pageOptions.length}
                </span>
                <select
                    className="border border-brand rounded"
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[5, 10, 20, 50, 100, 200].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
                <div className="flex text-brand">
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => gotoPage(0)}
                        icon={<DoubleLeftOutlined />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        icon={<CaretLeftFilled />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        icon={<CaretRightFilled />}
                    />
                    <Button
                        type="text"
                        className="border-none text-brand"
                        onClick={() => gotoPage(pageCount - 1)}
                        icon={<DoubleRightOutlined />}
                    />
                </div>
                <span>Total Course Categories: {totalCategories}</span>
            </div>
        </>
    );
}

export default CourseCategories;
