import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
    key: "permissions",
});

const permissionsState = atom({
    key: "permissions",
    default: null,
    effects_UNSTABLE: [persistAtom],
});

export default permissionsState;
