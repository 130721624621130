import { Divider } from "antd";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ReplyCard, SingleDiscussionCard } from "../../components/UI/Cards";
import { CommentModal, ReplyModal } from "../../components/UI/Modals";
import api from "../../config/api";
import { organizationState, titleState } from "../../store";

function SingleDiscussion() {
    const [commentModalOpen, setCommentModalOpen] = useState(false);
    const [replyModalOpen, setReplyModalOpen] = useState(false);
    const [discussionCommentId, setDiscussionCommentId] = useState(0);
    const [discussion, setDiscussion] = useState(null);
    const [sort, setSort] = useState("desc");
    const setTitle = useSetRecoilState(titleState);
    const organization = useRecoilValue(organizationState);
    const { id } = useParams();

    useEffect(() => {
        setTitle("Discussion");
    }, [setTitle]);

    const openReplyModal = (open, id) => {
        setReplyModalOpen(open);
        setDiscussionCommentId(id);
    };

    const fetchDiscussion = async () => {
        const { data } = await api.get(
            `/admin/discussion?organization_id=${organization}&id=${id}&:created_by=&:comments->created_by&^comments&^likes&:comments->comments->likes&^replies`
        );
        return data;
    };

    const { isLoading, isError } = useQuery("discussion", fetchDiscussion, {
        cacheTime: 0,
        onSuccess: (response) => {
            setDiscussion(response.result);
        },
    });

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isError) {
        return <>Error... Please refresh the page.</>;
    }

    return (
        <>
            <Divider />
            <SingleDiscussionCard
                id={discussion.id}
                profile_picture={discussion.created_by.image_name}
                title={discussion.title}
                description={discussion.description}
                author_name={discussion.created_by.full_name}
                comments_count={
                    discussion.comments_count + discussion.replies_count
                }
                likes_count={discussion.likes_count}
                time={discussion.created_at}
                setCommentModalOpen={setCommentModalOpen}
            />
            <div className="w-4/5 lg:w-3/4 mx-auto mt-8 flex flex-col gap-6">
                <div className="text-right">
                    <select
                        className="text-brand"
                        defaultValue={sort}
                        onChange={(event) => setSort(event.target.value)}
                    >
                        <option value="desc">Recent</option>
                        <option value="likes_count">Most Liked</option>
                        <option value="comments_count">Most Replies</option>
                    </select>
                </div>
                {_.orderBy(discussion.comments, ["id"], [sort]).map(
                    (comment) => (
                        <Fragment key={comment.id}>
                            <ReplyCard
                                id={comment.id}
                                profile_picture={comment.user.image_name}
                                author_name={comment.user.full_name}
                                description={comment.comment}
                                likes_count={comment.likes_count}
                                comments_count={comment.comments_count}
                                time={comment.created_at}
                                replyButton={true}
                                openReplyModal={openReplyModal}
                                type="comment"
                                likes={comment.likes}
                            />
                            {_.orderBy(comment.comments, ["id"], ["desc"]).map(
                                (commentReply) => (
                                    <div
                                        key={commentReply.id}
                                        className="w-4/5 lg:w-3/4 ml-auto"
                                    >
                                        <ReplyCard
                                            id={commentReply.id}
                                            profile_picture={
                                                commentReply.user.image_name
                                            }
                                            author_name={
                                                commentReply.user.full_name
                                            }
                                            description={commentReply.reply}
                                            time={commentReply.created_at}
                                            replyButton={false}
                                            type="reply"
                                            likes_count={
                                                commentReply?.likes_count
                                            }
                                            likes={commentReply?.likes}
                                        />
                                    </div>
                                )
                            )}
                        </Fragment>
                    )
                )}
            </div>
            <CommentModal
                open={commentModalOpen}
                closeAction={setCommentModalOpen}
            />
            <ReplyModal
                open={replyModalOpen}
                closeAction={setReplyModalOpen}
                discussionCommentId={discussionCommentId}
            />
        </>
    );
}

export default SingleDiscussion;
