import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import api from "../../../../config/api";
import { organizationState } from "../../../../store";
import { successNotification } from "../../../../utils/notifications";
import "./CommentModal.less";

const { TextArea } = Input;

function CommentModal({ open, closeAction }) {
    const [form] = Form.useForm();
    const organization = useRecoilValue(organizationState);
    const queryClient = useQueryClient();
    const { id } = useParams();

    const { mutate, isLoading } = useMutation(
        (commentText) => api.post(`/admin/discussion_comment`, commentText),
        {
            onSuccess: (response, variables, context) => {
                successNotification("Reply added successfully!");
                closeAction(false);
                queryClient.invalidateQueries("discussion");
                form.resetFields();
            },
        }
    );

    const handleReply = (values) => {
        const comment = {
            ...values,
            discussion_id: id,
            organization_id: organization,
        };

        mutate(comment);
    };

    return (
        <Modal
            centered={true}
            title={
                <span className="text-brand font-bold uppercase">Reply</span>
            }
            visible={open}
            footer={
                <div className="text-center">
                    <Button
                        type="primary"
                        className="rounded"
                        onClick={() => form.submit()}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        Reply
                    </Button>
                </div>
            }
            onCancel={() => closeAction(false)}
            maskClosable={false}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={handleReply}
                requiredMark={false}
                className="px-6"
            >
                <Form.Item
                    name="comment"
                    rules={[
                        {
                            required: true,
                            message: "Please input the reply",
                        },
                    ]}
                >
                    <TextArea rows={6} />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CommentModal;
