import { Breadcrumb, Button, Col, Form, Input, Row, Upload } from "antd";
import _ from "lodash";
import ImageResize from "quill-image-resize-module-react";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import ReactQuill, { Quill } from "react-quill";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import api from "../../config/api";
import {
    coursePreviewState,
    organizationState,
    permissionsState,
    titleState,
} from "../../store";
import { hasCreatePermission } from "../../utils/checkPermission";
import {
    errorNotification,
    successNotification,
} from "../../utils/notifications";

Quill.register("modules/imageResize", ImageResize);

const fontSizes = [
    "13px",
    "14px",
    "16px",
    "18px",
    "24px",
    "32px",
    "40px",
    "48px",
    "80px",
    "96px",
];
const Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizes;
Quill.register(Size, true);

const { TextArea } = Input;

function NewChapter() {
    const [fileList, setFileList] = useState([]);
    const [isImageFile, setImageFile] = useState(false);
    const [audioFileList, setAudioFileList] = useState([]);
    const [docFileList, setDocFileList] = useState([]);
    const setTitle = useSetRecoilState(titleState);
    const setCoursePreview = useSetRecoilState(coursePreviewState.visible);
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const { id } = useParams();
    const [courseTitle, setCourseTitle] = useState("");
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!hasCreatePermission(permissions, "CHAPTERS")) {
            navigate("/");
        }
    }, [permissions, navigate]);

    useEffect(() => {
        setTitle("Add New Chapter");
    }, [setTitle]);

    useEffect(() => {
        setCoursePreview(false);
    }, [setCoursePreview]);

    const { mutate, isLoading } = useMutation(
        (newChapter) => api.post("/admin/chapter", newChapter),
        {
            onSuccess: (response, variables, context) => {
                const { result } = response.data;
                successNotification("Chapter added successfully");
                navigate(
                    `/courses/${id}/chapters/${result.id}/quiz?workflow=true`
                );
            },
        }
    );

    const fetchCourse = async () => {
        const { data } = await api.get(
            `/admin/course?id=${id}&organization_id=${organization}`
        );
        return data;
    };

    useQuery("course", fetchCourse, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            setCourseTitle(result.title);
        },
    });

    const handleAddChapter = async (values) => {
        if (fileList.length > 0 && isImageFile) {
            const {
                title,
                summary,
                description,
                image,
                audio,
                youtube_url,
                file,
            } = values;

            const formData = new FormData();
            formData.append("title", title);
            formData.append("summary", summary);
            formData.append("description", description);
            formData.append("image", image[0]["originFileObj"]);
            if (docFileList.length > 0) {
                formData.append("file", file[0]["originFileObj"]);
            }
            if (audioFileList.length > 0) {
                formData.append("audio", audio[0]["originFileObj"]);
            }
            formData.append("youtube_url", youtube_url);
            formData.append("course_id", id);
            formData.append("organization_id", organization);

            mutate(formData);
        } else {
            errorNotification(
                `${fileList[0].name} is not an image. Please select an image.`
            );
        }
    };

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const isPicture = ["image/png", "image/jpeg", "image/jpg"].includes(
                file.type
            );

            if (!isPicture) {
                errorNotification(
                    `${file.name} is not an image. Please select an image.`
                );
                setImageFile(false);
            } else {
                setImageFile(true);
            }

            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    const audioUploadProps = {
        onRemove: (file) => {
            const index = audioFileList.indexOf(file);
            const newFileList = audioFileList.slice();
            newFileList.splice(index, 1);
            setAudioFileList(newFileList);
        },
        beforeUpload: (file) => {
            setAudioFileList([...audioFileList, file]);

            return false;
        },
        audioFileList,
    };

    const docUploadProps = {
        onRemove: (file) => {
            const index = docFileList.indexOf(file);
            const newFileList = docFileList.slice();
            newFileList.splice(index, 1);
            setDocFileList(newFileList);
        },
        beforeUpload: (file) => {
            setDocFileList([...docFileList, file]);

            return false;
        },
        docFileList,
    };

    const handleCancel = () => {
        if (_.isEqual(searchParams.get("workflow"), "true")) {
            navigate(`/courses/${id}/edit`);
        } else {
            navigate(-1);
        }
    };

    return (
        <>
            <Breadcrumb className="mb-6" separator=">">
                <Breadcrumb.Item>
                    <Link to="/courses">Courses</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{courseTitle}</Breadcrumb.Item>
                <Breadcrumb.Item>Adding a new chapter</Breadcrumb.Item>
            </Breadcrumb>
            <Form
                colon={false}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 18 }}
                onFinish={handleAddChapter}
                autoComplete="off"
                requiredMark={false}
            >
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Chapter Topic
                        </span>
                    }
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Please input a chapter topic",
                        },
                    ]}
                >
                    <Input placeholder="Simple and short title" />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Summary
                        </span>
                    }
                    name="summary"
                    initialValue=""
                >
                    <TextArea
                        showCount
                        maxLength={100}
                        placeholder="Any short summary about the course"
                    />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Content
                        </span>
                    }
                    name="description"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please input a chapter topic",
                    //   },
                    // ]}
                    initialValue=""
                >
                    <ReactQuill
                        modules={{
                            toolbar: [
                                [{ header: [1, 2, false] }],
                                [{ size: fontSizes }],
                                ["bold", "italic", "underline"],
                                [{ list: "ordered" }, { list: "bullet" }],
                                [
                                    { align: "" },
                                    { align: "center" },
                                    { align: "right" },
                                    { align: "justify" },
                                ],
                                ["image", "video"],
                            ],
                            imageResize: {
                                parchment: Quill.import("parchment"),
                                modules: ["Resize", "DisplaySize"],
                            },
                        }}
                    />
                </Form.Item>
                {/* <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Author
                        </span>
                    }
                >
                    <Input placeholder="Name of the author" />
                </Form.Item> */}
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Thumbnail
                        </span>
                    }
                    name="image"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => {
                        if (Array.isArray(e)) {
                            return e;
                        }
                        return e && e.fileList;
                    }}
                    rules={[
                        {
                            required: true,
                            message: "Please select an image",
                        },
                    ]}
                >
                    <Upload {...uploadProps} accept="image/*" maxCount={1}>
                        <Button>Click to upload</Button>
                    </Upload>
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Audio
                        </span>
                    }
                    name="audio"
                    valuePropName="file"
                    getValueFromEvent={(e) => {
                        if (Array.isArray(e)) {
                            return e;
                        }
                        return e && e.fileList;
                    }}
                >
                    <Upload {...audioUploadProps} accept="audio/*" maxCount={1}>
                        <Button>Click to upload</Button>
                    </Upload>
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            File
                        </span>
                    }
                    name="file"
                    valuePropName="file"
                    getValueFromEvent={(e) => {
                        if (Array.isArray(e)) {
                            return e;
                        }
                        return e && e.fileList;
                    }}
                >
                    <Upload
                        {...docUploadProps}
                        accept=".doc, .docx, .pdf"
                        maxCount={1}
                    >
                        <Button>Click to upload</Button>
                    </Upload>
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Video
                        </span>
                    }
                    name="youtube_url"
                    rules={[
                        {
                            type: "url",
                            message: "Please input a valid youtube url",
                        },
                        {
                            pattern: new RegExp(
                                /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/g
                            ),
                            message: "Please only write a youtube url",
                        },
                    ]}
                    initialValue=""
                >
                    <Input placeholder="Link for the video" />
                </Form.Item>
                <Row>
                    <Col span={4}></Col>
                    <Col span={18} className="flex justify-end gap-4">
                        <Button
                            onClick={handleCancel}
                            type="primary"
                            className="uppercase"
                            ghost
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            className="uppercase"
                            htmlType="submit"
                            disabled={isLoading}
                            loading={isLoading}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default NewChapter;
