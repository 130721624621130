import { FileTextFilled } from "@ant-design/icons";
import {
    Alert,
    Breadcrumb,
    Button,
    Col,
    Form,
    Input,
    Popconfirm,
    Row,
    Upload,
} from "antd";
import _ from "lodash";
import ImageResize from "quill-image-resize-module-react";
import React, { useEffect, useState } from "react";
import ReactAudio from "react-audio-player";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ReactQuill, { Quill } from "react-quill";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CustomButton } from "../../components/UI/Buttons";
import api from "../../config/api";
import {
    coursePreviewState,
    organizationState,
    permissionsState,
    titleState,
} from "../../store";
import { hasUpdatePermission } from "../../utils/checkPermission";
import {
    errorNotification,
    successNotification,
} from "../../utils/notifications";

Quill.register("modules/imageResize", ImageResize);

const fontSizes = [
    "13px",
    "14px",
    "16px",
    "18px",
    "24px",
    "32px",
    "40px",
    "48px",
    "80px",
    "96px",
];
const Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizes;
Quill.register(Size, true);

const { TextArea } = Input;

function EditChapter() {
    const [fileList, setFileList] = useState([]);
    const [isImageFile, setImageFile] = useState(false);
    const [audioFileList, setAudioFileList] = useState([]);
    const [docFileList, setDocFileList] = useState([]);
    const setTitle = useSetRecoilState(titleState);
    const setCoursePreview = useSetRecoilState(coursePreviewState.visible);
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const { id, chapterId } = useParams();
    const navigate = useNavigate();
    const [editChapterForm] = Form.useForm();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!hasUpdatePermission(permissions, "CHAPTERS")) {
            navigate("/");
        }
    }, [permissions, navigate]);

    useEffect(() => {
        setTitle("Edit Chapter");
    }, [setTitle]);

    useEffect(() => {
        setCoursePreview(false);
    }, [setCoursePreview]);

    const fetchChapter = async () => {
        const { data } = await api.get(
            `/admin/chapter?id=${chapterId}&:paragraphs&:quizzes->options&organization_id=${organization}`
        );
        return data;
    };

    const { data: chapterData, isLoading } = useQuery("chapter", fetchChapter, {
        cacheTime: 0,
    });

    const { mutate, isLoading: isSubmitting } = useMutation(
        (editedChapter) =>
            api.post(
                `/admin/chapter_update?organization_id=${organization}`,
                editedChapter
            ),
        {
            onSuccess: (response, variables, context) => {
                successNotification("Chapter edited successfully");
                // navigate(`/courses/${id}/chapters/${chapterId}`);
                navigate(`/courses`);
            },
        }
    );

    const { mutate: mutateSave, isLoading: isSaving } = useMutation(
        (editedChapter) =>
            api.post(
                `/admin/chapter_update?organization_id=${organization}`,
                editedChapter
            ),
        {
            onSuccess: (response, variables, context) => {
                successNotification("Chapter edit saved successfully");
                queryClient.invalidateQueries("chapter");
            },
        }
    );

    const handleEditChapter = async (values) => {
        const { title, summary, description, image, audio, youtube_url, file } =
            values;

        const formData = new FormData();
        formData.append("title", title);
        formData.append("summary", summary);
        formData.append("description", description);
        if (fileList.length > 0 && isImageFile) {
            formData.append("image", image[0]["originFileObj"]);
        } else if (fileList.length > 0 && !isImageFile) {
            errorNotification(
                `${fileList[0].name} is not an image. Please select an image.`
            );
            return;
        }
        if (docFileList.length > 0) {
            formData.append("file", file[0]["originFileObj"]);
        }
        if (audioFileList.length > 0) {
            formData.append("audio", audio[0]["originFileObj"]);
        }
        formData.append("youtube_url", youtube_url);
        formData.append("course_id", id);
        formData.append("id", chapterId);
        mutate(formData);
    };

    const handleSave = async () => {
        const values = editChapterForm.getFieldsValue();
        const { title, summary, description, image, audio, youtube_url, file } =
            values;

        const formData = new FormData();
        formData.append("title", title);
        formData.append("summary", summary);
        formData.append("description", description);
        if (fileList.length > 0 && isImageFile) {
            formData.append("image", image[0]["originFileObj"]);
        } else if (fileList.length > 0 && !isImageFile) {
            errorNotification(
                `${fileList[0].name} is not an image. Please select an image.`
            );
            return;
        }
        if (docFileList.length > 0) {
            formData.append("file", file[0]["originFileObj"]);
        }
        if (audioFileList.length > 0) {
            formData.append("audio", audio[0]["originFileObj"]);
        }
        formData.append("youtube_url", youtube_url);
        formData.append("course_id", id);
        formData.append("id", chapterId);
        mutateSave(formData);
    };

    const handleDeleteQuestion = async (quizId) => {
        await api.delete(
            `/admin/chapter_quiz?organization_id=${organization}&id=${quizId}`
        );
        successNotification("Question deleted successfully!");
        queryClient.invalidateQueries("chapter");
    };

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const isPicture = ["image/png", "image/jpeg", "image/jpg"].includes(
                file.type
            );

            if (!isPicture) {
                errorNotification(
                    `${file.name} is not an image. Please select an image.`
                );
                setImageFile(false);
            } else {
                setImageFile(true);
            }

            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    const audioUploadProps = {
        onRemove: (file) => {
            const index = audioFileList.indexOf(file);
            const newFileList = audioFileList.slice();
            newFileList.splice(index, 1);
            setAudioFileList(newFileList);
        },
        beforeUpload: (file) => {
            setAudioFileList([...audioFileList, file]);

            return false;
        },
        audioFileList,
    };

    const docUploadProps = {
        onRemove: (file) => {
            const index = docFileList.indexOf(file);
            const newFileList = docFileList.slice();
            newFileList.splice(index, 1);
            setDocFileList(newFileList);
        },
        beforeUpload: (file) => {
            setDocFileList([...docFileList, file]);

            return false;
        },
        docFileList,
    };

    if (isLoading) {
        return <>Loading...</>;
    }

    let editForm;

    if (chapterData) {
        const { result } = chapterData;

        editForm = (
            <Form
                colon={false}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 18 }}
                onFinish={handleEditChapter}
                autoComplete="off"
                form={editChapterForm}
            >
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Chapter Topic
                        </span>
                    }
                    name="title"
                    initialValue={result.title}
                >
                    <Input placeholder="Simple and short title" />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Summary
                        </span>
                    }
                    name="summary"
                    initialValue={result.summary}
                >
                    <TextArea
                        showCount
                        maxLength={100}
                        placeholder="Any short summary about the course"
                    />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Content
                        </span>
                    }
                    name="description"
                    initialValue={result.description}
                >
                    <ReactQuill
                        modules={{
                            toolbar: [
                                [{ header: [1, 2, false] }],
                                [{ size: fontSizes }],
                                ["bold", "italic", "underline"],
                                [{ list: "ordered" }, { list: "bullet" }],
                                [
                                    { align: "" },
                                    { align: "center" },
                                    { align: "right" },
                                    { align: "justify" },
                                ],
                                ["image", "video"],
                            ],
                            imageResize: {
                                parchment: Quill.import("parchment"),
                                modules: ["Resize", "DisplaySize"],
                            },
                        }}
                    />
                </Form.Item>
                {/* <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Author
                        </span>
                    }
                >
                    <Input placeholder="Name of the author" />
                </Form.Item> */}
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Thumbnail
                        </span>
                    }
                >
                    <img
                        src={`${process.env.REACT_APP_IMAGE_API}${result.image_name}`}
                        alt=""
                        className="h-[109px] w-[109px] object-cover object-center rounded mb-4"
                    />
                    <Form.Item
                        noStyle
                        name="image"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e && e.fileList;
                        }}
                    >
                        <Upload {...uploadProps} accept="image/*" maxCount={1}>
                            <Button>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Audio
                        </span>
                    }
                >
                    {Boolean(result.audio_name) && (
                        <ReactAudio
                            className="mb-4"
                            src={`${process.env.REACT_APP_IMAGE_API}${result.audio_name}`}
                            controls
                        />
                    )}
                    <Form.Item
                        noStyle
                        name="audio"
                        valuePropName="file"
                        getValueFromEvent={(e) => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e && e.fileList;
                        }}
                    >
                        <Upload
                            {...audioUploadProps}
                            accept="audio/*"
                            maxCount={1}
                        >
                            <Button>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            File
                        </span>
                    }
                >
                    {chapterData.result?.file_name ? (
                        <a
                            href={`${process.env.REACT_APP_IMAGE_API}${chapterData.result?.file_name}`}
                            target="_blank"
                            rel="noreferrer"
                            className="block mb-4"
                        >
                            <FileTextFilled className="text-6xl" />
                        </a>
                    ) : null}
                    <Form.Item
                        noStyle
                        name="file"
                        valuePropName="file"
                        getValueFromEvent={(e) => {
                            if (Array.isArray(e)) {
                                return e;
                            }
                            return e && e.fileList;
                        }}
                    >
                        <Upload
                            {...docUploadProps}
                            accept=".doc, .docx, .pdf"
                            maxCount={1}
                        >
                            <Button>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-400 uppercase font-bold">
                            Video
                        </span>
                    }
                    name="youtube_url"
                    initialValue={
                        _.isNil(result.youtube_url) ? "" : result.youtube_url
                    }
                >
                    <Input placeholder="Link for the video" />
                </Form.Item>
                <Row>
                    <Col span={4}></Col>
                    <Col span={18} className="flex justify-end gap-4">
                        <Button
                            type="primary"
                            className="uppercase"
                            ghost
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            className="uppercase"
                            onClick={handleSave}
                            ghost
                            loading={isSaving}
                            disabled={isSaving}
                        >
                            Save
                        </Button>
                        <Button
                            type="primary"
                            className="uppercase"
                            htmlType="submit"
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
                <div className="mt-8">
                    <h3 className="text-base text-gray-500 uppercase font-bold">
                        Quizzes
                    </h3>
                    {result.quizzes.length > 0 ? (
                        result.quizzes.map((quiz, index) => (
                            <div
                                key={quiz.id}
                                className="flex gap-4 items-center mb-6"
                            >
                                <Button
                                    type="primary"
                                    size="large"
                                    className="rounded"
                                    onClick={() =>
                                        navigate(
                                            `/courses/${id}/chapters/${quiz.chapter_id}`
                                        )
                                    }
                                >
                                    Question {index + 1}
                                </Button>
                                <div>{quiz.question}</div>
                                <Button
                                    className="py-1 px-2"
                                    onClick={() =>
                                        navigate(
                                            `/courses/${id}/chapters/${quiz.chapter_id}/quiz/${quiz.id}/edit`
                                        )
                                    }
                                >
                                    Edit
                                </Button>
                                <Popconfirm
                                    title="Are you sure you want to delete this quiz?"
                                    okText="Delete"
                                    onConfirm={() =>
                                        handleDeleteQuestion(quiz.id)
                                    }
                                    onCancel={(e) => e.preventDefault()}
                                >
                                    <Button className="py-1 px-2 bg-red-500 text-white">
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </div>
                        ))
                    ) : (
                        <Alert
                            className="mb-6"
                            message="No quizzes added to this chapter yet."
                            type="info"
                            showIcon
                        />
                    )}
                    <CustomButton
                        text="Add Question"
                        action={() =>
                            navigate(
                                `/courses/${id}/chapters/${chapterId}/quiz`
                            )
                        }
                    />
                </div>
            </Form>
        );
    }

    return (
        <>
            <Breadcrumb className="mb-6" separator=">">
                <Breadcrumb.Item>
                    <Link to="/courses">Courses</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Editing a chapter</Breadcrumb.Item>
            </Breadcrumb>
            {editForm}
        </>
    );
}

export default EditChapter;
