import { CaretDownFilled } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { Button, Divider, Drawer, Layout, Menu, Popover } from "antd";
import React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Background from "../assets/images/boy.webp";
import Logo from "../assets/logos/Hulentenawi-vertical.svg";
import ChangePassword from "../components/UI/Modals/ChangePassword/ChangePassword";
import EditProfile from "../components/UI/Modals/EditProfile/EditProfile";
import {
    availableOrganizationsState,
    changePasswordState,
    editProfileState,
    mobileNavState,
    organizationState,
    permissionsState,
    titleState,
    tokenState,
    userState,
} from "../store";
import { imageChecker } from "../utils/imageChecker";
import { getInitals } from "../utils/initials";
import "./MainLayout.less";

const { Sider, Content } = Layout;

function MainLayout() {
    const [navigationDrawer, setNavigationDrawer] =
        useRecoilState(mobileNavState);
    const title = useRecoilValue(titleState);
    const setProfileVisible = useSetRecoilState(editProfileState.visible);
    const setChangePasswordVisible = useSetRecoilState(
        changePasswordState.visible
    );
    const setProfile = useSetRecoilState(editProfileState.data);
    const [user, setUser] = useRecoilState(userState);
    const setToken = useSetRecoilState(tokenState);
    const setPermissions = useSetRecoilState(permissionsState);
    const [availableOrganizations, setAvailableOrganizations] = useRecoilState(
        availableOrganizationsState
    );
    const [currentOrganization, setCurrentOrganization] =
        useRecoilState(organizationState);
    const location = useLocation();
    const { pathname } = location;

    const routes = [
        {
            name: "Dashboard",
            url: "/",
            icon: <Icon icon="lucide:layout-dashboard" />,
        },
        {
            name: "Courses",
            url: "/courses",
            icon: <Icon icon="carbon:course" />,
        },
        {
            name: "Discussion",
            url: "/discussion",
            icon: <Icon icon="octicon:comment-discussion-16" />,
        },
        {
            name: "Mobile Users",
            url: "/mobile-users",
            icon: <Icon icon="clarity:mobile-phone-solid" />,
        },
        {
            name: "Admin User",
            url: "/admin-users",
            icon: <Icon icon="wpf:administrator" />,
        },
        {
            name: "Settings",
            url: "/course-categories",
            icon: <Icon icon="carbon:collapse-categories" />,
        },
    ];

    const logout = () => {
        setToken("");
        setUser(null);
        setCurrentOrganization(0);
        setAvailableOrganizations(null);
        setPermissions(null);
    };

    const dropMenu = (
        <div className="flex flex-col p-4 items-center">
            <div className="flex flex-col items-center gap-y-4">
                {user?.image_name ? (
                    <img
                        src={
                            imageChecker(user?.image_name)
                                ? user?.image_name
                                : `${process.env.REACT_APP_IMAGE_API}${user?.image_name}`
                        }
                        alt=""
                        className="h-14 w-14 object-cover object-center rounded-full"
                    />
                ) : (
                    <div className="h-14 w-14 rounded-full bg-brand grid place-items-center text-white font-bold">
                        {getInitals(user?.full_name)}
                    </div>
                )}
                <div className="flex flex-col items-center">
                    <span>{user?.full_name}</span>
                    <span>{user?.email}</span>
                </div>
                <div className="flex flex-col gap-y-2">
                    <Button
                        onClick={() => {
                            setProfileVisible(true);
                            setProfile(user);
                        }}
                    >
                        Edit Profile
                    </Button>
                    <Button
                        onClick={() => {
                            setChangePasswordVisible(true);
                        }}
                    >
                        Change Password
                    </Button>
                </div>
            </div>
            <Divider className="my-4" plain>
                Organizations
            </Divider>
            <div className="flex flex-col gap-y-2">
                {availableOrganizations.map((organization) => (
                    <Button
                        className={
                            organization?.organization?.id ===
                            currentOrganization
                                ? `bg-brand text-white`
                                : ``
                        }
                        key={organization?.organization?.id}
                        onClick={() =>
                            setCurrentOrganization(
                                organization?.organization?.id
                            )
                        }
                    >
                        {organization?.organization?.name}
                    </Button>
                ))}
            </div>
            <Divider className="my-4" />
            <Button onClick={() => logout()}>Logout</Button>
        </div>
    );

    return (
        <Layout className="min-h-screen pb-16 lg:pb-0">
            <Sider
                className="overflow-auto h-screen fixed top-0 bottom-0 left-0 hidden lg:block"
                width="260"
                style={{
                    backgroundImage: `linear-gradient(
                                    rgba(4, 93, 184, 0.8),
                                    rgba(4, 93, 184, 0.8)
                                    ), url(${Background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <div className="flex flex-col justify-between h-full py-8 text-center">
                    <div className="flex flex-col gap-10">
                        <div className="flex justify-center">
                            <img src={Logo} alt="Hulentenawi Logo" />
                        </div>
                        <Menu
                            className="bg-transparent border-none"
                            mode="vertical"
                            defaultSelectedKeys={["/"]}
                            selectedKeys={[pathname]}
                        >
                            {routes.map((route) => (
                                <Menu.Item
                                    key={route.url}
                                    icon={route.icon}
                                    className="my-4 text-white font-bold text-base uppercase flex items-center pl-12"
                                >
                                    <NavLink
                                        to={route.url}
                                        className="text-white"
                                    >
                                        {route.name}
                                    </NavLink>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </div>
                    <div className="text-white text-[10px] text-center">
                        © 2021 powered by <br /> Compassion International
                        Ethiopia and <br />
                        Great Commission Ministry Ethiopia
                    </div>
                </div>
            </Sider>
            <Layout className="lg:ml-[260px]">
                <Content className="m-8">
                    <div className="flex items-center justify-between mb-6">
                        <div className="flex items-center gap-1">
                            <Icon
                                icon="charm:menu-hamburger"
                                className="block lg:hidden"
                                onClick={() => setNavigationDrawer(true)}
                            />
                            <h1 className="text-sm lg:text-4xl font-bold text-brand uppercase mb-0">
                                {title}
                            </h1>
                        </div>
                        <div className="flex items-center relative">
                            {user?.image_name ? (
                                <img
                                    src={
                                        imageChecker(user?.image_name)
                                            ? user?.image_name
                                            : `${process.env.REACT_APP_IMAGE_API}${user?.image_name}`
                                    }
                                    alt=""
                                    className="h-14 w-14 rounded-full absolute object-cover object-center"
                                />
                            ) : (
                                <div className="h-14 w-14 rounded-full absolute bg-brand grid place-items-center text-white font-bold">
                                    {getInitals(user?.full_name)}
                                </div>
                            )}
                            <div className="bg-gray-100 w-full py-2 pl-16 pr-2 cursor-pointer rounded-l-full">
                                <Popover
                                    content={dropMenu}
                                    placement="bottomRight"
                                >
                                    <div className="flex items-center gap-1">
                                        <span className="text-brand">
                                            {user?.full_name}
                                        </span>
                                        <CaretDownFilled className="text-xs text-gray-400" />
                                    </div>
                                </Popover>
                            </div>
                        </div>
                    </div>
                    {/* <Divider className="m-0" /> */}
                    <div className="pb-5 relative">
                        <Outlet />
                    </div>
                    <ReactQueryDevtools initialIsOpen={false} />
                </Content>
            </Layout>
            <EditProfile />
            <ChangePassword />
            <Drawer
                placement="left"
                visible={navigationDrawer}
                onClose={() => setNavigationDrawer(false)}
                width={250}
            >
                <Menu
                    className="bg-transparent border-none"
                    mode="vertical"
                    defaultSelectedKeys={["/"]}
                    selectedKeys={[pathname]}
                >
                    {routes.map((route) => (
                        <Menu.Item
                            key={route.url}
                            icon={route.icon}
                            className="my-4 font-bold text-base uppercase flex items-center"
                        >
                            <NavLink to={route.url}>{route.name}</NavLink>
                        </Menu.Item>
                    ))}
                </Menu>
            </Drawer>
        </Layout>
    );
}

export default MainLayout;
