import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import _ from "lodash";

import {
    tokenState,
    userState,
    availableOrganizationsState,
    organizationState,
} from "../store";

function ProtectedRoute({ children }) {
    const token = useRecoilValue(tokenState);
    const user = useRecoilValue(userState);
    const availableOrganizations = useRecoilValue(availableOrganizationsState);
    const currentOrganization = useRecoilValue(organizationState);
    const selectedOrganization = _.find(availableOrganizations, [
        "organization_id",
        currentOrganization,
    ]);

    if (!token && !user) {
        return <Navigate to="/login" />;
    }

    if (!Boolean(selectedOrganization.is_active)) {
        return <Navigate to="/waiting-confirmation" />;
    }

    return children;
}

export default ProtectedRoute;
