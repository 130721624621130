import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import api from "../../../../config/api";
import { organizationState } from "../../../../store";
import { successNotification } from "../../../../utils/notifications";
import "./ReplyModal.less";

const { TextArea } = Input;

function ReplyModal({ open, closeAction, discussionCommentId }) {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const organization = useRecoilValue(organizationState);

    const { mutate, isLoading } = useMutation(
        (replyText) =>
            api.post(
                `/admin/discussion_comment_reply?:discussion_comment&:discussion`,
                replyText
            ),
        {
            onSuccess: (response, variables, context) => {
                successNotification("Reply added successfully!");
                closeAction(false);
                queryClient.invalidateQueries("discussion");
                form.resetFields();
            },
        }
    );

    const handleReply = async (values) => {
        const reply = {
            ...values,
            discussion_comment_id: discussionCommentId,
            organization_id: organization,
        };

        mutate(reply);
    };

    return (
        <Modal
            centered={true}
            title={
                <span className="text-brand font-bold uppercase">Reply</span>
            }
            visible={open}
            footer={
                <div className="text-center">
                    <Button
                        type="primary"
                        className="rounded"
                        onClick={() => form.submit()}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        Reply
                    </Button>
                </div>
            }
            onCancel={() => closeAction(false)}
            maskClosable={false}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={handleReply}
                requiredMark={false}
                className="px-6"
            >
                <Form.Item
                    name="reply"
                    rules={[
                        {
                            required: true,
                            message: "Please input the reply",
                        },
                    ]}
                >
                    <TextArea rows={6} />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ReplyModal;
