import React from 'react';

export default function BookCardIcon(props) {
  return (
    <svg width={50} height={98} viewBox="0 0 50 98" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g style={{mixBlendMode: 'overlay'}} filter="url(#filter0_d_162_3045)">
        <path fillRule="evenodd" clipRule="evenodd" d="M45.8116 0.275574H18.2403C12.9038 0.275574 4.96533 3.8559 4.96533 13.7186V76.4528C4.96533 86.3155 12.9038 89.8958 18.2403 89.8958H45.8116V80.9338H18.2934C16.2491 80.88 13.8153 80.06 13.8153 76.4528C13.8153 72.8456 16.2491 72.0256 18.2934 71.9718H45.8116V63.0098H13.8153V27.1617V13.7186C13.8153 10.1114 16.2491 9.29137 18.2403 9.2376H45.8116V0.275574ZM45.8116 18.1996H27.0902V27.1616H45.8116V18.1996Z" fill="#EBE9E9" />
      </g>
      <defs>
        <filter id="filter0_d_162_3045" x="0.965332" y="0.275574" width="48.8462" height="97.6202" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_162_3045" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_162_3045" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
