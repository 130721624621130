import { LoadingOutlined } from "@ant-design/icons";
import { Avatar, Button, Divider, Modal, Spin, Tag } from "antd";
import { differenceInYears } from "date-fns";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import api from "../../../../config/api";
import { organizationState, userPreviewState } from "../../../../store";
import { imageChecker } from "../../../../utils/imageChecker";
import { getInitals } from "../../../../utils/initials";

function UserPreview() {
    const [userPreviewVisibility, setUserPreviewVisibility] = useRecoilState(
        userPreviewState.visible
    );
    const [userPreviewData, setUserPreviewData] = useRecoilState(
        userPreviewState.data
    );
    const [courseId, setCourseId] = useState(null);
    const [completedCourses, setCompletedCourses] = useState([]);
    const [createdDiscussions, setCreatedDiscussions] = useState([]);
    const [discussions, setDiscussions] = useState([]);
    const [onProgressCourses, setOnProgressCourses] = useState([]);

    const organization = useRecoilValue(organizationState);

    const closeUserPreview = () => {
        setUserPreviewData(null);
        setUserPreviewVisibility(false);
    };

    const fetchProgress = async () => {
        const { data } = await api.get(
            `/admin/user_progress?organization_id=${organization}&user_id=${userPreviewData?.id}`
        );
        return data;
    };

    const { refetch, isFetching } = useQuery("progress-stats", fetchProgress, {
        enabled: false,
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            setCompletedCourses(result.completed_courses);
            setCreatedDiscussions(result.created_discussions);
            setDiscussions(result.discussions);
            setOnProgressCourses(result.on_progress_courses);
        },
    });

    useEffect(() => {
        if (userPreviewVisibility) {
            refetch();
        }
    }, [userPreviewVisibility]);

    return (
        <Modal
            centered={true}
            visible={userPreviewVisibility}
            footer={
                <div className="text-right">
                    <Button
                        type="primary"
                        className="rounded uppercase"
                        onClick={closeUserPreview}
                        ghost
                    >
                        Back
                    </Button>
                </div>
            }
            onCancel={closeUserPreview}
            closable={false}
            maskClosable={false}
        >
            <div className="grid place-items-center py-5 shadow-lg">
                <div className="flex items-center gap-4">
                    {userPreviewData?.image_name ? (
                        <Avatar
                            src={
                                imageChecker(userPreviewData?.image_name)
                                    ? userPreviewData?.image_name
                                    : `${process.env.REACT_APP_IMAGE_API}${userPreviewData?.image_name}`
                            }
                            alt={userPreviewData?.full_name}
                            size={84}
                        />
                    ) : (
                        <Avatar size={84} className="bg-brand">
                            {getInitals(userPreviewData?.full_name)}
                        </Avatar>
                    )}

                    <div className="flex flex-col">
                        <span className="font-bold text-2xl text-brand">
                            {userPreviewData?.full_name}
                        </span>
                        <span className="text-xs">
                            {userPreviewData?.email}
                        </span>
                        <div className="flex gap-2">
                            {!_.isNil(userPreviewData?.gender) ? (
                                <span className="text-xs">
                                    {userPreviewData?.gender}
                                </span>
                            ) : null}
                            <span className="text-xs">
                                {!_.isNil(userPreviewData?.birth_date)
                                    ? differenceInYears(
                                          new Date(),
                                          new Date(userPreviewData?.birth_date)
                                      )
                                    : null}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-5 px-10 flex flex-col">
                {isFetching ? (
                    <Spin indicator={<LoadingOutlined />} />
                ) : (
                    <>
                        <h3 className="text-base text-brand font-bold">
                            Completed Courses
                        </h3>
                        <div className="flex flex-wrap gap-y-4">
                            {completedCourses.map((course) => (
                                <Tag key={course.id}>{course.title}</Tag>
                            ))}
                        </div>
                        <Divider />
                        <h3 className="text-base text-brand font-bold">
                            Courses In Progress
                        </h3>
                        <div className="flex flex-wrap gap-y-4">
                            {onProgressCourses.map((course) => (
                                <Tag key={course.id}>{course.title}</Tag>
                            ))}
                        </div>
                        <Divider />
                        <h3 className="text-base text-brand font-bold">
                            Created Discussions
                        </h3>
                        {createdDiscussions.map((discussion) => (
                            <div
                                key={discussion.id}
                                className="flex flex-col bg-gray-100 rounded border border-gray-200 px-4 py-2"
                            >
                                <h5 className="uppercase font-bold text-gray-500">
                                    {discussion.title}
                                </h5>
                                <p className="text-gray-400 truncate">
                                    {discussion.description}
                                </p>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </Modal>
    );
}

export default UserPreview;
