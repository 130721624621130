import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button } from "antd";
import { formatDistanceToNow } from "date-fns";
import React from "react";
import { useRecoilValue } from "recoil";
import { permissionsState } from "../../../../store";
import { hasCreatePermission } from "../../../../utils/checkPermission";
import { imageChecker } from "../../../../utils/imageChecker";
import { getInitals } from "../../../../utils/initials";

function SingleDiscussionCard({
    id,
    profile_picture,
    title,
    description,
    author_name,
    comments_count,
    likes_count,
    time,
    setCommentModalOpen,
}) {
    const permissions = useRecoilValue(permissionsState);

    return (
        <div className="flex flex-col gap-y-4 lg:gap-y-0 lg:flex-row bg-white items-center p-6 shadow-lg">
            {profile_picture ? (
                <Avatar
                    size={84}
                    src={
                        imageChecker(profile_picture)
                            ? profile_picture
                            : `${process.env.REACT_APP_IMAGE_API}${profile_picture}`
                    }
                />
            ) : (
                <Avatar size={84} className="bg-brand">
                    {getInitals(author_name)}
                </Avatar>
            )}
            <div className="lg:ml-10 mr-auto text-gray-500 flex-1">
                <div className="flex flow-col lg:gap-6 mb-1">
                    <span className="uppercase font-bold text-base">
                        {title}
                    </span>
                    <span className="text-base">{author_name}</span>
                    <span className="ml-auto">
                        {formatDistanceToNow(new Date(time), {
                            addSuffix: true,
                        })}
                    </span>
                </div>
                <div className="text-xs text-justify overflow-hidden break-all">
                    {description}
                </div>
                <div className="flex items-center justify-end gap-4 mt-3">
                    <div className="flex items-center gap-1">
                        <span>{comments_count}</span>
                        <UserOutlined />
                    </div>
                    {/* <div className="flex items-center gap-1">
                        <span>{likes_count}</span>
                        <LikeOutlined />
                    </div> */}
                    {hasCreatePermission(permissions, "DISCUSSIONS") ? (
                        <Button
                            size="small"
                            onClick={() => {
                                setCommentModalOpen(true);
                            }}
                        >
                            Comment
                        </Button>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default SingleDiscussionCard;
