import React, { useState } from "react";
import { Button, Form, Input, Modal, Radio, Tabs, Upload } from "antd";
import { useRecoilValue, useRecoilState } from "recoil";
import { useQueryClient } from "react-query";
import _ from "lodash";

import api from "../../../../config/api";
import {
    changePasswordState,
    organizationState,
    userState,
} from "../../../../store";
import { successNotification } from "../../../../utils/notifications";

const { TextArea } = Input;

function ChangePassword() {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const organization = useRecoilValue(organizationState);
    const user = useRecoilValue(userState);
    const [open, setOpen] = useRecoilState(changePasswordState.visible);
    const queryClient = useQueryClient();

    const handleSubmit = async (values) => {
        const confirmPasswordRemoved = _.omit(values, ["confirmPassword"]);
        const newPasswordData = {
            ...confirmPasswordRemoved,
            email: user?.email,
        };
        setLoading(true);
        const { data } = await api.post(
            `/auth/change_password`,
            newPasswordData
        );
        form.resetFields();
        setOpen(false);
        successNotification("Password changed successfully!");
        setLoading(false);
    };

    return (
        <Modal
            centered={true}
            title={
                <span className="text-brand font-bold uppercase">
                    Change Password
                </span>
            }
            visible={open}
            footer={
                <div className="text-center">
                    <Button
                        type="primary"
                        className="rounded"
                        onClick={() => form.submit()}
                        loading={loading}
                        disabled={loading}
                    >
                        Submit
                    </Button>
                </div>
            }
            onCancel={() => setOpen(false)}
            maskClosable={false}
        >
            <Form
                className="px-6"
                layout="vertical"
                form={form}
                onFinish={handleSubmit}
                requiredMark={false}
                autoComplete="off"
            >
                <Form.Item
                    label={
                        <span className="text-base text-gray-500 uppercase font-bold">
                            Current Password
                        </span>
                    }
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Please input your current password",
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-500 uppercase font-bold">
                            New Password
                        </span>
                    }
                    name="new_password"
                    rules={[
                        {
                            required: true,
                            message: "Please input your new password",
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-500 uppercase font-bold">
                            Confirm Password
                        </span>
                    }
                    name="confirmPassword"
                    rules={[
                        {
                            required: true,
                            message: "Please confirm your new password",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue("new_password") === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        "The two passwords you entered do not match"
                                    )
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ChangePassword;
