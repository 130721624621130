import { MinusCircleOutlined } from "@ant-design/icons";
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Switch,
} from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CustomButton } from "../../components/UI/Buttons";
import api from "../../config/api";
import { organizationState, permissionsState, titleState } from "../../store";
import { hasCreatePermission } from "../../utils/checkPermission";

function NewQuiz() {
    const setTitle = useSetRecoilState(titleState);
    const organization = useRecoilValue(organizationState);
    const permissions = useRecoilValue(permissionsState);
    const { id, chapterId } = useParams();
    const [quizForm] = Form.useForm();
    const navigate = useNavigate();
    const [courseTitle, setCourseTitle] = useState("");
    const [chapterTitle, setChapterTitle] = useState("");
    const [confirmModal, setConfirmModal] = useState(false);
    const [answerRequired, setAnswerRequired] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!hasCreatePermission(permissions, "CHAPTER_QUIZZES")) {
            navigate("/");
        }
    }, [permissions, navigate]);

    useEffect(() => {
        setTitle("Add New Question");
    }, [setTitle]);

    const fetchCourse = async () => {
        const { data } = await api.get(
            `/admin/course?id=${id}&organization_id=${organization}`
        );
        return data;
    };

    useQuery("course", fetchCourse, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            setCourseTitle(result.title);
        },
    });

    const fetchChapter = async () => {
        const { data } = await api.get(
            `/admin/chapter?id=${chapterId}&organization_id=${organization}`
        );
        return data;
    };

    useQuery("chapter", fetchChapter, {
        cacheTime: 0,
        onSuccess: (response) => {
            const { result } = response;
            setChapterTitle(result.title);
        },
    });

    const showConfirm = () => {
        setConfirmModal(true);
    };

    // const closeConfirm = () => {
    //     setConfirmModal(false);
    // };

    const { mutate, isLoading } = useMutation(
        (newQuiz) => api.post("/admin/chapter_quiz?:options", newQuiz),
        {
            onSuccess: (response, variables, context) => {
                showConfirm();
            },
        }
    );

    const handleAddQuiz = async (values) => {
        const { questions } = values;
        let filledCorrectly = false;

        for (let q of questions) {
            if (_.every(q["quiz_options"], { answer: false })) {
                setAnswerRequired(true);
                filledCorrectly = false;
            } else {
                setAnswerRequired(false);
                filledCorrectly = true;
            }
        }

        if (filledCorrectly) {
            let quiz = {
                questions: [],
            };
            let answer_index = 0;
            for (let question of questions) {
                let quiz_options = [];
                for (
                    let index = 0;
                    index < question.quiz_options.length;
                    index++
                ) {
                    if (question.quiz_options[index]["answer"] === true) {
                        answer_index = index;
                    }
                    let choice = _.omit(question.quiz_options[index], [
                        "answer",
                    ]);
                    // choice["name"] = (Number(index) + 10)
                    //     .toString(36)
                    //     .toUpperCase();
                    quiz_options.push(choice);
                }
                quiz.questions.push({
                    chapter_id: chapterId,
                    question: question["question"],
                    quiz_options,
                    answer_index,
                    answer_description: question["answer_description"],
                });
            }
            quiz["organization_id"] = organization;
            mutate(quiz);
        }
    };

    const handleSetAsDefaultChange = (index, questionIndex) => {
        let questions = quizForm.getFieldValue("questions");
        let options = questions[questionIndex]["quiz_options"];
        options.map((option, optionIndex) => {
            if (optionIndex === index) {
                option.answer = true;
            } else {
                option.answer = false;
            }
            return option;
        });
        quizForm.setFieldsValue({ questions });
        setAnswerRequired(false);
    };

    const handleCancel = () => {
        if (_.isEqual(searchParams.get("workflow"), "true")) {
            navigate(`/courses/${id}/chapters/${chapterId}`);
        } else {
            navigate(-1);
        }
    };

    return (
        <>
            <Divider />
            <Breadcrumb className="mb-6" separator=">">
                <Breadcrumb.Item>
                    <Link to="/courses">Courses</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{courseTitle}</Breadcrumb.Item>
                <Breadcrumb.Item>{chapterTitle}</Breadcrumb.Item>
                <Breadcrumb.Item>Adding a new question</Breadcrumb.Item>
            </Breadcrumb>
            <Modal
                centered={true}
                maskClosable={false}
                closable={false}
                title={
                    <span className="text-brand font-bold uppercase">
                        Congratulations!
                    </span>
                }
                visible={confirmModal}
                okText="Yes, Continue"
                cancelText="No, Finish"
                onOk={() => navigate(`/courses/${id}/chapter`)}
                onCancel={() => navigate("/courses")}
            >
                <p className="px-6">
                    You added quiz successfully. Do you want to add a new
                    chapter?
                </p>
            </Modal>
            <Form
                name="dynamic_form_item"
                labelCol={{ span: 4 }}
                colon={false}
                onFinish={handleAddQuiz}
                requiredMark={false}
                autoComplete="off"
                form={quizForm}
            >
                <Form.List name="questions" initialValue={[{ question: "" }]}>
                    {(fields, { add: addOuter, remove: removeOuter }) => (
                        <>
                            {fields.map(
                                (
                                    { key: keyOuter, name: nameOuter },
                                    indexOuter
                                ) => (
                                    <React.Fragment key={keyOuter}>
                                        <Row gutter={16}>
                                            <Col span={22}>
                                                <Form.Item
                                                    label={
                                                        <span className="text-base text-gray-400 uppercase font-bold">
                                                            Question
                                                        </span>
                                                    }
                                                    name={[
                                                        nameOuter,
                                                        "question",
                                                    ]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Please write a question",
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Add Question" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <MinusCircleOutlined
                                                    onClick={() =>
                                                        removeOuter(nameOuter)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Form.List
                                            name={[nameOuter, "quiz_options"]}
                                            initialValue={[
                                                { option: "", answer: false },
                                                { option: "", answer: false },
                                                { option: "", answer: false },
                                                { option: "", answer: false },
                                            ]}
                                        >
                                            {(
                                                fields,
                                                { add, remove },
                                                { errors }
                                            ) => (
                                                <>
                                                    {fields.map(
                                                        (
                                                            {
                                                                key,
                                                                name,
                                                                fieldKey,
                                                            },
                                                            index
                                                        ) => (
                                                            <Form.Item
                                                                key={key}
                                                                labelCol={{
                                                                    span: 8,
                                                                }}
                                                                label={
                                                                    <span className="text-base text-gray-400 uppercase font-bold">
                                                                        Choice{" "}
                                                                        {index +
                                                                            1}
                                                                    </span>
                                                                }
                                                            >
                                                                <Row
                                                                    gutter={16}
                                                                >
                                                                    <Col
                                                                        span={2}
                                                                    >
                                                                        <Form.Item
                                                                            name={[
                                                                                name,
                                                                                "name",
                                                                            ]}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message:
                                                                                        "Please write a choice letter",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col
                                                                        span={
                                                                            14
                                                                        }
                                                                    >
                                                                        <Form.Item
                                                                            name={[
                                                                                name,
                                                                                "option",
                                                                            ]}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message:
                                                                                        "Please write a choice",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Add choice for the question" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col
                                                                        span={4}
                                                                    >
                                                                        <Form.Item
                                                                            fieldKey={[
                                                                                fieldKey,
                                                                                "answer",
                                                                            ]}
                                                                            name={[
                                                                                name,
                                                                                "answer",
                                                                            ]}
                                                                            valuePropName="checked"
                                                                            initialValue={
                                                                                false
                                                                            }
                                                                            validateStatus={
                                                                                answerRequired
                                                                                    ? "error"
                                                                                    : ""
                                                                            }
                                                                            help={
                                                                                answerRequired
                                                                                    ? "Choose a correct answer"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            <Switch
                                                                                onChange={() =>
                                                                                    handleSetAsDefaultChange(
                                                                                        index,
                                                                                        indexOuter
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col
                                                                        span={4}
                                                                    >
                                                                        <MinusCircleOutlined
                                                                            onClick={() =>
                                                                                remove(
                                                                                    name
                                                                                )
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Form.Item>
                                                        )
                                                    )}
                                                    <div className="flex justify-center mb-6">
                                                        <CustomButton
                                                            text="Add Choice"
                                                            action={() => add()}
                                                        />
                                                    </div>
                                                    <Form.ErrorList
                                                        errors={errors}
                                                    />
                                                </>
                                            )}
                                        </Form.List>
                                        <Form.Item
                                            label={
                                                <span className="text-base text-gray-400 uppercase font-bold">
                                                    Answer Description
                                                </span>
                                            }
                                            name={[
                                                nameOuter,
                                                "answer_description",
                                            ]}
                                            initialValue=""
                                        >
                                            <Input.TextArea />
                                        </Form.Item>
                                        <Divider />
                                    </React.Fragment>
                                )
                            )}

                            <Form.Item>
                                <Button
                                    type="primary"
                                    className="uppercase"
                                    onClick={() => addOuter()}
                                >
                                    Add Question
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <div className="flex justify-end gap-4">
                    {_.isEqual(searchParams.get("workflow"), "true") ? (
                        <Button
                            onClick={() => navigate(`/courses`)}
                            type="primary"
                            className="uppercase"
                            ghost
                        >
                            Skip
                        </Button>
                    ) : null}
                    <Button
                        onClick={handleCancel}
                        type="primary"
                        className="uppercase"
                        ghost
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        className="uppercase"
                        htmlType="submit"
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        Submit
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default NewQuiz;
