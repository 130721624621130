import { Button } from "antd";
import React from "react";
import { DeleteFilled } from "@ant-design/icons";

function IconDeleteButton() {
    return (
        <Button
            className="text-red-500"
            shape="circle"
            icon={<DeleteFilled />}
        />
    );
}

export default IconDeleteButton;
