import React, { useState, useEffect } from "react";
import { Button, Form, Input, Row, Col, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";

import Background from "../../assets/images/kids.webp";
import HulentenawiLogo from "../../assets/logos/Hulentenawi-horizontal.svg";
import CompassionLogo from "../../assets/logos/compassion.svg";
import GreatCommissionLogo from "../../assets/logos/Great-commission.svg";

import api from "../../config/api";
import { tokenState } from "../../store";

function ForgotPassword() {
    const [displayCheckCode, setDisplayCheckCode] = useState(false);
    const token = useRecoilValue(tokenState);
    const navigate = useNavigate();
    const [forgotPasswordForm] = Form.useForm();

    useEffect(() => {
        if (token) {
            navigate("/");
        }
    }, [token, navigate]);

    const { mutate, isLoading, isError, error } = useMutation(
        (emailAddress) => api.post("/auth/forgot_password", emailAddress),
        {
            onSuccess: (response, variables, context) => {
                setDisplayCheckCode(true);
            },
        }
    );

    const handleForgotPassword = (values) => {
        mutate(values);
    };

    return (
        <div
            className="grid place-items-center h-screen py-4"
            style={{
                backgroundImage: `linear-gradient(
                                rgba(4, 93, 184, 0.8),
                                rgba(4, 93, 184, 0.8)
                            ), url(${Background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <div>
                <div className="flex justify-center mb-8">
                    <img
                        src={HulentenawiLogo}
                        alt="Hulentenawi Logo"
                        className="w-80"
                    />
                </div>
                {displayCheckCode && (
                    <h1 className="text-white text-center mb-4">
                        Check your email, we have sent you a link to reset your
                        password
                    </h1>
                )}
                <div
                    className="px-8 lg:px-32 h-[209px] flex flex-col items-center rounded-2xl"
                    style={{ backgroundColor: "rgba(4, 93, 184, 0.6)" }}
                >
                    <div className="flex flex-col h-full">
                        <Form
                            className="w-[330px] my-auto"
                            layout="vertical"
                            requiredMark={false}
                            autoComplete="off"
                            onFinish={handleForgotPassword}
                            form={forgotPasswordForm}
                        >
                            <Form.Item
                                label={
                                    <span className="text-white uppercase font-bold">
                                        Email Address
                                    </span>
                                }
                                name="email"
                                validateStatus={
                                    isError && error.response.data.errors.email
                                        ? "error"
                                        : ""
                                }
                                help={
                                    isError && error.response.data.errors.email
                                        ? error.response.data.errors.email
                                        : ""
                                }
                            >
                                <Input
                                    placeholder="Email"
                                    disabled={displayCheckCode}
                                />
                            </Form.Item>
                            <Row className="text-center">
                                <Col span={24}>
                                    <Button
                                        type="secondary text-brand rounded-sm w-[180px]"
                                        htmlType="submit"
                                        loading={isLoading}
                                        disabled={isLoading}
                                    >
                                        {displayCheckCode ? "Resend" : "Send"}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                <div className="text-center">
                    <Divider />
                    <Button
                        className="text-white rounded-sm w-[180px]"
                        ghost
                        onClick={() => navigate("/login")}
                    >
                        Login
                    </Button>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row items-baseline gap-[57px]">
                <img
                    src={CompassionLogo}
                    alt="Compassion Logo"
                    className="w-56"
                />
                <img
                    src={GreatCommissionLogo}
                    alt="Great Commission Logo"
                    className="w-56"
                />
            </div>
        </div>
    );
}

export default ForgotPassword;
