import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import api from "../../../../config/api";
import { editDiscussionState, organizationState } from "../../../../store";
import { successNotification } from "../../../../utils/notifications";
import "./EditDiscussion.less";

const { TextArea } = Input;

function EditDiscussion() {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const organization = useRecoilValue(organizationState);
    const [open, setOpen] = useRecoilState(editDiscussionState.visible);
    const [discussion, setDiscussion] = useRecoilState(
        editDiscussionState.data
    );
    const queryClient = useQueryClient();

    useEffect(() => {
        if (discussion) {
            form.setFieldsValue(discussion);
        }
    }, [discussion]);

    const { mutate, isLoading, isError, error } = useMutation(
        (editedDiscussion) => api.patch("/admin/discussion", editedDiscussion),
        {
            onSuccess: (response, variables, context) => {
                successNotification("Discussion edited successfully");
                queryClient.invalidateQueries("discussions");
                setOpen(false);
            },
        }
    );

    const handleSubmit = async (values) => {
        const editedDiscussion = {
            ...values,
            id: discussion?.id,
            organization_id: organization,
        };

        mutate(editedDiscussion);
    };

    return (
        <Modal
            centered={true}
            title={
                <span className="text-brand font-bold uppercase">
                    Edit Discussion
                </span>
            }
            visible={open}
            footer={
                <div className="text-center">
                    <Button
                        type="primary"
                        className="rounded"
                        onClick={() => form.submit()}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        Submit
                    </Button>
                </div>
            }
            onCancel={() => {
                setOpen(false);
                setDiscussion(null);
            }}
            maskClosable={false}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={handleSubmit}
                requiredMark={false}
                className="px-6"
            >
                <Form.Item
                    label={
                        <span className="text-base text-gray-500 uppercase font-bold">
                            Topic
                        </span>
                    }
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Please input the topic of discussion",
                        },
                    ]}
                >
                    <Input placeholder="Simple and short title" />
                </Form.Item>
                <Form.Item
                    label={
                        <span className="text-base text-gray-500 uppercase font-bold">
                            Description
                        </span>
                    }
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: "Please input the description",
                        },
                    ]}
                >
                    <TextArea
                        rows={6}
                        placeholder="Any description to discuss"
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default EditDiscussion;
