import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Icon } from "@iconify/react";

import Background from "../../assets/images/kids.webp";
import HulentenawiLogo from "../../assets/logos/Hulentenawi-horizontal.svg";
import CompassionLogo from "../../assets/logos/compassion.svg";
import GreatCommissionLogo from "../../assets/logos/Great-commission.svg";

import {
    tokenState,
    userState,
    availableOrganizationsState,
    organizationState,
    permissionsState,
} from "../../store";

function WaitingConfirmation() {
    const navigate = useNavigate();
    const setToken = useSetRecoilState(tokenState);
    const setPermissions = useSetRecoilState(permissionsState);
    const setUser = useSetRecoilState(userState);
    const setAvailableOrganizations = useSetRecoilState(
        availableOrganizationsState
    );
    const setCurrentOrganization = useSetRecoilState(organizationState);

    const logout = () => {
        setToken("");
        setUser(null);
        setCurrentOrganization(0);
        setAvailableOrganizations(null);
        setPermissions(null);
        navigate("/login");
    };

    return (
        <div
            className="grid place-items-center h-screen py-4"
            style={{
                backgroundImage: `linear-gradient(
                                rgba(4, 93, 184, 0.8), 
                                rgba(4, 93, 184, 0.8)
                            ), url(${Background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <div>
                <div className="flex justify-center mb-8">
                    <img
                        src={HulentenawiLogo}
                        alt="Hulentenawi Logo"
                        className="w-80"
                    />
                </div>
                <div
                    className="w-[624px] h-[143px] flex flex-col items-center rounded-2xl"
                    style={{ backgroundColor: "rgba(4, 93, 184, 0.6)" }}
                >
                    <div className="flex h-full items-center justify-center text-white gap-2">
                        <Icon icon="fa6-solid:clock" /> Please Wait until the
                        administrator approves
                    </div>
                </div>
            </div>
            <div className="text-center">
                <h1 className="text-white text-center mb-4">Go back to</h1>
                <Button
                    className="text-white rounded-sm w-[180px]"
                    ghost
                    onClick={logout}
                >
                    Login
                </Button>
            </div>
            <div className="flex items-baseline gap-[57px]">
                <img
                    src={CompassionLogo}
                    alt="Compassion Logo"
                    className="w-56"
                />
                <img
                    src={GreatCommissionLogo}
                    alt="Great Commission Logo"
                    className="w-56"
                />
            </div>
        </div>
    );
}

export default WaitingConfirmation;
